import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-overlay-resource-type',
  templateUrl: './overlay-resource-type.component.html',
  styleUrls: ['./overlay-resource-type.component.scss']
})
export class OverlayResourceTypeComponent {
  public params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
