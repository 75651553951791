import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as _ from 'lodash';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { ToolManagementService } from 'src/app/modules/shared/services/tool-mangement.service';
import { CreateRoleComponent } from './create-role/create-role.component';
import { Role, IRoleDetails, RoleOptions, RoleResponse, RoleDetailsResponse, IPermission, ICreateUpdateRoleResponse, IDeleteRoleResponse } from './model/tool-management.model';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { IApply, ITreeView } from 'src/app/modules/shared/components/filter/filter.model';
import { CONSTANT, ROLES_CONTENT_PROPS, ToolTipConfig, getRoleSuccessMsg } from 'src/app/modules/shared/constants/constant-data';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ToolRenamedialogComponent } from './tool-renamedialog/tool-renamedialog.component';
import { Observable, Subscription } from 'rxjs';
import { GlobalSearchService } from 'src/app/modules/shared/services/global-search.service';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { CanComponentDeactivate, UnsaveChangeGuard } from 'src/app/modules/shared/unsave-change.guard';
import { UnsavedDialogComponent } from 'src/app/modules/shared/components/unsaved-dialog/unsaved-dialog.component';
import { ApiService } from 'src/app/modules/shared/services/api.service';
@Component({
  selector: 'app-tool-management',
  templateUrl: './tool-management.component.html',
  styleUrls: ['./tool-management.component.scss'],
  providers: [TitleCasePipe]
})

export class ToolManagementComponent implements OnInit, CanComponentDeactivate {
  roles: Role[] = [];
  roleDetails: IRoleDetails = {};
  @Input()
  role: Role | any = {};
  selectedState: number = 1;
  defaultRoleList: Role[] = [];
  Regions: boolean = false;
  Notifications: boolean = false;
  resetButtonRequired: boolean = false;
  showDescription: boolean = false;
  defaultValues: RoleOptions | any = {};
  selectedTab: number = 0;
  isTabClicked: boolean = false;
  // currentSelectedTab: Role = {};
  maxStepperValue: number = 100;
  searchRole: string = '';
  public initialTableData: any;
  public toolTipOption = ToolTipConfig;
  existingRoleNames: { [name: string]: string[] } = {};
  filterColumnData: Map<String, ITreeView[]> = new Map<String, ITreeView[]>();
  deviceDetails: ITreeView[] | undefined = [];
  isSaveChanges: boolean = false;
  enableAll: boolean = false;
  editRole: any = {};
  glbSearch: Subscription;
  isFirstLevelApiCalled: boolean = false;
  isSecondLevelApiCalled: boolean = false;
  isSaveDisable: boolean = true;
  closeCreateRoleDialog: boolean = false;
  tabApiLoaded: boolean = false;
  apiCount: number = 0;
  dialogRef!: MatDialogRef<UnsavedDialogComponent> | null;
  userLoggedOutSubscription!: Subscription;
  selectedPermission: any;
  previousTabIndex:number=0;
  isApiCallDone:boolean=true;
  openAccordions: { [key: string]: boolean } = {};
  isToogle:boolean = false;
  public bothLevelSelectedData: IApply | null = null;
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  constructor(
    private dialog: MatDialog,
    private titlecase: TitleCasePipe,
    private dialogService: DialogService,
    public toolManagementService: ToolManagementService,
    public spinnerService: SpinnerService,
    private messageService: MessageServiceService,
    private headerService: HeaderService,
    private authService: AuthService,
    private globalSearchService: GlobalSearchService,
    private sharedService: SharedService,
    private canDeactivateGuard: UnsaveChangeGuard,
    private apiService: ApiService) {
    this.headerService.setHeading('Role Management', { type: 'roleMgnt' });
    this.glbSearch = this.globalSearchService.searchCleared.subscribe(() => {
      if (!this.globalSearchService.isRedirected) {
        this.sharedService.clearData();
        this.roles = [];
        this.selectedTab = 0;
      }
      this.getAllTabs();
    });
  }

  ngOnInit(): void {
    this.getAllTabs();
    this.userLoggedOutSubscription = this.authService.userLoggedOut.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.glbSearch) {
      this.glbSearch.unsubscribe();
    }
    if (this.userLoggedOutSubscription) {
      this.userLoggedOutSubscription.unsubscribe();
    }
    this.globalSearchService.clearSearch();
    this.apiService.unsubscribeApiCall();
  }
  getAllTabs() {
    //this.spinnerService.show();
    this.apiCount = 0;
    this.tabApiLoaded = false;
    this.toolManagementService.getRoles(this.getTabsCallback);
  }
  getTabsCallback = (response: RoleResponse) => {
    this.spinnerService.hide();
    this.tabApiLoaded = true;
    if (response.status === 200) {
      const data = JSON.parse(JSON.stringify(response.data));
      const userCreated: Role[] = data?.filter((role: Role) => {
        return !role?.default_role;
      });
      const systemCreated = data?.filter((role: Role) => {
        return role?.default_role;
      });
      this.roles = systemCreated?.concat(userCreated);
      //this.defaultRoleList = systemCreated;
      this.defaultRoleList=this.roles;
      const selectedRoleFromSearch =  this.sharedService.getData();
      if(Object.keys(selectedRoleFromSearch).length && this.roles?.length){
       
        const selectedRoleIndex: number = this.roles.findIndex((role:Role)=>{
          return selectedRoleFromSearch.id === role.id;
        })
        if((selectedRoleIndex || selectedRoleIndex ===0) && selectedRoleIndex > -1) {
          this.getRoleDetails(this.roles[selectedRoleIndex]);
          this.role = this.roles[selectedRoleIndex];
          this.previousTabIndex = selectedRoleIndex;
          this.selectedTab = selectedRoleIndex;
        } else {
          this.getRoleDetails(this.roles[0])
        }
      } else {
        this.getRoleDetails(this.roles[0])
      }
    }
  }
  public isSystemRole(roleName: any): boolean {
    const systemRoles = ["Admin", "Read-Only", "Read & Run"];
    return systemRoles.includes(roleName);
  }

  getRoleDetails(role: Role) {
    //this.spinnerService.show();
    this.isApiCallDone=false;
    this.apiCount = 0;
    this.filterColumnData.clear();
    this.toolManagementService.getRolesData(role, this.roleDetailsCallback);
  }
  roleDetailsCallback = (response: RoleDetailsResponse) => {
    this.spinnerService.hide();
    this.apiCount++;
    if (response.status === 200) {
      this.roleDetails = JSON.parse(JSON.stringify(response.data));
      if (this.roleDetails?.permissions?.length) {
        this.roleDetails.permissions = _.orderBy(this.roleDetails?.permissions, ['label'], ['asc'])
        const topLevelPermissions: IPermission[] = [];
        const subLevelPermissions: IPermission[] = [];
        this.defaultValues={}
        const subPermissionsMap: { [parentId: string]: IPermission[] } = {};
        const accordianPermissions: any = [];
        this.roleDetails?.permissions?.forEach((role: IPermission) => {
          if (!role.parent_id) {
            topLevelPermissions.push(role);
          } else {
            if (!subPermissionsMap[role.parent_id]) {
              subPermissionsMap[role.parent_id] = [];
            }
            subPermissionsMap[role.parent_id].push(role);
          }
          if (role.type === 'toggle') {
            role.value = role?.value === 'true' || false;
          } else {
            role.value = Number(role.value);
          }

          if(role.type === "accordian"){
            accordianPermissions.push(role);
          }
          if (role.code) {
            this.defaultValues[role.code] = role.value;
          }

        });

        this.roleDetails?.permissions?.forEach((role: IPermission) => {
           if(accordianPermissions.length > 0){
            accordianPermissions.forEach((value:any)=>{
             if(value.id  === role.parent_id ){
              subPermissionsMap[value.parent_id].push(role);
             }
            })
          }
        });



       

        topLevelPermissions.forEach((parentPermission: IPermission | any) => {
          if (parentPermission.id != undefined) {
            parentPermission.subPermissions = subPermissionsMap[parentPermission.id] || [];
          }
        });
 
        this.roleDetails.permissions = topLevelPermissions;
        this.isFirstLevelApiCalled = false;
        this.isSecondLevelApiCalled = false;
        if (!this.filterColumnData.get('Region')?.length) {
          //this.spinnerService.show();
          // this.bothLevelSelectedData = null;
          this.toolManagementService.loadRegionList(this.roles[this.selectedTab], this.loadRegionListCallback);
        } else {
          this.apiCount++;
          this.isSecondLevelApiCalled = true;
          this.isFirstLevelApiCalled = true;
        }
        this.selectedPermission = this.roleDetails.permissions?.[0]
      }
      const roleIndex = this.roles.findIndex((obj: Role) => {
        return obj.name === this.roleDetails?.name;
      });
      if (roleIndex > -1) {
        this.roles[roleIndex].permissions = { ...this.defaultValues };
        this.role = this.roles[roleIndex];
      }
      this.makeSetPermissionsSelection();
    }
    this.isApiCallDone=true
  }
  checkTabChange(currentTabIndex: number, currentSelectedRole: Role): void {
    if(this.previousTabIndex==currentTabIndex){
      return;
    }
    
    const sData = this.sharedService.getData();
    const filterSelectedTab: Role = this.roles?.find((role: Role) => {
      return sData && sData.role_name ? (role.name === sData.role_name) : (role.name === currentSelectedRole.name);
    }) || {};
    let selectedIndex = currentTabIndex;
    this.previousTabIndex=currentTabIndex;
    if (sData && sData.role_name) {
      selectedIndex = this.roles.findIndex((role: Role) => {
        return role.name === sData.role_name;
      });
    }
    if (Object.keys(filterSelectedTab).length) {
      if (this.role && this.role.permissions
        && Object.keys(this.role?.permissions).length) {
        if (!_.isEqual(this.role.permissions, this.defaultValues)) {
          this.saveChangesDialog(this.role, filterSelectedTab);
        } else {
          if (selectedIndex > -1) {
            this.selectedTab = selectedIndex;
          } else {
            this.selectedTab = currentTabIndex;
          }
          this.getRoleDetails(filterSelectedTab);
        }
      } else {
        if (sData && sData.role_name && selectedIndex > -1) {
          this.selectedTab = selectedIndex;
        } else {
          this.selectedTab = currentTabIndex;
        }
        this.getRoleDetails(filterSelectedTab);
      }
      this.makeSetPermissionsSelection();
    }
    this.saveChangeEnableDisable();
  }

  splitData(data: IRoleDetails, type: string): IPermission[] {
    if (data?.permissions) {
      return type === 'left' ? data?.permissions.slice(0, 5) : data?.permissions.slice(5);
    }
    return [];
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const payload: IDialog = {
      component: CreateRoleComponent,
      width: '350px',
      enterAnimationDuration: enterAnimationDuration,
      exitAnimationDuration: exitAnimationDuration,
      data: {
        defaultRoles: this.defaultRoleList,
        setCallbackFunction: (result: any) => {
          return this.roleCall(result);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  roleCall(result: any) {
    if (result) {
      return this.createNewRoleTab(result);
    }
  }

  saveChanges() {
    this.spinnerService.show();
    this.isSaveChanges = true;
    const currentRole: Role = this.roles[this.selectedTab];
    this.toolManagementService.createUpdateRole(currentRole, this.createUpdateRolecallback, false);
    if (!this.canSaveFilterData()) {
      this.apply(this.bothLevelSelectedData);
    }
  }
  createUpdateRolecallback = (response: ICreateUpdateRoleResponse,
    isNewRole: boolean,
    roleName: string = '') => {
    this.spinnerService.hide();
    this.sharedService.clearData();
    if (response?.status === 200) {
      const role: Role = {};
      role.id = response.data?.role_id;
      if (isNewRole) {
        this.roles.push({
          name: roleName,
          status: true,
          id: response.data?.role_id,
          default_role: false
        });
        this.selectedTab = this.roles.length - 1;
      } else {
        this.getRoleDetails(this.roles[this.selectedTab]);
      }
      const snakMsg: IToastrOptions = {
        message: this.isSaveChanges ? CONSTANT.ROLE_MODIFIED_MSG : getRoleSuccessMsg(roleName)
      }
      this.messageService.showSuccess(snakMsg);
      this.isSaveChanges = false;
    }
  }
  createNewRoleTab(result: any) {
    const roleId = result.roleId;
    const label = this.titlecase.transform(result.name);
    const flag = this.roles.find(item => item.name?.trim()?.toLocaleLowerCase() === label.trim().toLocaleLowerCase());
    if (!flag) {
      this.spinnerService.show();
      const newRole: Role = {
        name: label,
        clone_role_id:roleId
      };
      this.isSaveChanges = false;
      this.toolManagementService.createUpdateRole(newRole, this.createUpdateRolecallback, true);
      this.closeCreateRoleDialog = true
    } else {
      this.closeCreateRoleDialog = false
    }
    return { closeDialog: this.closeCreateRoleDialog }
  }

  deleteRole(role: Role): void {
    const title = 'Are you sure you want to delete the role ' + role.name + '?';
    this.dialogService.openDialog(
      {
        title: title,
        content: 'If you delete the role, you will not be able to find the role again',
        buttons: [{
          label: 'Delete',
          class: 'btn-red',
          callBack: () => {
            this.spinnerService.show();
            this.toolManagementService.deleteRole(role, this.deleteRoleCallback);
          }
        },
        { label: 'Cancel', class: 'btn-grayborder' }
        ],
        disableClose: true
      }
    );
  }

  deleteRoleCallback = (response: IDeleteRoleResponse) => {
    this.spinnerService.hide();
    this.sharedService.clearData();
    if (response.status === 200) {
      const snackbar: IToastrOptions = {
        message: response?.message
      }
      this.messageService.showSuccess(snackbar);
      this.defaultValues = {};
      this.role = null;
      this.selectedTab=0;
      this.getAllTabs();
    }

  }

  public openDailog(role: Role, roleId: any) {
    const payload: IDialog = {
      component: ToolRenamedialogComponent,
      width: '360px',
      data: {
        roleName: role.name,
        setCallbackFunction: (result: { value: string, click: Boolean }) => {
          this.rolenameCall(result, role)
        }
      },
    }
    this.dialogService.openDialog(payload);
    // dialogRef.afterClosed().subscribe((result: { value: string, click: Boolean }) => {
    //   if (result.value != null && result.value !== role.name && result.click) {
    //     const matching: any = this.roles.filter((item: any) => result.value === item.name);
    //     if (matching && matching.length > 0) {
    //       const snackBarData: IToastrOptions = {
    //         title: 'Duplicate Role Name'
    //       }
    //       this.messageService.showError(snackBarData);
    //     } else {
    //       this.editRole = {
    //         name: result.value,
    //         role: role,
    //       }
    //       this.spinnerService.show();
    //       this.toolManagementService.renameRole(role, result.value, this.onRenameCallback);
    //     }
    //   }
    // })
  }

  rolenameCall(result: any, role: any) {
    if (result.value != null && result.value !== role.name && result.click) {
      const matching: any = this.roles.filter((item: any) => result.value === item.name);
      if (matching && matching.length > 0) {
        const snackBarData: IToastrOptions = {
          title: 'Duplicate Role Name'
        }
        this.messageService.showError(snackBarData);
      } else {
        this.editRole = {
          name: result.value,
          role: role,
        }
        this.spinnerService.show();
        this.toolManagementService.renameRole(role, result.value, this.onRenameCallback);
      }
    }
  }

  onRenameCallback = (response: any) => {
    this.spinnerService.hide();
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message,
      }
      this.messageService.showSuccess(snakMsg);

      this.editRole.role.name = this.editRole.name;
    }
  }

  resetValues(role: Role): void {
    this.dialogService.openDialog(
      {
        title: 'Alert',
        content: `Are you sure you want to reset the values for "${role.name}" ?`,
        buttons: [{
          label: 'Ok',
          callBack: () => {
            role.permissions = { ...this.defaultValues }
          }
        },
        { label: 'Cancel' }]
      }
    );
  }

  onStepperChange(role: any, code: string, type: string): void {
    if (this.isRoleEditable(role)) {
      return;
    }
    if (type === 'increment' && role.permissions[code] < this.maxStepperValue) {
      role.permissions[code] += 1;
    } else if (type === 'decrement' && role.permissions[code] > 0) {
      role.permissions[code] -= 1;
    }

    this.makeSetPermissionsSelection();
  }

  loadRegionListCallback = (response: any) => {
    this.spinnerService.hide();
    this.isFirstLevelApiCalled = true;
    this.apiCount++;
    if (response.status === 200) {
      if (response?.data && response?.data.length) {
        this.filterColumnData.set('Region', response.data);
      } else {
        this.isSecondLevelApiCalled = true;
        const snakMsg: IToastrOptions = {
          message: CONSTANT.NO_MAPPED_REGION_LIST_MSG
        }
        this.messageService.showSuccess(snakMsg);
      }
    }
  }
  public isSaveButtonEnableDisable() {
    let isEnable = false;
    if(this.deviceDetails && this.deviceDetails.length) {
      if(!this.bothLevelSelectedData?.secondLevel?.length){
        isEnable = true;
      }
    }
    return isEnable;
  }

  updateBothLevelData(data: IApply) {
    this.bothLevelSelectedData = data;
  }

  apply(data: IApply | null) {
    this.finalFilterData(data?.firstLevel);
    this.finalFilterData(data?.secondLevel);
    //this.spinnerService.show();
    if (!!data) {
      const payload = {
        role_id: this.roles[this.selectedTab].id,
        all_devices: data.isSecondLevelAllSelected || false,
        all_regions: data.isFirstLevelAllSelected || false,
        device_list: data.secondLevel ? data.secondLevel : [],
        region_list: data.firstLevel ? data.firstLevel : []
      }
      this.toolManagementService.saveRegionDevice(payload, this.applyCallback)
    }
  }

  finalFilterData(data: ITreeView[] | undefined) {
    data?.map(item => {
      if (('data' in item)) {
        delete item.parent;
        delete item.lavel;
        this.finalFilterData(item.data || []);
      } else {
        delete item.lavel;
        delete item.parent;
      }
    })
  }
  selectedRegions(values: ITreeView[]) {
    this.deviceDetails = [];
    // this.bothLevelSelectedData = null;
    values = _.cloneDeep(values);
    this.isSecondLevelApiCalled = false;
    if ((this.roles[this.selectedTab].name === this.roleDetails.name) && values.length) {
      //this.spinnerService.show();
      let selectedRegionFilter: ITreeView[] = [];
      values?.forEach((obj: ITreeView) => {
        const getGlobalFilterObj = this.createRegionFilterData(obj);
        selectedRegionFilter.push(getGlobalFilterObj);
      });
      this.toolManagementService.loadDeviceList(this.roles[this.selectedTab], selectedRegionFilter, this.deviceDetailsCallback);
    } else {
      this.deviceDetails = [];
      this.bothLevelSelectedData = null;
      this.isSecondLevelApiCalled = true;
    }
  }
  showFilterForCurrentTab(currentRole: Role) {
    return this.roles[this.selectedTab] && (this.roles[this.selectedTab].name === currentRole.name);
  }
  public checkUncheckRegion(values: ITreeView[]) {
    this.deviceDetails = [];
  }
  createRegionFilterData(regionFilter: ITreeView, reqObj: ITreeView | any = {}) {
    reqObj.id = regionFilter.id;
    reqObj.label = regionFilter.label;
    if (regionFilter?.data) {
      reqObj.data = [];
    }
    regionFilter?.data?.forEach((obj: ITreeView, index) => {
      const currentlevelObj: ITreeView | any = {};
      currentlevelObj.id = obj.id;
      currentlevelObj.label = obj.label;
      reqObj.data.push(currentlevelObj);
      if (obj?.data) {
        this.createRegionFilterData(obj, reqObj.data[index] = {});
      }

    })
    return reqObj;
  }

  deviceDetailsCallback = (response: any) => {
    this.spinnerService.hide();
    this.isSecondLevelApiCalled = true;
    if (response.status === 200) {
      if (response?.data && response?.data.length) {
        this.deviceDetails = response.data;
      } else {
        if (!response.data.length) {
          this.bothLevelSelectedData = null;
          const snakMsg: IToastrOptions = {
            message: CONSTANT.NO_DEVICE_LIST_MSG
          }
          this.messageService.showSuccess(snakMsg);
        }
      }
    }

  }

  applyCallback = (response: any) => {
    this.spinnerService.hide();
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: CONSTANT.SUCCESS_REGION_DEVICE
      }
      this.messageService.showSuccess(snakMsg);
      // this.bothLevelSelectedData = null;
    }
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }
  isRoleEditable(role: Role) {
    const isEditable = role.default_role ? true : false;
    return isEditable;
  }

  resetRoles() {
    if (this.role && this.role.permissions) {
      const defaultPermissions = { ...this.defaultValues };
      Object.keys(this.role.permissions).forEach(key => {
        if (defaultPermissions.hasOwnProperty(key)) {
          this.role.permissions[key] = defaultPermissions[key];
        }
      });
      this.enableAll = false;

    }
  }

  toggleParentRole(event: MatSlideToggleChange, role: any, subrole: any) {
    if (subrole) {
      subrole.forEach((item: any) => {
        role.permissions[item.code] = event.checked;
      });
    }

    this.makeSetPermissionsSelection();
  }

  toggleChildRole(event: MatSlideToggleChange, role: any, parentPermission: any) {
    if (event.checked) {
      role.permissions[parentPermission.code] = true;
    }
    this.makeSetPermissionsSelection();
  }

  public saveChangeEnableDisable() {
    this.isSaveDisable = true;
    if (this.hasPermission(this.permissionProps.MODIFY_ROLE_PERMISSION) &&
      this.roles && !this.roles[this.selectedTab]?.default_role) {
      this.isSaveDisable = false
    }
  }
  
  public canSaveFilterData() {
    let canSaveFilter = true;
    if (this.hasPermission(this.permissionProps.MODIFY_ROLE_PERMISSION) &&
      (this.roles && !this.roles[this.selectedTab]?.default_role)) {
      canSaveFilter = false
    }
    return canSaveFilter;
  }


  toggleEnableAll(event: any) {
    this.updateRolePermissions(event.checked)
  }

  updateRolePermissions(flag: boolean) {
    const roles: any = this.roles[this.selectedTab] ? this.roles[this.selectedTab]?.permissions : [];
    Object.keys(roles)?.forEach((item: any) => {
      if (typeof (roles[item]) === 'boolean') {
        roles[item] = flag;
      } else {
        roles[item] = flag ? 1 : 0
      }
    })
  }
  validateAllPermissions() {
    const roles: any = this.roles[this.selectedTab] ? this.roles[this.selectedTab]?.permissions : [];
    return roles && Object.values(roles)?.every((v: any) => v === true || v >= 1)
  }
  toggleDescription() {
    this.showDescription = !this.showDescription;
  }
  makeSetPermissionsSelection() {
    const flag: boolean = this.validateAllPermissions();
    this.enableAll = flag;
  }
  saveChangesDialog(currentRole: Role, selectedRole: Role, outerLink: boolean = false): Observable<boolean> {
    this.dialogRef = this.dialog.open(UnsavedDialogComponent, {
      width: '360px',
      disableClose: true,
      data: {
        title: 'Save Changes',
        content: 'Do you want to save changes?',
        buttons: [{
          label: 'Yes', class: 'btn-blue',
          callBack: () => {
            this.isSaveChanges = true;
            this.spinnerService.show();
            this.toolManagementService.createUpdateRole(currentRole, this.createUpdateRolecallback, false);
            this.isApiCallDone=true
          }
        }, {
          label: 'No', class: 'btn-white',
          callBack: () => {
            this.isSaveChanges = false;
            if (!outerLink) {
              this.getRoleDetails(selectedRole);
            }
          }
        }],
        disableClose: true
      },
    });
    return this.dialogRef.afterClosed();
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    const hasChange = this.role?.permissions &&
      Object.keys(this.role?.permissions).length &&
      !_.isEqual(this.role.permissions, this.defaultValues);

    const sData = this.sharedService.getData();
    const filterSelectedTab: Role = this.roles?.find((role: Role) => {
      return sData && sData.role_name ? (role.name === sData.role_name) : this.roles[this.selectedTab];
    }) || {};

    return hasChange ? this.saveChangesDialog(this.role, filterSelectedTab, true) : true;
  }
  public updateState(state: any) {
    this.selectedState = state.value;
  }

  handlePermissionClick(permission: any) {
    this.openAccordions = {};
    this.isToogle = permission.subPermissions.some((permission:any) => permission.type === "accordian");
    this.selectedPermission = permission;
  }
  toggleAccordion(accordionId: string): void {
    this.openAccordions[accordionId] = !this.openAccordions[accordionId];
  }
}
