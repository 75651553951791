import { Injectable } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { SortingFilterService } from './sorting-filter.service';
import { Utils } from '../utils';
import { IList } from '../components/list/list.model';
import * as _ from 'lodash';
import { ITreeView } from '../components/filter/filter.model';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {
  public updateGraphData = new Subject();
  public updateGraphDataObserver = this.updateGraphData.asObservable();
  public updateCyberGraph = new Subject();
  public updateCyberGraphData = this.updateCyberGraph.asObservable();
  public updateDataOnWhiteListUpdate = new Subject();
  public updateDataOnWhiteListUpdateObserver = this.updateDataOnWhiteListUpdate.asObservable();
  constructor(private restService: RestService,
    private responseService: ResponseService,
    private sortingFilterService: SortingFilterService,
      private apiService: ApiService
) { }

    getWhitelistGraph(callback: Function, ){
      const subs = this.restService.getApi(END_POINTS.WHITELIST_GRAPHS)?.subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
      this.apiService.apiSubscriber.push(subs);
    }
    loadWhitelistHistory(callback: Function,page_num?: number, content_num?: number){ //page_num=3&content_num=10 ?page=${page}&pageSize=${pageSize}
     const subs = this.restService.getApi(END_POINTS.WHITELIST_HISTORY+`?width=30&height=30`).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     this.apiService.apiSubscriber.push(subs); 
    }

    loadWhitelistParameter(callback: Function){
      const obj = {
        type: 1
      }
      const apiDetails: IAPIDetails | any = {
        url: END_POINTS.WHITELIST_PARAMETER,
        req: obj,
        method: CONFIG.METHOD.POST,
        responseType: CONFIG.RESPONSE_TYPE.NONE,
        callBack: callback
      };
      this.apiService.callAPI(apiDetails);
    }

    public loadWhitelistDevices(parameterId: any,  callback:Function, globalFilter?:ITreeView[]) {
      const vendor: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.whiteListSub?.filters.vendor);
      const device_type: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.whiteListSub.filters.device_type);
      const acceptedRisk: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.whiteListSub.filters.accepted_risk_filter);
      const device_failed_filter: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.whiteListSub.filters.device_failed_filter);
      const regionList = _.uniq(this.finalFilterData(globalFilter || []));
      const req: any = {
        type: 1,
        parameter_id: parameterId,
        filters:{
          vendor: vendor,
          device_type: device_type,
          accepted_risk_filter: acceptedRisk.length ? acceptedRisk[0] : '',
          device_failed_filter : device_failed_filter,
          region_list: regionList || []
        }
      };
      const apiDetails: IAPIDetails | any = {
      url: END_POINTS.WHITELIST_DEVICES,
      req: req,
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
      };
      this.apiService.callAPI(apiDetails);
    }

    fetchDeviceDetails(selectedRow: IList, callback: Function ){
     const req: any = {
       parameter_id: selectedRow?.id,
       global_filter:false
     };
    const subs = this.restService.postApi(END_POINTS.SECURITY_INFORMATION_SELECTED_DETAIL, req).subscribe({
       next: (response) => {
         callback(this.responseService.successResponse(response));
       },
       error: (err) => {
         callback(this.responseService.errorResponse(err));
       },
     })
     this.apiService.apiSubscriber.push(subs);
   }
  updateWhiteListItem(itemLists: any[],parameterDetails:any, comments: any,state: number = 0, callback: Function) {
    const itemListsCopy = _.cloneDeep(itemLists);
    const deviceDetails: any = [];
    itemListsCopy?.forEach((item: any) => {
      deviceDetails.push(
        {
          white_list: item?.isSelected,
          device_id: item.id,
          uid: item?.uid || '',
          master_data_id: item?.master_data_id || ''
        }
      )
    })
    const req: any = {
      state:state,
      devices_ids: deviceDetails,
      comments: comments
    }
    if(state===1 || state===2) {
      let uniqueMasterDataIds = parameterDetails.map((detail: { master_data_id: any; }) => detail.master_data_id);
      req.master_data_id = uniqueMasterDataIds;
      req.uid = parameterDetails[0]?.uid;
    }
    const subs = this.restService.postApi(END_POINTS.WHITE_LIST, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }

  getWhitleListHistoryDetails(id: number | undefined , callback: Function){
    const req = {
      whitelist_history_id: id
    }
    const subs = this.restService.postApi(END_POINTS.WHITELIST_HISTORY_DETAILS,req).subscribe({
      next: (Response) => {
        callback(this.responseService.successResponse(Response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  private finalFilterData(data: ITreeView[], array:any[] = []): any {
    data?.forEach(item => {
      if ('data' in item) {
        array.push(item.id);
        this.finalFilterData(item.data || [], array);
      } else {
        array.push(item.id);
      }
    })
    return array;
  }
}
