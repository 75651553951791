import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-vulnerability-parameter',
  templateUrl: './vulnerability-parameter.component.html',
  styleUrls: ['./vulnerability-parameter.component.scss']
})
export class VulnerabilityParameterComponent implements ICellRendererAngularComp {
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
   public params!: ICellRendererParams;
    
      agInit(params: ICellRendererParams): void {
        this.params = params;
      }

}
