export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6LeGY3QjAAAAAJgQa18CRytaAv7KatcMwG_kwCI_',
  },
  baseURL: 'https://c7le22zxwj.execute-api.us-east-1.amazonaws.com/api/',

  searchIndexes: {
    'device': 'scandev_table_dev',
    'scan_summary': 'scansum_table_dev',
    'param' : 'param_table_dev',
    'roles' : 'roles_table_dev',
    'benchmark' : 'benchmarks_table_dev',
    'users': 'users_table_dev'
  },

  WebSocket: 'https://spark-ws.dev.eficensittest.com/',
  onPremRestriction:{
    showSignup: true,
    captcha: true,
    vpn: true
  }
};


// export const environment = {
//   production: false,
//   recaptcha: {
//     siteKey: '6LeGY3QjAAAAAJgQa18CRytaAv7KatcMwG_kwCI_',
//   },
//   baseURL:   process.env['BASE_URL'],
//   searchIndexes: {
//     'device': process.env['DEVICE_INDEX'],
//     'scan_summary': process.env['SCAN_SUMMARY_INDEX'],
//     'param': process.env['PARAM_INDEX'],
//     'roles': process.env['ROLES_INDEX'],
//     'benchmark': process.env['BENCHMARK_INDEX'],
//     'users': process.env['USERS_INDEX'],
//   },
//   WebSocket: process.env['WEBSOCKET_URL'],
//   onPremRestriction:{
//     showSignup: process.env['SHOW_SIGNUP'],
//     captcha: process.env['CAPTCHA'],
//     vpn: process.env['VPN']
//   }
// };
