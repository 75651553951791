
 <!-- <div class="control-panel-col-header commonvul_header mb0">
    <h3 *ngIf="title" class="spark-sec-tittle">{{title}}</h3>
    <div class="spark-btngroup device-gtngroup region-sort" class="region-sort">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
            class="spark-btngroup cyber-reilience-gtngroup" [(ngModel)]="this.selectedVulnarability.id">
            <mat-button-toggle (click)="filterVulnarability(value, true)" [ngClass]="{'active':this.selectedVulnarability.id==value.id}"*ngFor="let value of vulnarabilityMenu" [ngClass]="{'active':this.selectedVulnarability.id==value.id}" value={{value.id}}>{{value.name}}</mat-button-toggle> 
        </mat-button-toggle-group>
    </div>
</div> -->
<div class="out-layout">
    <div class="vulnerabilities_page page_content">
        <div class="vulnerabilities-header">
            <div class="left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Controls</mat-label>
                            <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text" (input)="filterAssetsField()"
                            #searchField>
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="right-mgt-head">
                <div class="Filter-dropdown">
                    <span class="all-text">All</span><mat-icon>arrow_drop_down</mat-icon>
                </div>
            </div> -->
        </div>
        <app-ag-grid #myGrid [agDeviceColumnDefs]="agDeviceColumnDefs" [data]="commonVulnarabilites"
            [gridClass]="'new_vul_tabel ag-grid-loader-bg'" [loader]="isApiCalled" [tableKey]="'new-vul-table-list'">
        </app-ag-grid>
    </div>
</div>