<div class="ag-center">
    <!-- <ng-container *ngIf="!getStatus(params.data); else rescanText">
    <span [ngClass]="{'scan-count': params.data.total != null}">{{params.data.total }}</span>
    <span class="text_ellipsis">{{params.value ? params.value : ''}}</span>
    <mat-icon class="mat-icon-reversed gray-icon-refresh" *ngIf="hasPermission(permissionProps.RESCAN_DEVICE) 
    && params.data.rescan && is_rescan && params.data.status!==4" (click)="rescan(params.data)" 
    >refresh</mat-icon>
    </ng-container> -->
    <div *ngIf="!getStatus(params.data); else rescanText" class="total-assets">
        <span [ngClass]="{'scan-count': params.data.total != null}">{{params.data.total }}</span>
        <!-- <span class="text_ellipsis" appSafariEllipsis matTooltip="{{params.value ? params.value : ''}}">{{params.value ? params.value : ''}}</span> -->
            <p matTooltip="{{params.value ? params.value : ''}}" class="text_ellipsis paramer-value">{{params.value ? params.value : ''}}</p>
        <mat-icon class="mat-icon-reversed gray-icon-refresh" *ngIf="hasPermission(permissionProps.RESCAN_DEVICE) 
        && params.data.rescan && is_rescan && params.data.status!==4" (click)="rescan(params.data)" 
       matTooltip="Re-Scan" >refresh</mat-icon>
    </div>
    <ng-template #rescanText>
        <p><span class="scan-count"><mat-icon>loop</mat-icon></span><span>Scanning...</span>
        </p>
    </ng-template>
</div>