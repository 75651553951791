<div>
    <div class="controls-deails">
        <div class="close-btn">
            <button (click)="closeSidenav()" mat-button class="btn close-sidebar">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
        <div class="controls-header">
            <h3>
                Ensure ‘Idle timeout’ is less than or equal to Ensure ‘Idle timeout’
            </h3>
        </div>
        <div class="header-labels">
            <span class="vulnerab_label new-critical br-radius label_dot">Critical
            </span>
            <span class="vulnerab_label manual br-radius">Manual</span>
        </div>
    </div>
    <div [class.hidden]="hideTab" class="controls-content">
        <mat-tab-group #tabgroup (selectedTabChange)="onTabChange($event)" class="framework-tabgroup">
            <mat-tab label="Details">
                <div class="asset-tabcontent">
                    <mat-card>
                        <div class="details-status">
                            <div class="details-status-item">
                                <p class="status-type ">Total Resources</p>
                                <p class="status-value total ">56</p>
                            </div>
                            <div class="details-status-item">
                                <p class="status-type ">Failed</p>
                                <p class="status-value failed">56</p>
                            </div>
                            <div class="details-status-item">
                                <p class="status-type ">Pass</p>
                                <p class="status-value pass">56</p>
                            </div>
                            <div class="details-status-item">
                                <p class="status-type">Accepted Risk</p>
                                <p class="status-value risk-accepted">56</p>
                            </div>
                        </div>
                        <div class="benchmark-staus">
                            <div class="benchmark-values">
                                <div>NIST</div>
                            </div>
                            <div class="version-values">
                                <div> CIS V3
                                    <button class="drop-down-btn" mat-button><mat-icon>arrow_drop_down</mat-icon>
                                    </button>
                                    <mat-menu class="white-menu">
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div class="parameter-details">
                            <div class="parameter-header">
                                <div class="sub-text">1.4</div>
                                <p class="header-text">Ensure no “root” user account access key exists </p>
                                <div class="sub-text">Level 1</div>
                            </div>
                            <p class="parameter-description">
                                Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh
                                eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum
                                id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis
                                vel.
                            </p>
                            <div class="description-values">
                                <div class="description-top">
                                    <div class="top-left">
                                        <div class="descript-icon">
                                            <img src="" alt="">

                                        </div>
                                        <h5 class="value">Remediation:</h5>
                                        <!-- <button class="btn btn-expand" mat-button><mat-icon>keyboard_arrow_right</mat-icon></button> -->
                                        <button class="btn btn-expand"
                                            mat-button><mat-icon>keyboard_arrow_down</mat-icon></button>
                                    </div>
                                    <p class="parameter-description">Perform the following to determine if the 'root'
                                        user account has access keys: </p>

                                </div>
                                <div class="description-bottom">
                                    <div class="from-controls">
                                        <h6 class="control-header">From Console:</h6>
                                        <ol class="control-list order-list">
                                            <li> Login to the AWS Management Console.</li>
                                            <li>Click Services.</li>
                                            <li>Click IAM.</li>
                                            <li>Click on Credential Report.</li>
                                            <li>This will download a .csv file which contains credential usage for all
                                                IAM users within an AWS Account - open this file.</li>
                                            <li>For the user, ensure the access_key_1_active and access_key_2_active
                                                fields are set to FALSE.</li>
                                        </ol>
                                    </div>
                                    <div class="from-controls">
                                        <h6 class="control-header">From Command Line:</h6>
                                        <p class="control-list">Run the following command:</p>
                                        <div class="command-link">
                                            <p>aws iam get-account-summary | grep "AccountAccessKeysPresent</p>
                                            <button mat-button class="btn"><mat-icon>content_copy</mat-icon></button>
                                        </div>
                                        <p class="control-list">If no 'root' access keys exist the output will show
                                            "AccountAccessKeysPresent": 0,. If the output shows a "1", then 'root' keys
                                            exist and should be deleted.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </mat-tab>
            <mat-tab label="Security Frameworks">
                <div class="security-frameworks">
                    <div class="frameworks-container">
                        <div (click)="logoclick(benchmark)"
                            [ngClass]="{'white-box': benchmark.type === selectedBenchmark}" class="framework-logo"
                            *ngFor="let benchmark of showBenchamrks">
                            <img [src]="benchmark.image_src">
                        </div>
                    </div>
                    <div class="framework-details" *ngIf="selectedBenchmark === 'cis'">
                        <div class="details-version ">
                            <div>
                                <ol class="order_list">
                                    <li *ngFor="let version of cisJson.cisVersions">
                                        <h4>{{ version.cisVersion }}</h4>
                                        <h3>{{ version.cisHeader }}</h3>
                                        <p class="text">{{ version.cisData }}</p>
                                        <div class="bottom-labels">
                                            <p>IG 1:</p>
                                            <p>IG 1:</p>
                                            <p>IG 1:</p>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div class="framework-details" *ngIf="selectedBenchmark === 'nist'">
                        <div class="details-version ">
                            <div>
                                <ol class="order_list">
                                    <li *ngFor="let version of cisJson.cisVersions">
                                        <h4>{{ version.cisVersion }}</h4>
                                        <h3>{{ version.cisHeader }}</h3>
                                        <p class="text">{{ version.cisData }}</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="framework-details" *ngIf="selectedBenchmark === 'hippa'">
                        <p>hippa</p>
                    </div>
                    <div class="framework-details" *ngIf="selectedBenchmark === 'pci'">
                        <p>pci</p>
                    </div>
                    <div class="framework-details scroll" *ngIf="selectedBenchmark === 'mitre'">
                        <div class="mitre-info">
                            <div class="mitre-header">
                                <img src="" alt="img">
                                <h4>Reconnaissance (T000124)</h4>
                                <a href="">copy</a>
                            </div>
                            <p class="sub-text">Gather Victim Host Information (T00124) <a href="">copy</a></p>
                            <div class="mitre-description">
                                <h5>Description</h5>
                                <p class="pb_16">Adversaries may gather information about the victim's hosts that can be
                                    used during targeting. Information about hosts may include a variety of details,
                                    including administrative data (ex: name, assigned IP, functionality, ...More</p>
                            </div>
                            <div class="mitre-item">
                                <div class="mitre-item-top">
                                    <div class="item-icon">
                                        <img src="../../../../../assets/images/Capa_1 (1).svg">
                                    </div>
                                    <h5>Remediation <a href="">copy</a></h5>
                                    <button class="btn expand-btn"><mat-icon>keyboard_arrow_right</mat-icon></button>
                                </div>
                                <div class="mitre-item-content">
                                    <div class="item-info">
                                        <h4>ID</h4>
                                        <p class="pb_16">DS0034</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Data Source</h4>
                                        <p class="pb_16">Internet Scan</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Data Component</h4>
                                        <p class="pb_16">Response Content</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Detects</h4>
                                        <p class="pb_16">Internet scanners may be used to look for patterns associated
                                            with malicious content designed to collect host information fro visitors.
                                            Much of this activity may have a very high occurrence and associated false
                                            positive rate, as well as potentially taking place outside the visibility of
                                            the target organization, makin detection difficult for defenders. Detection
                                            efforts may be focused on related stages of the adversary lifecycle, such as
                                            during initial Access.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mitre-item">
                                <div class="mitre-item-top">
                                    <div class="item-icon">
                                        <img src="../../../../../assets/images/Capa_1 (1).svg">
                                    </div>
                                    <h5>Remediation <a href="">copy</a></h5>
                                    <button class="btn expand-btn"><mat-icon>keyboard_arrow_right</mat-icon></button>
                                </div>
                                <div class="mitre-item-content">
                                    <div class="item-info">
                                        <h4>ID</h4>
                                        <p class="pb_16">DS0034</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Data Source</h4>
                                        <p class="pb_16">Internet Scan</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Data Component</h4>
                                        <p class="pb_16">Response Content</p>
                                    </div>
                                    <div class="item-info">
                                        <h4>Detects</h4>
                                        <p class="pb_16">Internet scanners may be used to look for patterns associated
                                            with malicious content designed to collect host information fro visitors.
                                            Much of this activity may have a very high occurrence and associated false
                                            positive rate, as well as potentially taking place outside the visibility of
                                            the target organization, makin detection difficult for defenders. Detection
                                            efforts may be focused on related stages of the adversary lifecycle, such as
                                            during initial Access.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Resources(25)">
                <app-resource></app-resource>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div *ngIf="resourceData">
    <!-- <div class="controls-deails">
        <div class="controls-header">
            <h3>
                Ensure ‘Idle timeout’ is less than or equal to Ensure ‘Idle timeout’
            </h3>
        </div>
        <div class="header-labels">
            <p>critical</p>
            <p>Manual</p>
        </div>
    </div> -->
    <div class="resources">
        <div class="resource_content horizontal-border-bottom">
            <button (click)="goBack()">Back!!</button>
            <img src="../../../../../../assets/images/aws_resource.svg" alt="AWS Resource">
            <p>vpc-<b>04b1cee16656df312</b></p>
            <p>Acc:<b>27401825011589</b> </p>
            <p>Resource type: <b>VPC</b></p>
        </div>
    </div>
    <div class="resources_status">
        <p>Status:</p>
        <p>Accepted Risk:</p>
        <p>Last Reviewed:</p>
    </div>
    <div class="resources_current_status">
        <p>Pending Review</p>
        <p>Not Accepted</p>
        <p>29 Jan,2024</p>
    </div>
</div>

<!-- Resource content code starts here -->
<div>
    <div class="controls-deails">
        <div class="controls-header">
            <h3>
                Ensure ‘Idle timeout’ is less than or equal to Ensure ‘Idle timeout’
            </h3>
        </div>
        <div class="header-labels">
            <p>critical</p>
            <p>Manual</p>
        </div>
    </div>
    <div class="resources">
        <div class="resource_content">
            <img src="../../../../../../assets/images/aws_resource.svg" alt="AWS Resource">
            <p class="text"> <span>vpc-04b1cee16656df312</span></p>
            <p class="text">Acc: <span>27401825011589</span> </p>
            <p class="text">Resource type: <span>VPC</span></p>
        </div>
        <div class="resources_status">
            <div class="status-item">
                <p class="text1">Status:</p>
                <p class="text2">Pending Review</p>
            </div>
            <div class="status-item">
                <p class="text1">Accepted Risk:</p>
                <p class="text2">Not Accepted</p>
            </div>
            <div class="status-item">
                <p class="text1">Last Reviewed:</p>
                <p class="text2">29 Jan,2024</p>
            </div>
        </div>
        <div>
            <mat-tab-group #tabgroup (selectedTabChange)="onTabChange($event)" class="resource-tabgroup">
                <mat-tab label="Review">
                    <div class="resource-tabcontent">
                        <mat-card>
                            <p class="review_details">Details</p>
                            <div class="review_status">
                                <p>Confirm if each route table has least required access</p>
                                <!-- <p>Status: <img src="../../.././../../assets/images/ManuvallyFail.svg" alt=""></p> -->
                                <div class="status-info">
                                    <p>Status: </p>
                                    <p class="status-info-left"><img
                                            src="../../.././../../assets/images/ManuvallyFail.svg" alt="">Failed</p>
                                </div>
                            </div>
                            <div class="tables-data toast-container-failed">
                                <div class="table-status">
                                    <mat-checkbox class="mat-checkbox-label">Table ID 1</mat-checkbox>
                                    <button class="btn expand-btn"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                </div>
                                <ol>
                                    <li>Route 1</li>
                                    <li>Route 2</li>
                                    <li>Route 3</li>
                                </ol>
                            </div>
                            <div class="tables-data toast-container-failed">
                                <div class="table-status">
                                    <mat-checkbox class="mat-checkbox-label">Table ID 1</mat-checkbox>
                                    <button class="btn expand-btn"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                </div>
                                <ol>
                                    <li>Route 1</li>
                                    <li>Route 2</li>
                                    <li>Route 3</li>
                                </ol>
                            </div>
                            <div class="bottom-btns">
                                <button class="btn btn-primary-outline br-radius"><mat-icon
                                        class="sort-icon">keyboard_arrow_left</mat-icon>Previous Account
                                </button>
                                <button class="btn btn-primary-outline br-radius">Next Account<mat-icon
                                        class="sort-icon">keyboard_arrow_right</mat-icon>
                                </button>
                                <button class="btn btn-primary">Submit
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </mat-tab>
                <mat-tab label="Last Review">
                    <div class="asset-tabcontent">
                        <mat-card>
                            <p>Last Review</p>
                        </mat-card>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

    </div>

</div>