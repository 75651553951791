<div class="device-popup">
    <div class="device-popup-header">
        <!-- <h3 class="text_ellipsis" appSafariEllipsis>{{ deviceId }}&nbsp;({{deviceVendor}}-{{deviceType}})</h3> -->
        <!-- <p class="text_ellipsis" appSafariEllipsis matTooltip="{{regionName}}">{{regionName}}</p> -->
        <h3>
            <p matTooltip="{{deviceId}}" class="text_ellipsis paramer-value">{{deviceId}}</p>
        </h3>
        
            <p matTooltip="{{regionName}}" class="text_ellipsis paramer-value">{{regionName}}</p>
        
        <span class="popup-close-btn"><button mat-button [mat-dialog-close]="true">
                <mat-icon>cancel</mat-icon></button></span>
    </div>
    <div class="device-data d-flex scroll">
        <div class="device-data-left">
            <div class="device-data-left-toprow  d-flex">
                <div class="sec-card spark-whitebox spark-whiteboxshadow relative">
                    <app-security-score [isOverlay]="true" [apiName]="securityApiName" [title]="securityGraphTitle"></app-security-score>
                </div>
                <!-- <div class="device-popup-col app-category-wise">
                    <app-category-wise [categoryWiseGraphSec]="'bar-chart-pop-up'" [title]="categoryGraphTitle" [apiName]="categoryGraphApiName" [payLoad]="apiPayload"></app-category-wise>
                </div> -->
                <div class="vul-card spark-whitebox spark-whiteboxshadow">
                    <app-vulnerability [vulnerabilityGraphSec]="'pie-chart-pop-up'" [apiName]="vulnerabilityApiName"
                        [title]="vulnerabilityGraphTitle"></app-vulnerability>
                        <app-vulnerability [vulnerabilityGraphSec]="'pie-chart-pop-up'" [apiName]="vulnerabilityCveApiName"
                        [title]="vulnerabilityCveGraphTitle"></app-vulnerability>
                        <div class="severity-label-section">
                            <div class="severity-label">
                                <div class="color-indicator critical"></div>
                                <div class="text">Critical</div>
                            </div>
                            <div class="severity-label">
                                <div class="color-indicator high"></div>
                                <div class="text">High</div>
                            </div>
                            <div class="severity-label">
                                <div class="color-indicator medium"></div>
                                <div class="text">Medium</div>
                            </div>
                            <div class="severity-label">
                                <div class="color-indicator low"></div>
                                <div class="text">Low</div>
                            </div>
    
                        </div>
                </div>
            </div>
            <div class="spark-whitebox spark-whiteboxshadow device-cyber-reilience">
                <app-cyber-reilience [filterPage]="'cyberDevice'" [title]="cyberResilienceGraphTitle"
                    [apiName]="cyberResilienceGraphApiName" [showFilter]="cyberResilienceFilter" [payLoad]="apiPayload">
                </app-cyber-reilience>
            </div>
        </div>
        <div class="device-data-right ">
            <div class=" spark-whitebox p0 spark-whiteboxshadow device-data-right-inner" >
                <app-common-vulnerability [filterPage]="'commonVulDevice'" [title]="commonVulnerabilityTitle"
                    [apiName]="commonVulnerabilityApiName" [showFilter]="commonVulnerabilityFilter" [payLoad]="apiPayload">
                </app-common-vulnerability>
            </div> 
        </div>
    </div>
</div>