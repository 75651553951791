<div class="out-layout">
    <div class="cloud_security page_content">
        <div class="cloud-security-header">
            <div class="left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Controls</mat-label>
                        <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text"
                            [ngModel]="searchText" (input)="filterAssetsField()" #searchField>
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>

            </div>
            <div class="right-mgt-head">
                <div><Span class="head-class">Group by:</Span></div>
                <div *ngIf="sortByDropdownList.length" class="region-sort">
                    <div mat-button [matMenuTriggerFor]="AssetWith" class="btn-custom">
                        <label class="sort-title">{{ this.selectedDeviceWithSortBy.name }}</label>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #AssetWith="matMenu" class="sort-menu assetwith-btn">
                        <div>
                            <button (click)="sortByDevice(value)" *ngFor="let value of sortByDropdownList;let i = index"
                                mat-menu-item class="list-item"
                                [ngClass]="{'active': value.id === this.selectedDeviceWithSortBy.id}">{{ value.name
                                }}</button>
                        </div>
                    </mat-menu>
                </div>

            </div>
        </div>
        <app-ag-grid *ngIf="groupByValue === 1" #myGrid [agDeviceColumnDefs]="agScanColumnDefs" [data]="dataSource"
            [gridClass]="'cloud_security_class ag-grid-loader-bg'" [loader]="true" [customSidebar]="dataSource.length > 0"
            (onRowSelectedChange)="onRowSelected($event)" [tableKey]="'cloud-table'">
        </app-ag-grid>
  
        <div class="resource-type-table" *ngIf="groupByValue !== 1" >
            <div *ngFor="let resource of dataResource; let i = index ">
                <div class="resource-types" >
                    <button class="expand-btn btn" [ngClass]="{'expand': resource.selected}" mat-button
                        (click)="toggleDescription(resource)">
                    </button>
                    <div class="resource-type">
                        <div class="type">vpc {{resource.resourceType}}</div>
                        <div class="count"> Number of Controls
                            <div class="number-label">{{resource.childRows.length}}</div>
                        </div>
                        <div class="resource-complaince">Complaince:
                            <div class="complaint-assets">
                                <!-- <p class="doc-persentage"> {{params.data.passed_count}}/{{params.data.count}} ({{params.data.passd_percentage+'%'}})
                                </p>
                                <span class="doc-persentage-value"> <span class="gray-bg scale gray-width "></span>
                                  <span class="green-bg scale" [ngStyle]="{'width': params.data.passd_percentage+'%'}"></span></span>
                              </div> -->
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="recource-details" *ngIf="resource.selected">
                <app-ag-grid
                #myGrid
                [agDeviceColumnDefs]="agScanColumnTypeDefs"
                [data]="resource.childRows"
                [gridClass]="'cloud_security_class ag-grid-loader-bg'"
                [loader]="true"
                [customSidebar]="false"
                [isPegination]="false"
                (onRowSelectedChange)="onRowSelected($event)"
                [tableKey]="'cloud-table'">
               </app-ag-grid>
            </div>
            </div>
        
    </div>
</div>
</div>
 