import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, searchText?: any): any {
    if(!value) return [];
    if(!searchText) return value;
    searchText = searchText.toLowerCase();
    return value.filter( (item: any): any => {
      return (item.device_category?.toLowerCase().includes(searchText) || 
      item.host_name?.toLowerCase().includes(searchText) ||
      item?.name?.toLowerCase().includes(searchText) ||
       item?.vendor_category?.toLowerCase().includes(searchText) ||
       item?.asset_name?.toLowerCase().includes(searchText) ||
       item?.assetName?.toLowerCase().includes(searchText) );
    });
  }

}
@Pipe({
  name: 'searchSingle'
})

export class SearchPipeT implements PipeTransform {

  transform(value: any, searchText?: any): any {
    if(!value) return [];
    if(!searchText) return value;
    searchText = searchText.toLowerCase();
    return value.filter((item: any): any => {
      const itemString = JSON.stringify(item);
      return itemString?.toLowerCase().includes(searchText);
    });
  }
}
@Pipe({
  name: 'Highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(list: any, searchText: string): any {
    if (!list) { return []; }
    if (!searchText) { return list; }
    const value = list.replace(new RegExp(searchText, "gi"), (match: any) => {
      return '<b class="highlight-search">' + match + '</b>';
    });
    return this._sanitizer.bypassSecurityTrustHtml(value)
  }
}

@Pipe({
  name: 'searchAgent'
})
export class SearchAgentPipe implements PipeTransform {

  transform(value: any, searchText?: any): any {
    if(!value) return [];
    if(!searchText) return value;
    searchText = searchText.toLowerCase();
    return value.filter( (item: any): any => {
      return (
      item?.name?.toLowerCase().includes(searchText) ||
      item?.description?.toLowerCase().includes(searchText) ||
      item?.devices_vendor_name?.toLowerCase().includes(searchText)
       );
    });
  }

}