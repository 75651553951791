<div class="hover-container" id="draggable" [ngClass]="{'active': isSidebarOpen}" (click)="toggleSidebar()">
  <button class="column-button"><mat-icon>apps</mat-icon></button>
  <div class="custom-sidebar">
    <h4>Columns</h4>
    <div *ngFor="let col of columnDefs" class="column-items">
      <ng-container *ngIf="col.width != 0 && (col.lockPosition === undefined || col.lockPosition === false)">
        <!-- <input type="checkbox" [checked]="col.visible !== false" (change)="onToggleColumn(col.field!, $event)"> -->
        <!-- {{ col.headerName }}  -->
        <div class="custom-checkbox mat-accent">
          <input type="checkbox" [checked]="col.visible !== false" (change)="onToggleColumn(col.field!, $event)" id="checkbox-{{col.field}}" class="checkbox">
          <label for="checkbox-{{col.field}}">
            {{ col.headerName }}
          </label>
        </div>
        
        
       
      </ng-container>
    </div>
  </div>
</div>
