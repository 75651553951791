import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DUMMEY_RES } from './static';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { SecurityInformationService } from 'src/app/modules/shared/services/security-information.service';
import { IList, IListDetails, IListResponse, ISelectedListDetails, ISelectedListDetailsResponse, ISelectedListInfoDetails, IWhiteListResponse } from 'src/app/modules/shared/components/list/list.model';

import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { GlobalFilterService } from 'src/app/modules/shared/services/global-filter.service';
import { Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap } from 'rxjs';
import * as _ from 'lodash';
import { WhitelistReasonComponent } from 'src/app/modules/scan-details/components/whitelist-reason/whitelist-reason.component';
import { IMainFilter, ISortingFilter, SortingFilterService } from 'src/app/modules/shared/services/sorting-filter.service';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { GlobalSearchService } from 'src/app/modules/shared/services/global-search.service';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { ListComponent } from 'src/app/modules/shared/components/list/list.component';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/modules/shared/services/api.service';

@Component({
  selector: 'app-security-information',
  templateUrl: './security-information.component.html',
  styleUrls: ['./security-information.component.scss']
})
export class SecurityInformationComponent implements OnInit {
  @ViewChild(ListComponent) listComp!: ListComponent;
  @ViewChild('searchField') searchField!: ElementRef;
  public totalPage: number = 1;
  public page: number = 1;
  public numberOfResults: number = Math.ceil((window.innerHeight - 210) / 58);
  public securityInfoDataDetails: IListDetails = {};
  public securityInfoData: IList[] = [];
  public selectedRowDetails: ISelectedListDetails[] | undefined = [];
  public selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
  public filterValue: string | undefined = undefined;
  public currentSelectedRow!: IList;
  public comments = "";
  public isWhiteListApiCall = false;
  public filterSubscription!: Subscription;
  public filterList!: ISortingFilter;
  public selectedRowApiCall = false;
  public selectedApiAssets = false;
  public assetsPage = 1;
  public totalAssetsPage = 1;
  public numberOfDeviceListResults: number = Math.ceil((window.innerHeight - 153) / 30);
  public noDataMsg: string = CONSTANT.NO_DATA_MSG;
  public searchText: string = '';
  public perameterId: string = '';
  public securityInfoSubscription!: Subscription;
  public tableKey:string='sec-info-table';
  glbSearch: Subscription;
  assestSearchTrue: boolean = false;
  securitySharedServiceData: any;
  constructor(private spinnerService: SpinnerService,
    private securityInformationService: SecurityInformationService,
    private dialog: MatDialog,
    private messageService: MessageServiceService,
    private headerService: HeaderService,
    private globalFilterService: GlobalFilterService,
    private sortingFilterService: SortingFilterService,
    private sharedService: SharedService,
    private globalSearchService: GlobalSearchService,
    private authService: AuthService,
    private responseService: ResponseService,
    private dialogService: DialogService,
    private myScansService: MyScansService,
    private router: Router,
    private apiService: ApiService
    ) {
      this.headerService.setHeading('Security Information', {type: 'securityInformation'});
      this.glbSearch = this.globalSearchService.searchCleared?.subscribe(() => {
        this.page = 1;
        this.securityInfoData = [];
        this.isWhiteListApiCall = false;
        if(this.listComp) {
          this.listComp.page = 1;
          this.listComp.data = [];
          this.listComp.isApiCallDone = false;
          this.listComp.isDataAvailableOnFilter = true;
          this.listComp.dataSource.data = [];
        }
        this.filterList?.filters?.benchmark?.map((f: any) => (f.activated = false ) );
        this.filterList?.filters?.severity?.map((f: any) => (f.activated = false ) );
        this.perameterId = "";
        this.searchField.nativeElement.disabled = false;
        if(!this.globalSearchService.isRedirected) {
          this.searchField.nativeElement.value = "";
          this.sharedService.clearData();
          this.updateAppliedFilter();
          // this.headerService.dispatchDisableSearch(false);
        } else {
          const filter = this.sharedService.getData();
          if(filter && Object.keys(filter).length > 0){
            this.searchField.nativeElement.value = "";

            if(filter?.paramter || filter?.name|| filter?.benchmark_name) {
              // if(filter.benchmark){
              //   this.perameterId = filter.name;
              // } else {
              //   this.perameterId = filter.param_id || filter.id || filter.benchmark_id;
              // }

              if(filter.benchmark || filter?.benchmark_name){
                //this.perameterId = filter.name || filter?.benchmark_name;
                const filterModel = {
                  bechmark: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filter.benchmark_name // You can change the value dynamically
                  }
                };
                this.authService.setCookie(`agGridFilter_${this.tableKey}`,JSON.stringify(filterModel));
                //this.searchField.nativeElement.value=this.perameterId;
                //this.searchText=this.perameterId;
              } else {
                // if(filter.name && filter.name.endsWith('.')){
                //   filter.name = filter.name.slice(0, filter.name.length - 1);
                // }
                this.perameterId = filter.id||filter.paramter ||filter.name|| filter.param_id ||  filter.benchmark_id;
                this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
                //this.searchField.nativeElement.value=this.perameterId;
                //this.searchText=this.perameterId;
              }

              // if(this.searchField){
              //   this.searchField.nativeElement.disabled = true;
              // }
            }
            this.updateAppliedFilter();
            const result = this.filterList?.filters?.benchmark?.find((f: any) => (f.id === filter.id|| f.id===filter.benchmark_id ) );
            if(result){
              result.activated = true;
            }
          }
        }
        this.listComp?.updateFilterDropdown();
        this.listComp?.updateAppliedFilter();
        this.securityInfoList(this.page);
        this.headerService.dispatchDisableSearch(false);
       });
  }
  onSearchTextChange(newText: string): void {
    this.searchText = newText; // Update the searchText whenever a change is detected
  }
  ngOnInit(): void {
    this.sortingFilterService.securityInfoFilter((res: ISortingFilter)=>{
      this.filterList = res;
      const filter = this.sharedService.getData();
      console.log(filter.redirection)
      const customFilter: any = {};
      if(Object.keys(filter).length > 0){
        this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
        this.searchText = "";
        this.perameterId = "";
        // if(this.searchField){
        //   this.searchField.nativeElement.disabled = false;
        // }

          if(filter.benchmark || filter?.benchmark_name){
            //this.perameterId = filter.name || filter?.benchmark_name;
            const filterModel = {
              bechmark: {
                filterType: 'text',
                type: 'contains',
                filter: filter.benchmark_name // You can change the value dynamically
              }
            };
            this.authService.setCookie(`agGridFilter_${this.tableKey}`,JSON.stringify(filterModel));
            //this.searchField.nativeElement.value=this.perameterId;
            //this.searchText=this.perameterId;
          } else {
            // if(filter.name && filter.name.endsWith('.')){
            //   filter.name = filter.name.slice(0, filter.name.length - 1);
            // }
            this.perameterId =filter.id|| filter.paramter ||filter.name|| filter.param_id  || filter.benchmark_id;
            this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
            //this.searchField.nativeElement.value=this.perameterId;
            //this.searchText=this.perameterId;
          }

          // if(this.searchField){
          //   this.searchField.nativeElement.disabled = true;
          // }

        // if(filter?.paramter || filter?.name) {
        //   if(filter.benchmark){
        //     this.perameterId = filter.name;
        //   } else {
        //     this.perameterId = filter.param_id || filter.id;
        //   }
        //   if(this.searchField){
        //     this.searchField.nativeElement.disabled = true;
        //   }
        // }
        this.filterList.filters.benchmark?.map((f: any) => (f.activated = false ));
        this.filterList?.filters?.severity?.map((f: any) => (f.activated = false ) );
        if(filter?.name) {
          this.filterList?.filters?.severity.forEach((obj: any)=>{
            if(filter?.name.toLowerCase() === obj.name.toLowerCase()) {
              obj.activated = true;
            }
          })
        }
        this.updateAppliedFilter();
        const result = this.filterList.filters.benchmark.find((f: any) => (f.id === filter.id||f.id ===  filter.benchmark_id) );
        if(result){
          result.activated = true;
          customFilter.benchmark = [result.id]
        }
      }
      this.listComp?.updateAppliedFilter();
      this.securityInfoList(this.page);
      this.sharedService.clearData();
    })
    this.filterSubscription = this.globalFilterService.updateAfterApplyState.subscribe((flag: boolean)=>{
      this.page = 1;
      this.listComp.page = 1;
      this.isWhiteListApiCall = false;
      this.listComp.data = [];
      this.listComp.dataSource.data = [];
      this.securityInfoData = [];
      this.myScansService.scrollTop('listTable');
      this.securityInfoList(this.page);
    })
    // const navigationState = this.router.getCurrentNavigation()?.extras?.state || history.state;
    // if (navigationState && navigationState['searchTrue']) {
    //   this.assestSearchTrue = navigationState['searchTrue']
    // }
  }
  ngAfterViewInit() {
    // this.searchField.nativeElement.disabled = false;
    // if(this.perameterId){
    //   this.searchField.nativeElement.disabled = true;
    // }

    // const obs = fromEvent(this.searchField?.nativeElement, 'keyup');
    //   obs.pipe(
    //     filter((data: any) => {
    //       return data && (data.target && (data.target?.value.length > 2 || !data.target?.value.length));
    //     }),
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     switchMap(data => {
    //       this.page = 1;
    //       this.listComp.page = 1;
    //       this.isWhiteListApiCall = false;
    //       this.listComp.data = [];
    //       this.listComp.dataSource.data = [];
    //       this.securityInfoData = [];
    //       this.myScansService.scrollTop('listTable');
    //       return  this.securityInformationService.fetchSecurityInformationList(this.page, this.numberOfResults, (data.target?.value || this.perameterId));
    //     })).subscribe({
    //       next: (response: HttpResponse<any>) => {
    //         this.securityInfoListCallBack(this.responseService.successResponse(response));
    //       },
    //       error: (err: HttpErrorResponse) => {
    //         this.securityInfoListCallBack(this.responseService.errorResponse(err));
    //       },
    //     })
  }
  ngOnDestroy(){
    this.filterSubscription.unsubscribe();
    if(this.glbSearch){
      this.glbSearch.unsubscribe();
    }
    this.globalSearchService.clearSearch();
    if (this.assestSearchTrue) {
      this.securitySharedServiceData = []
      this.securitySharedServiceData.response = []
    }
    this.apiService.unsubscribeApiCall();
  }
  public securityInfoList(page: number = 1) {
    this.isWhiteListApiCall = false;
    this.numberOfResults = Math.ceil((window.innerHeight - 210) / 58) + 1;
    let data =  this.sharedService.getAPIData(CONFIG.PAGE_API.SECURITY_INFO_LIST);
    if(false) {
      const timer = setTimeout(()=>{
        this.securityInfoListCallBack(data.response);
        clearTimeout(timer);
      })
    } else {
      this.securityInformationService.fetchSecurityInformationList(page, this.numberOfResults, (this.searchField?.nativeElement.value || this.perameterId),this.securityInfoListCallBack)
    }
    // if (this.assestSearchTrue) {
    //   if (this.securitySharedServiceData && this.securitySharedServiceData.isDataAvailable) {
    //      this.securitySharedServiceData = []
    //      this.securitySharedServiceData.response = []
    //   }
    //   this.securityInformationService.fetchSecurityInformationList(page, this.numberOfResults, (this.searchField?.nativeElement.value || this.perameterId),this.securityInfoListCallBack)
    // }else if(this.securitySharedServiceData && this.securitySharedServiceData.isDataAvailable) {
    //   const timer = setTimeout(()=>{
    //     this.securityInfoListCallBack(this.securitySharedServiceData.response);
    //     clearTimeout(timer);
    //   })
    // } else {
    //   this.securityInformationService.fetchSecurityInformationList(page, this.numberOfResults, (this.searchField?.nativeElement.value || this.perameterId),this.securityInfoListCallBack)
    // }

  }
  customSortOrder = ['Critical', 'High', 'Medium', 'Low']; // Mixed case order
  sortDataByVulnerability(): void {
    const sortedData = [...this.securityInfoData].sort((a: any, b: any) => {
      const vulnerabilityA = a.vulnerability.toLowerCase();
      const vulnerabilityB = b.vulnerability.toLowerCase();
  
      // Ensure case-insensitive comparison for customSortOrder
      const orderA = this.customSortOrder.findIndex(order => order.toLowerCase() === vulnerabilityA);
      const orderB = this.customSortOrder.findIndex(order => order.toLowerCase() === vulnerabilityB);
  
      return orderB - orderA;
    });
  
    this.securityInfoData = sortedData.reverse(); // Reassign sorted data to this.data
  }

  public securityInfoListCallBack = (res: IListResponse | any) => {
    this.spinnerService.hide();
    this.isWhiteListApiCall = true;
    if (res?.status === 200 && res.data && Object.keys(res.data).length) {
      this.securityInfoDataDetails = res.data;
      this.totalPage = Number(res.data.numberofpages);
      if (this.securityInfoData && this.securityInfoData.length) {
        if(this.securityInfoDataDetails.indexs){
          this.securityInfoData = this.securityInfoData.concat(this.securityInfoDataDetails.indexs);
        }
      } else {
        
        this.securityInfoData = this.securityInfoDataDetails.indexs as IList[];
        this.sortDataByVulnerability();
        if (this.page === 1 && this.securityInfoDataDetails.indexs  && this.securityInfoDataDetails.indexs.length) {
          this.selectedSecurityDetails(this.securityInfoData[0]);
        }
      }
      this.sharedService.setAPIData(CONFIG.PAGE_API.SECURITY_INFO_LIST, res, Object.keys(res.data).length > 0);
    }
  }

  public updateListOnScroll(page: number) {
    this.securityInfoList(page);
  }
  public updateAppliedFilter() {
    const filterDetails: any = JSON.parse(this.authService.getCookie('securityInfo') || '{}');
    filterDetails.filters.benchmark = this.filterList?.filters?.benchmark;
    filterDetails.filters.severity = this.filterList?.filters?.severity;
    filterDetails.filters.parameters=this.filterList?.filters?.parameters;
    filterDetails.filters.device_failed_sort = this.filterList?.filters?.device_failed_sort;
    filterDetails.filters.accepted_risk_sort = this.filterList?.filters?.accepted_risk_sort;
    this.sortingFilterService.setFilter('securityInfo', filterDetails);
  }
  applySortingAndFilter = (page: number) => {
    this.page = 1;
    this.securityInfoData = [];
    this.updateAppliedFilter();
    this.securityInfoList();
  }
  public selectedSecurityDetails(row: IList, isScroll: boolean = false) {
    this.currentSelectedRow = row;
    this.selectedRowDetails = [];
    this.selectedRowInfoDetails = {};
    this.selectedRowApiCall = false;
    this.selectedApiAssets = false;
    const reqAssetsList = {
      page: this.assetsPage,
      parameter_id: this.currentSelectedRow,
      // content_number: this.numberOfDeviceListResults,
      // pages: this.page,
    }
    this.securityInformationService.fetchSelectedSecurityDetail(row?.id, this.fetchSelectedSecurityDetailCallback);

    this.securityInformationService.fetchSelectedSecurityAssetsList(reqAssetsList, this.fetchSelectedSecurityAssetsListCallback);
  }

  public fetchSelectedSecurityAssetsListCallback = (res: ISelectedListDetailsResponse) => {
    this.spinnerService.hide();
    this.selectedApiAssets = true;
    if (res.status === 200 && res?.data && res?.data.device_list?.length) {
        this.selectedRowDetails = _.cloneDeep(res.data?.device_list);
    }
  }
  public updateAssetsOnScroll(page: number){
    const reqAssetsList = {
      parameter_id: this.currentSelectedRow,
      content_number: this.numberOfDeviceListResults,
      pages: page
    }
    this.selectedApiAssets = false;
    this.securityInformationService.fetchSelectedSecurityAssetsList(reqAssetsList, this.fetchSelectedSecurityAssetsListCallback);

  }
  public onScroll(page: number) {
    this.updateAssetsOnScroll(page);
  }
  public fetchSelectedSecurityDetailCallback = (res: ISelectedListDetailsResponse) => {
    this.spinnerService.hide();
    this.selectedRowApiCall = true;
    if (res.status === 200) {
      this.selectedRowInfoDetails = _.cloneDeep(res.data?.details);
    }
  }
  public whiteList(selectedListDetails: ISelectedListDetails[]) {
    const payload: IDialog = {
      component:WhitelistReasonComponent,
      width: '510px',
      height: '450px',
      data: {
        Parameter: this.currentSelectedRow.paramter,
        setCallbackFunction: (result: { value: string, click: boolean }) => {
         this.whiteListCall(result, selectedListDetails)
        }
      },
    }
   this.dialogService.openDialog(payload)
    // dialogRef.afterClosed().subscribe((result: { value: string, click: boolean }) => {
    //   if (result.value != null && result.click) {
    //     this.comments = result.value;
    //     this.updateWhiteList(selectedListDetails)
    //   }
    // });
  }
  public whiteListCall(result:{ value: string, click: boolean },selectedListDetails: ISelectedListDetails[]){
    if (result.value != null && result.click) {
          this.comments = result.value;
          this.updateWhiteList(selectedListDetails)
        }
  }

  public updateWhiteList(selectedListDetails: ISelectedListDetails[]) {
    this.spinnerService.show();
    this.securityInformationService.updateWhiteListItem(selectedListDetails,this.comments, this.updateWhiteListItemCallback);
  }
  updateWhiteListItemCallback = (response: IWhiteListResponse) => {
    this.spinnerService.hide();
    this.selectedRowDetails = [];
    if (response?.status === 200) {
      const SuccessRes: IToastrOptions = {
        message: response.message
      }
      this.selectedSecurityDetails(this.currentSelectedRow);
      this.messageService.showSuccess(SuccessRes);
    }
  }

  public isRowSelected() {
    let isRowSelectedExists = false;
    if(this.currentSelectedRow && Object.keys(this.currentSelectedRow).length) {
      isRowSelectedExists = true;
    }
    return isRowSelectedExists;
  }
}
