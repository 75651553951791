<router-outlet></router-outlet>
<app-spinner></app-spinner>
<app-events-manager></app-events-manager>
<mat-sidenav-container  class="global-filter-main" [ngClass]="{'hide': !isLoggedIn}">
    <mat-sidenav  #globalFilter mode="over" position="end" class="global-filter-side-nav">
      <app-global-filter></app-global-filter>
    </mat-sidenav>
 
</mat-sidenav-container>

<mat-sidenav-container class="global-filter-main" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav  #globalSearch mode="over" position="end" class="global-filter-side-nav">
    <app-search-bar></app-search-bar>
  </mat-sidenav>
</mat-sidenav-container>

<mat-sidenav-container class="global-filter-main notification-popup auto-width" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav  #globalNotification mode="over" position="end" class="notification-side-nav">
   <app-notification></app-notification>
  </mat-sidenav>
</mat-sidenav-container>


<mat-sidenav-container  class="global-filter-main11" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav disableClose="true" #sideNavFilter mode="over" position="start" class="">
    <app-side-nav></app-side-nav>
  </mat-sidenav>
  
</mat-sidenav-container>


<mat-sidenav-container>
  <mat-sidenav-container  class="global-filter-main11 myaccount-popup" [ngClass]="{'hide': !isLoggedIn}">
    <mat-sidenav disableClose="true" #accountFilter mode="over" position="end" class="">
      <app-account-services></app-account-services>
      
    </mat-sidenav>
</mat-sidenav-container>

<mat-sidenav-container  class="global-filter-main assets-details-side-nav  scroll" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav #cveAssetsDetails mode="over" position="end" class="">
    <app-cve-assets-details></app-cve-assets-details>
  </mat-sidenav>
</mat-sidenav-container>

<mat-sidenav-container class="notification-popup" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav disableClose="true" #complainceReport mode="over" position="end" class="notification-side-nav">
  <app-complaince-report></app-complaince-report>
  </mat-sidenav>
</mat-sidenav-container>

<mat-sidenav-container class="notification-popup" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav disableClose="true" #editCloudScan mode="over" position="end" class="notification-side-nav">
  <app-edit-cloud-scan></app-edit-cloud-scan>
  </mat-sidenav>
</mat-sidenav-container>

<mat-sidenav-container  class="global-filter-main assets-details-side-nav  scroll" [ngClass]="{'hide': !isLoggedIn}">
  <mat-sidenav #cloudOverlay mode="over" position="end" class="">
    <app-cloud-security-overlay></app-cloud-security-overlay>
  </mat-sidenav>
</mat-sidenav-container>