<div class="side-nav-header">
  <div class="myaccount-section">
    <div class="close-btn">
      <mat-icon (click)="closeFilter()">cancel</mat-icon>
    </div>
    <h3 class="spark-sec-tittle" *ngIf="jirainteragationclicked"><mat-icon
        (click)="backToAccount()">keyboard_backspace</mat-icon> Jira Integration</h3>
    <h3 class="spark-sec-tittle" *ngIf="!jirainteragationclicked">Account</h3>
    <div  [class.hidden]="hideTabs" class="myaccount-content">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="account-tabgroup" (selectedTabChange)="checkTabChange()">
        <mat-tab label="Profile">
         <!-- <app-profile></app-profile> -->
         <div #tabContainer></div>
        </mat-tab>
        <ng-container *ngIf="showOrganization">
        <mat-tab label="Organization">
            <!-- <div class="users_header"> </div> -->
    <div class="profile_page scroll">
      <div class="profile_info">
              <div class="personal_details">
                  <form [formGroup]="orgForm">
                      <div class="personal_details_header">
                        
                          <div class="profile_photo" *ngIf="imageSrc?.length===0">
                              <mat-icon aria-hidden="false" aria-label="Example person icon" fontIcon="person"></mat-icon>
                              
                              <span class="edit-pan-icon" (click)="openFilePicker()"></span>    
                              <input type="file" class="hidden-file-input" #fileInput
                                  (change)="handleImageInput(fileInput?.files)" accept=".jpg,.jpeg,.png">
                          </div>
                          <div class="profile_photo" *ngIf="imageSrc?.length">
                              <mat-icon [style.background-image]="'url(' + imageSrc + ')'" aria-hidden="false"></mat-icon>
                              <span class="edit-pan-icon" (click)="openFilePicker()"></span>    
                              <input type="file" class="hidden-file-input" #fileInput
                                  (change)="handleImageInput(fileInput?.files)" accept=".jpg,.jpeg,.png">
                          </div>
                          <div>
                          </div>
                          <div *ngIf="hideOrgdetail" class="profile_info ">

                              <p *ngIf="orgDetails.organization_name?.length===0" >Organization Name</p>
                              <p  *ngIf="orgDetails.organization_name?.length!=0"class="label_value">{{makeFirstLetterCapital(orgDetails.organization_name)}}</p>
                              <button class="btn edit-button" (click)="showHideDetails()">Edit</button>
                          </div>
                      </div>
                      <div *ngIf="hideOrgdetail" class="pt_4 profile_details">
                                  <div class="profiletab-item">
                                      <div class="profiletab-itemlable">Parent Name</div>
                                      <!-- <div class="profiletab-itemvalue text_ellipsis" [options]="toolTipOption" appSafariEllipsis   [tooltip]="makeFirstLetterCapital(orgDetails.parent_organization_name)">{{makeFirstLetterCapital(orgDetails.parent_organization_name)}}</div> -->
                                      <div class="profiletab-itemvalue" >
                                       
                                            <p [tooltip]="makeFirstLetterCapital(orgDetails.parent_organization_name)"class="text_ellipsis paramer-value">{{makeFirstLetterCapital(orgDetails.parent_organization_name)}}</p>
                                        
                                      </div> 
                                  </div>  
                                  <!-- <p>Address</p>
                                  <P *ngIf="hideOrgdetail" title=" ">{{orgDetails.organization_address}}</P> -->
                                  <div class="profiletab-item">
                                      <div class="profiletab-itemlable">Address</div>
                                      <!-- <p  *ngIf="hideOrgdetail" class="profiletab-itemvalue text_ellipsis" matTooltip="{{orgDetails.organization_address}}" appSafariEllipsis >{{orgDetails.organization_address}}</p> -->
                                      <p  *ngIf="hideOrgdetail" class="profiletab-itemvalue">
                                        <label>
                                            <p matTooltip="{{orgDetails.organization_address}}" class="text_ellipsis paramer-value">{{orgDetails.organization_address}}</p>
                                        </label>
                                    </p>
                                  </div>
                               
                                  
                                  <!-- <div *ngIf="!hideOrgdetail">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Organization Address</mat-label>
                                          <input matInput placeholder="Organization Address"
                                              [(ngModel)]="orgDetails.organization_address"
                                              formControlName="organization_address">
                                          <mat-error
                                              *ngFor="let validation of validation_messages.organization_address">
                                              <mat-error class="error-message"
                                                  *ngIf="orgForm.get('organization_address')?.hasError(validation.type) && (orgForm.get(' organization_address')?.dirty || orgForm.get('organization_address')?.touched)">
                                                  {{validation.message}}</mat-error>
                                          </mat-error>
                                      </mat-form-field>
                          </div> -->
                          <!-- <div class="mb_3 profile_basic">
                              <p class="label_object">Location & time zone</p>
                              <P title=" " *ngIf="hideOrgdetail">1234{{orgDetails.organization_location}}</P>
                              <div *ngIf="!hideOrgdetail">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Location & time zone</mat-label>
                                      <input matInput placeholder="Location & time zone"
                                          [(ngModel)]="orgDetails.organization_location"
                                          formControlName="organization_location">
                                      <mat-error *ngFor="let validation of validation_messages.organization_location">
                                          <mat-error class="error-message"
                                              *ngIf="orgForm.get('organization_location')?.hasError(validation.type) && (orgForm.get('organization_location')?.dirty || orgForm.get('organization_location')?.touched)">
                                              {{validation.message}}</mat-error>
                                      </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>  -->
                      </div>
                      <div class="pt_4 profile_details" *ngIf="hasPermission(permissionProps.JIRA_INTEGRATION)">
                        <div class="profiletab-item">
                            <div class="profiletab-itemlable">Jira Integration</div>
                            <div class="profiletab-itemvalue">
                                <div *ngIf="!JirDetails">Not Integrated</div>
                                <button *ngIf="hasPermission(permissionProps.JIRA_CREATE_MODIFY)" (click)="jiraIntegration()" class="btn edit-jira">
                                  <img src="../../../../../assets/images/blue-pen-icon.svg" alt="">
                                </button>
                              </div>
                        </div>
                        <div *ngIf="JirDetails && hasPermission(permissionProps.JIRA_VIEW_ONLY)">
                            <div class="profiletab-item">
                                <div class="profiletab-itemlable">Org URL</div>
                                <!-- <div class="profiletab-itemvalue text_ellipsis" matTooltip="{{JirDetails.server}}" appSafariEllipsis> {{JirDetails.server}}</div> -->
                                 <div class="profiletab-itemvalue">
                                    <label>
                                        <p matTooltip="{{JirDetails.server}}" class="text_ellipsis paramer-value">{{JirDetails.server}}</p>
                                    </label>
                                    </div>
                            </div>
                            <div class="profiletab-item">
                                <div class="profiletab-itemlable">Project Short Code</div>
                                <!-- <p class="profiletab-itemvalue text_ellipsis" matTooltip="{{JirDetails.project_type_key}}" appSafariEllipsis> {{JirDetails.project_type_key}}</p> -->
                                <p class="profiletab-itemvalue" matTooltip="{{JirDetails.project_type_key}}" class="text_ellipsis paramer-value">{{JirDetails.project_type_key}}</p>
                            </div>
                            <div class="profiletab-item ">
                                <div class="profiletab-itemlable">User Email Id</div>
                                <!-- <p class="profiletab-itemvalue text_ellipsis" matTooltip="{{JirDetails.email_id}}" appSafariEllipsis> {{JirDetails.email_id}}</p> -->
                                <p class="profiletab-itemvalue" matTooltip="{{JirDetails.email_id}}" class="text_ellipsis paramer-value">{{JirDetails.email_id}}</p>
                            </div>
                        </div>

                    </div> 

                      <div *ngIf="!hideOrgdetail">
                          <mat-form-field class="example-full-width border-field no-bg" appearance="outline">
                              <mat-label>Address</mat-label>
                              <input matInput placeholder="Address" 
                                  formControlName="organization_address">
                              <mat-error
                                  *ngFor="let validation of validation_messages.organization_address">
                                  <mat-error class="error-message"
                                      *ngIf="orgForm.get('organization_address')?.hasError(validation.type) && (orgForm.get(' organization_address')?.dirty || orgForm.get('organization_address')?.touched)">
                                      {{validation.message}}</mat-error>
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div *ngIf="hideOrgdetail" class="sso-info">
                        <div class="sso-switch">
                            <span class="label_object">SSO</span>
                            <div class="tool_switch right_ele">
                                <mat-slide-toggle formControlName="sso_status" (change)="ssoToggle($event)"></mat-slide-toggle>
                            </div>
                        </div>
                        
                    <div class="mb_3 profile_basic " [ngClass]="{'disableMe': !ssoData.sso_status}">
                        <mat-form-field appearance="outline" class="border-field no-bg full-width">
                            <mat-label>Define SSO</mat-label>
                            <mat-select formControlName="sso_id" placeholder="Define SSO">
                                <mat-option *ngFor="let sso of ssoList"
                                    [value]="sso.id">{{sso.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                    </div>
                   
                  </form>
                  <!-- <div *ngIf="!hideOrgdetail" class="profile_buttons">

                      <button *ngIf="!hideOrgdetail" class="btn btn-blue"
                      >Save</button>
                  <button *ngIf="!hideOrgdetail" class="btn btn-white"
                      (click)="hideDetails()">Cancel</button>
              </div> -->

                  <div  class="profile_buttons">
                      <span *ngIf="hideOrgdetail" [ngClass]="{'disableMe': defaultSso === ssoData.sso_status}">
                          <button class="btn btn-blue" (click)="onSaveSSO()">Save</button>
                          <button class="btn btn-white" (click)="onCancelSSO()">Cancel</button>
                      </span>
                      <button *ngIf="!hideOrgdetail" class="btn btn-blue"
                          (click)="uploadOrganisationConnect(orgForm)">Submit</button>
                      <button *ngIf="!hideOrgdetail" class="btn btn-white" (click)="hideDetails()">Cancel</button>
                  </div>
              </div>
          </div>
         
      </div>
          <!-- <div #tabContainerOrganization></div> -->
        </mat-tab>
      </ng-container>
        <ng-container *ngIf="showOrganization || profileData.subscriptionCode=='free'">
        <mat-tab label="Subscription">
          <div class="myaccount-subscriptiontab">
              <div class="d-flex myaccount-subscription-info">
                <div class="subscription-infotop">
                    <div class="subscription-graybox">
                        <div class="subscription-planname">
                         <div *ngIf="profileData.subscriptionCode=='free'" > <img src="../../../../../assets/images/free-icon.svg" /> <span class="subscrpt-name free">{{profileData.subscriptionCode}}</span></div>
                          <div *ngIf="profileData.subscriptionCode=='gold'"><img  src="../../../../../assets/images/gold-icon.svg" /> <span class="subscrpt-name">{{profileData.subscriptionCode}}</span></div>
                        <div  *ngIf="profileData.subscriptionCode=='platinum'"><img  src="../../../../../assets/images/platinum-icon.svg" /> <span class="subscrpt-name">{{profileData.subscriptionCode}}</span></div>
                         <div *ngIf="profileData.subscriptionCode=='diamond'"><img src="../../../../../assets/images/diamond-icon.svg" /><span class="subscrpt-name"> {{profileData.subscriptionCode}}</span></div>


                        </div>
                        <div class="subscription-planexpair" *ngIf="profileData.subscriptionCode=='free'" >
                            <p>Expiring on {{profileData.expiryDateWithoutTime |date:'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="subscription-planinfo" *ngIf="profileData.subscriptionCode !='free'">
                         <p>Plan expires in next {{profileData.expiresIn}} Days ({{profileData.expiryDateWithoutTime |date:'dd/MM/yyyy'}})</p>
                    </div>
                    <div class="plan-details scroll">
                        <h4>Plan Details</h4>
                        <ol class="plan-infolist" *ngIf="profileData.subscriptionCode == 'free'">
                          <li>You are currently on a trial period of 14days</li>
                          <li>You can add up-to 10 unique assets and re-scan them without restriction</li> 
                        </ol>
                        <ol class="plan-infolist" *ngIf="profileData.subscriptionCode == 'gold'">
                          <li>You can add upto 50 unique assets and re-scan without restriction.</li>
                          <li>You can delete a device to reduce the count of unique assets.</li> 
                        </ol>
                        <ol class="plan-infolist" *ngIf="profileData.subscriptionCode == 'diamond'">
                          <li>You can add up-to 100 unique assets and re-scan without restriction.</li>
                          <li>You can delete a asset to reduce the count of unique assets..</li> 
                        
                        </ol>
                        <ol class="plan-infolist" *ngIf="profileData.subscriptionCode == 'platinum'">
                          <li>You can add unlimited number of assets.</li>
   
                        
                        </ol>
                    </div>
                </div>

                <div class="subscription-infobottom">
                                  <div class="d-flex subscription-bottom-btns" >
                    <button class="btn btn-outline" [ngClass]="{'download-mac':isSafari,'download':!isSafari}" [disabled]="true">Download Data</button>  
                    <button class="btn btn-tartorange-outline" [disabled]="isNewUser?.new_user && isNewUser?.device_count === 0" (click)="deleteData()" >Delete data permanently</button>
                  </div>
                  <div class="subscription-infogreybox">
                    
                    <p>View & upgrade plans and pick the one that fits you best.</p>
                    <div class="subscription-buttons">
                        <button class="btn  btn-outline" (click)="navigatePlans()"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.90478 1.52385C9.90478 2.36542 9.2225 3.0477 8.38093 3.0477C7.53936 3.0477 6.85708 2.36542 6.85708 1.52385C6.85708 0.682285 7.53936 0 8.38093 0C9.2225 0 9.90478 0.682285 9.90478 1.52385ZM15.3139 12.3808L15.8169 8.38084C15.8843 7.86107 15.68 7.34309 15.2759 7.00941C14.8868 6.68326 14.3574 6.5797 13.874 6.73518C13.5745 6.82191 13.2638 6.86299 12.9521 6.85699C12.4263 6.85699 11.2226 6.13314 9.56172 4.09131C9.26363 3.75087 8.83316 3.55561 8.38072 3.55561C7.92828 3.55561 7.49782 3.75089 7.19973 4.09131C5.53877 6.13327 4.33508 6.85699 3.80936 6.85699C3.49686 6.85801 3.18615 6.81184 2.88744 6.71987C2.41563 6.57829 1.90416 6.68123 1.52365 6.99423C1.10031 7.32243 0.88042 7.84896 0.944583 8.38085L1.44737 12.3959H1.4475C1.49406 12.7663 1.67505 13.1065 1.95591 13.3522C2.23678 13.598 2.598 13.7322 2.97122 13.7293H13.8055C14.1785 13.728 14.5379 13.59 14.8159 13.3415C15.094 13.0929 15.2712 12.7511 15.314 12.3807L15.3139 12.3808ZM1.52385 6.09537C2.36542 6.09537 3.0477 5.41308 3.0477 4.57152C3.0477 3.72995 2.36542 3.04779 1.52385 3.04779C0.682285 3.04779 0 3.72995 0 4.57152C0 5.41308 0.682285 6.09537 1.52385 6.09537ZM16.7617 4.57152C16.7617 5.41308 16.0795 6.09537 15.2379 6.09537C14.3963 6.09537 13.714 5.41308 13.714 4.57152C13.714 3.72995 14.3963 3.04779 15.2379 3.04779C16.0795 3.04779 16.7617 3.72995 16.7617 4.57152ZM2.38803 14.8571C2.52413 14.6214 2.77566 14.4762 3.04785 14.4762H13.7143C13.9865 14.4762 14.238 14.6214 14.3741 14.8571C14.5102 15.0928 14.5102 15.3834 14.3741 15.6191C14.238 15.8548 13.9865 15.9999 13.7143 15.9999H3.04785C2.77566 15.9999 2.52413 15.8548 2.38803 15.6191C2.25194 15.3834 2.25194 15.0928 2.38803 14.8571Z" fill="#3A2ADA"/>
                        </svg > 
                        <span *ngIf="profileData.subscriptionCode == 'platinum'">View Plans </span>
                        <span *ngIf="profileData.subscriptionCode != 'platinum'">Upgrade</span>
                        </button>
                    </div>

                  </div>
                </div>
              </div>
          </div>
        </mat-tab>
      </ng-container>
      </mat-tab-group>
    </div>
    <div class="myaccount-content" *ngIf="jirainteragationclicked">
        <app-jira-integration [isApiIntegrated]="isApiIntegrated" [JirDetails]="JirDetails"></app-jira-integration>
    </div>
  </div>
</div>