import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { FilterService } from '../../services/filter.service';
import { INotificationResponse } from '../../model/notification/notification';
import { CONSTANT } from '../../constants/constant-data';
import { AuthService } from '../../services/auth.service';
import { MessageServiceService } from '../../services/message-service.service';
import { IToastrOptions } from '../../model/IMessage.model';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../services/profile.service';
import { IUserDetail } from '../../model/profile/profile';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public showMore: boolean = false;
  public notificationSubscription!: Subscription; 
  public selectedTab: string = 'alerts'; 
  public notificationMessages:any = [];
  public alertMessages:any = [];
  public notificationId:any;
  public alertKey!:any;
  public notificationKey!:any;
    public alertShow:boolean = true;
  public flagAlertStatus = true;
  public flagNotificationStatus = true;
  public notificationShow!:boolean;
  public notificationPannelShow:boolean = true;
  public alertPannelShow:boolean = true;
  public isApiCallDone = false;
  public alertsKeyCallback:boolean = true;
  public notificationKeyCallback: boolean = true;
  public numberOfResults: number = 10;
  public totalAlertsPage: number = 1;
  public AlertsPage: number = 1;
  public totalNotificationPage: number = 1;
  public NotificationPage: number = 1;
  public unreadnotification = CONSTANT.UNREAD_NOTIFICATION;
  public notificationEmptyMessage = CONSTANT.NOTIFICATION_EMPTY;
  public alertsEmptyMessage = CONSTANT.ALERTS_EMPTY;
  public unreadAlerts = CONSTANT.UNREAD_ALERTS;
  public isMuted:any;
  public readAllnotification = '1';
  public readOneNotification = '0';
  public deviceManagementCodes: string[] = [ 'decommission_device', 'recommission_device', 'modify_device_location', 'delete_device'];
  public regionManagementCodes: string[] = ['add_region_data', 'delete_region_data', 'modify_region_level', 'modify_region_name'];
  public acceptedRiskCodes: string[] = ['accepted_risk'];
  public newFileUpload: string[] = ['api_connection_new_device','file_upload_new_device','ssh_connection_new_device','rescan'];
  public roleManagement: string[] = ['role_delete','role_modification']
  public userManagement: string[] = ['activate_user','deactivate_user','invite_user','modify_user_role']; 
  public myScans: string[] = ['master_file_upload']; 
  public securityInformation: string[] = [];
  alertUnreadCount:number=0
  notificationUnreadCount:number=0;
  userProfile: IUserDetail = {};
  private socket: Socket | null = null;
  showDetails = false;
  selectedNotification :any;
  selectedOption: number = 0;
  muteOptions = [
    { value: 3, label: 'Mute Notifications' },
    { value: 2, label: 'Mute Alerts' },
    { value: 1, label: 'Mute All' },
    { value: 0, label: 'None' }
  ];
  public content:any
  
  
  constructor(public notificationService: NotificationService,
    private authService: AuthService,
    private filterService: FilterService,
    private messageService:MessageServiceService,
    private profileService: ProfileService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer, 
    private router: Router,
    private el: ElementRef,
    private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.notificationSubscription = this.notificationService.notificationState.subscribe(
      (flag: boolean) => {
        this.openNotificationSidebar(flag); 
      }
    )
     this.authService.userLoggedIn.subscribe(() => {
       this.loadNotificationAlerts();
       this.notificationService.LoggedInSubject.next();
       this.profileService.fetchUserDetails(this.userProfileCallback);
       this.notificationService.getUserDetails(this.profileCallback);
      //  this.socketConnection();
     });

    //  this.authService.userLoggedOut.subscribe(() => {
    //   this.closeSocketConnection()
    //  })

    //  if(this.authService.isLoggedIn()){
    //   //this.profileService.fetchUserDetails(this.userProfileCallback);
    //   //this.notificationService.getUserDetails(this.profileCallback);
    //     // this.socketConnection();
    //   } 
       this.notificationService.bellRefresh.subscribe(() => {
        this.loadNotificationAlerts();
      });
  }

  // public closeSocketConnection() {
  //   if (this.socket) {
  //     this.socket.disconnect();
  //     this.socket = null;
  //     console.log('Socket disconnected');
  //   }
  // }

  public userProfileCallback = (results: IUserDetail) => {
    this.userProfile = results;
    this.isMuted = this.userProfile?.mute_notification
       }

  


//        public socketConnection() {
        
//         // Check if a socket connection already exists
//         if (this.socket) {
//           this.disconnectSocket();
//         }
    
//         if (this.authService.isLoggedIn()) {
//           const token = this.authService.getToken();
//           if (token) {
//             this.socket = io(environment.WebSocket + `?token=${token}&device_code=d48iif4qdPzyoS8yZ0DqPQmBNIjD2Cc3wXWSaqxO`);
            

//             this.socket.on('connect', ()=>{
//               console.log('Connected to the WebSocket server');
//             });
    
//             this.socket.on('message', (message: any)=>{
//               console.log(message)
//               this.showNotification(message);
//             });

//             this.socket.on('custom_message', (message: any)=>{
//               console.log(message);
//               this.sharedService.updateApiData(message);
//               this.sharedService.updateDataOnSocketNotify.emit(message);
//             });
    
//             this.socket.on('scan_status_event', (response: any) => {
//               this.authService.scanDetailsSubject.next(response);
//             });
//             this.socket.on('socket-data-sync-topic-dev',(response:any)=>{
//              console.log(response);
//             })
//             this.socket.on('socket-data-sync-dev-group',(response:any)=>{
//               console.log(response);
//              })
//             this.socket.on('final_myscans_status', (response: any) => {
//               this.authService.myScansSubject.next(response);
//             });
//             this.socket.on('expire_token', (response: any) => {
//             if(response.expire_token === true){
//               this.authService.logoutUser().subscribe({
//                 next: (result) => {
//                   if (result.status === 200) {
//                     this.authService.logOut(true,true,false,true);
//                     this.authService.userLoggedOutSubject.next();
//                   }
//                 },
//                 error: (error) => {
//                   console.error('Error during logout:', error);
//                   this.authService.logOut(true,true,false,true);
//                   this.authService.userLoggedOutSubject.next()
//                 }
//               });
//             }
//             })

//           }
//         }
// }
    
      // public disconnectSocket() {
      //   if (this.socket) {
      //     this.socket.disconnect();
      //     this.socket = null; 
      //   }
      // }
    
      // ngOnDestroy() {
      //   this.disconnectSocket();
      // }

// public showNotification(notificationData: any): void {
//     const snakMsg: IToastrOptions = {
//       message: notificationData.message
//   };
//   const notificationCategoryCode: string = notificationData.notification_category_code;
//   const notificationImageMap: Map<string, string> = new Map([
//       ['deviceManagementCodes', "../../assets/images/Device-Management.png"],
//       ['regionManagementCodes', "../../assets/images/Region-Management.png"],
//       ['acceptedRiskCodes', "../../assets/images/accepted-risk.png"],
//       ['newFileUpload', "../../assets/images/New-Scan.png"],
//       ['roleManagement', "../../assets/images/Role-Management.png"],
//       ['userManagement', "../../assets/images/User-Management.png"],
//       ['myScans', "../../assets/images/My-Scans.png"]
//   ]);
//   let svgImage: string = "../../assets/images/Device-Management.png";
//   for (const [codesKey, imagePath] of notificationImageMap.entries()) {
//     const codeArray: string[] = this[codesKey as keyof NotificationComponent];
//     if (codeArray.includes(notificationCategoryCode)) {
//         svgImage = imagePath;
//         break; 
//     }
// }
//   this.messageService.showCustomToast(snakMsg, svgImage);

//   if (notificationData.category_type === this.alertKey && !notificationData.read_status) {
//     this.notificationService.alert_count += 1;
//     // this.alertUnreadCount += 1;
//   }
//   if (notificationData.category_type === this.notificationKey && !notificationData.read_status) {
//     this.notificationService.notification_count += 1;
//     // this.notificationUnreadCount += 1;
//   }

// }

  public onAlertScroll() {
    if (this.isApiCallDone && (this.AlertsPage !== this.totalAlertsPage)) {
      this.AlertsPage++;
      this.alertKeyStatus(this.flagAlertStatus);
    }
  }

  

  public onNotificationScroll() {
    if (this.isApiCallDone && (this.NotificationPage !== this.totalNotificationPage)) {
      this.NotificationPage++;
      this.notificationKeyStatus(this.flagNotificationStatus)
    }
  }

  onSelectMuteOption(option: number) {
    this.selectedOption = option;
    const muteValue = option;
    this.notificationService.muteNotification(this.muteNotificationCallback,muteValue)
  }

  muteNotificationCallback = (results: { status: number; message: any}) => {
    if(results.status === 200){
      const snakMsg: IToastrOptions = {
        message: results.message
      }
      this.notificationService.getUserDetails(this.profileCallback);
      this.messageService.showSuccess(snakMsg)
    }
  }
  public profileCallback = (results: any) => {
    if (results.status === 200 && results.data) {
      this.selectedOption = results.data.mute_notification;
      this.authService.setCookie('userInfo', JSON.stringify(results.data || {}));
    } else {
      this.authService.logOut();
    }
  }

  public loadNotificationAlerts(){
    this.notificationService.getNotification(this.notificationCallback);
  }
  
  public notificationCallback = (results: INotificationResponse) => {
    if(results.status === 200 && results.data){
      this.alertKey = results.data?.ALERT?.event_type_id;
     this.notificationService.alertKey = results.data?.ALERT?.event_type_id;
     this.notificationService.alert_count = this.alertKey ? results.data?.ALERT?.count : 0;
     this.notificationKey = results.data?.NOTIFICATION?.event_type_id;
     this.notificationService.notificationKey = results.data?.NOTIFICATION?.event_type_id;
     this.notificationService.notification_count = this.notificationKey ? results.data?.NOTIFICATION?.count : 0;
     this.alertUnreadCount = this.notificationService.alert_count;
     this.notificationUnreadCount =  this.notificationService.notification_count;
    } else {
      this.authService.logOut();
    }
  }

 public readNotification(read_all?: string,notification?: any){
    this.notificationId = notification?.notification_id || undefined;
    let readAll = read_all || undefined;  
    let eventId = this.alertShow ? this.alertKey : this.notificationKey
    this.notificationService.markReadNotification(this.readnotificationCallback,this.notificationId,readAll,eventId);
    if(notification?.notification_category_code){
      this.navigateBasedOnNotificationCode(notification)
    }
 }

 public readnotificationCallback = (results: { status: number; data: any; }) => {
  if(results.status === 200){
    if(this.alertShow){
    if(this.notificationId){
    const index = this.alertMessages.findIndex((alert: { notification_id: any; }) => alert.notification_id === this.notificationId);
    if (index !== -1) {
        this.alertMessages[index].read_status = true;
        this.alertMessages = [...this.alertMessages];
        this.loadNotificationAlerts();
    }   
  }
  else {
    this.alertMessages.forEach((alert: { read_status: boolean; }) => {
      alert.read_status = true;
  });
  this.alertMessages = [...this.alertMessages];
  this.loadNotificationAlerts();
  }
  } else {
    if(this.notificationId){
      const index = this.notificationMessages.findIndex((notification: { notification_id: any; }) => notification.notification_id === this.notificationId);
      if (index !== -1) {
          this.notificationMessages[index].read_status = true;
          this.notificationMessages = [...this.notificationMessages];
          this.loadNotificationAlerts();
      }   
    }
    else {
      this.notificationMessages.forEach((notification: { read_status: boolean; }) => {
        notification.read_status = true;
    });
    this.notificationMessages = [...this.notificationMessages];
    this.loadNotificationAlerts();
    }
  }
}
 }
  public alertKeyStatus(flag: boolean, alerts_status?:string){
    this.showDetails = false;
    this.selectedNotification = null;
    if(this.alertsKeyCallback){
    this.selectedTab = "alerts"
    this.flagAlertStatus = flag
    this.alertsKeyCallback = false;
    this.numberOfResults = Math.ceil((window.innerHeight - 50) / 50);
    this.isApiCallDone = false;
    if(alerts_status === "alerts"){
      this.alertShow = flag;
      this.notificationShow = !flag;
      this.alertMessages = [];
      this.AlertsPage = 1;
    }
    if(alerts_status === "resetPage"){
      this.AlertsPage = 1;
      this.alertMessages = [];
    }
    const req = {
      "page_no": this.AlertsPage,
      "content_no": this.numberOfResults,
      "notification_type_id":this.alertKey,
      "all_notifications": flag
    }
    this.notificationService.getNotificationData(this.noticationAlertsDataCallback,req)
  }
  }

  public noticationAlertsDataCallback = (results: any) => {
    if(results.status === 200 && results.data){
      this.isApiCallDone = true;
      this.alertsKeyCallback = true;
      this.totalAlertsPage = results.data.numberofpages;
      if(this.alertMessages){
        this.alertMessages = this.alertMessages.concat(results.data.notification_messages);   
      }
      else {
        this.alertMessages = results.data.notification_messages;
      }
  //     if(this.AlertsPage != 1){
  //     this.alertMessages.sort((a: { read_status: any; }, b: { read_status: any; }) => {
  //       if (!a.read_status && b.read_status) {
  //           return -1;
  //       }
  //       else if (!b.read_status && a.read_status) {
  //           return 1;
  //       }
  //       else {
  //           return 0;
  //       }
  //   });
  // }
       this.alertMessages.forEach((message:any) => {
        // message.notification_time = this.formatNotificationTime(message.notification_time);
        const formattedTime = this.formatNotificationTime(message.notification_time);
        message.notice_time = formattedTime;
        message.isValidTime = this.isValidDate(formattedTime)
        message.isMessageArrayType = this.isMessageArray(message.message)
       });
       console.log(this.alertMessages);
      const notificationCount = results.data.notification_count;
      this.notificationService.alert_count = results.data?.notification_count?.ALERT?.count;
      this.notificationService.notification_count = results.data?.notification_count?.NOTIFICATION?.count;
      this.alertUnreadCount = results.data?.notification_count?.ALERT?.count;
      this.notificationUnreadCount = results.data?.notification_count?.NOTIFICATION?.count;
      if(this.flagAlertStatus === true && this.alertMessages.length === 0){
         this.alertPannelShow = false;
      }
    }
  }

  public notificationKeyStatus(flag: boolean, notification_status?:string){ 
    this.showDetails = false;
    this.selectedNotification = null;
    if(this.notificationKeyCallback){ 
    this.selectedTab = "notifications"
    this.flagNotificationStatus = flag;
    this.numberOfResults = Math.ceil((window.innerHeight - 50) / 50);
    this.notificationKeyCallback = false;
    this.isApiCallDone = false;
    if(notification_status === "notifications"){
      this.notificationShow = flag;
      this.alertShow = !flag;
      this.notificationMessages = [];
      this.NotificationPage = 1;
    }
    if(notification_status === "resetPage"){
      this.NotificationPage = 1;
      this.notificationMessages = [];
    }
    const req = {
      "page_no": this.NotificationPage,
      "content_no": this.numberOfResults,
      "notification_type_id":this.notificationKey,
      "all_notifications": flag
    }
    this.notificationService.getNotificationData(this.noticationDataCallback,req)
  }
  }

  public noticationDataCallback = (results: any) => {
    if(results.status === 200 && results.data){
      this.isApiCallDone = true;
      this.notificationKeyCallback = true;
      this.totalNotificationPage = results.data.numberofpages;
      if(this.notificationMessages){
        this.notificationMessages = this.notificationMessages.concat(results.data.notification_messages);
      }
      else {
        this.notificationMessages = results.data.notification_messages;
      }
  //     if(this.NotificationPage != 1){
  //     this.notificationMessages.sort((a: { read_status: any; }, b: { read_status: any; }) => {
  //       if (!a.read_status && b.read_status) {
  //           return -1;
  //       }
  //       else if (!b.read_status && a.read_status) {
  //           return 1;
  //       }
  //       else {
  //           return 0;
  //       }
  //   });
  // }

 this.notificationMessages.forEach((message:any) => {
  const formattedTime = this.formatNotificationTime(message.notification_time);
  message.notice_time = formattedTime;
  message.isValidTime = this.isValidDate(formattedTime)
  message.isMessageArrayType = this.isMessageArray(message.message)
 });
    //  this.notificationMessages.forEach((message:any) => {
    //   // message.notification_time = this.formatNotificationTime(message.notification_time);
    //   const formattedTime = this.formatNotificationTime(message.notification_time);
    //   message.notice_time = formattedTime;
    //   message.isValidTime = this.isValidDate(formattedTime)
    //  });
     this.alertUnreadCount = results.data?.notification_count?.ALERT?.count;
     this.notificationUnreadCount = results.data?.notification_count?.NOTIFICATION?.count;
      if(this.flagNotificationStatus === true && this.notificationMessages.length === 0){
        this.notificationPannelShow = false;
      }
    }
  }

  isMessageArray(message: any): boolean {
    return Array.isArray(message);
  }

  // isRemoveString(message:any){
  //   const cleanedMessage = message.replace(/^"|"$/g, '');
  //   return cleanedMessage
  // }

  handleClick(notification:any){
    this.notificationId = notification?.notification_id || undefined;  
    let eventId = this.alertShow ? this.alertKey : this.notificationKey
    if(!notification.read_status){
    this.notificationService.markReadNotification(this.readnotificationCallback,this.notificationId,'0',eventId);
  }
    if(notification && notification.sub_notification && notification.sub_notification?.length > 0){
      this.selectedNotification = notification;
      this.showDetails = true;
      }
  }

  navigateBasedOnNotificationCode(notificationCode: any) {
    if (this.deviceManagementCodes.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/on-premises/asset-management']);
      console.log('device code')
      this.filterService.closeSidenav();
    } else if (this.regionManagementCodes.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/organization/region-management']);
      console.log('region code')
      this.filterService.closeSidenav();
    } else if (this.acceptedRiskCodes.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/on-premises/acceptedrisk']);
      console.log('accepted code')
      this.filterService.closeSidenav();
    } else if (this.newFileUpload.includes(notificationCode?.notification_category_code)) {
      // this.router.navigate(['/premises/new-scan']);
      this.router.navigate(['on-premises/scan-details', notificationCode?.reference.scan_id, 'status',3]);
      console.log('file code')
      this.filterService.closeSidenav();
    } else if (this.roleManagement.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/organization/role-management']);
      console.log('role code')
      this.filterService.closeSidenav();
    } else if (this.userManagement.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/organization/user-management']);
      console.log('user code')
      this.filterService.closeSidenav();
    } else if (this.myScans.includes(notificationCode?.notification_category_code)) {
      this.router.navigate(['/on-premises/my-scans']);
      console.log('myscans code')
      this.filterService.closeSidenav();
    } else {
      console.log('Notification code not found');
    }
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // returns true if valid
  }

  formatNotificationTime(time: string): string {
    const notificationTime = new Date(time);
    const currentTime = new Date();
    if (isNaN(notificationTime.getTime())) {
        return "Invalid Date";
    }
    const diffMs = currentTime.getTime() - notificationTime.getTime();
    const diffSeconds = Math.round(diffMs / 1000);
    const diffMinutes = Math.round(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    return diffDays > 0
        ? diffDays === 1
            ? time
            : time
        : diffHours > 0
            ? diffHours === 1
                ? '1h ago'
                : `${diffHours}h ago`
            : diffMinutes > 0
                ? diffMinutes === 1
                    ? '1m ago'
                    : diffMinutes < 60
                        ? `${diffMinutes}m ago`
                        : '1h ago'
                : 'Now';
}




  public openNotificationSidebar(flag: boolean) {
    this.filterService.toggleSidenav('globalNotification');
    if(this.alertMessages){
      this.alertMessages = [];
      this.AlertsPage = 1; 
  } 
  this.notificationShow = false;
  this.alertShow = true;
  this.alertKeyStatus(true);
  }

  public closeNotification() {
    this.filterService.closeSidenav();
  }

  isInCategory(code: string, categoryCodes: string[]): boolean {
    return categoryCodes.includes(code);
  }
  sanitizeHTML(htmlContent: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
 isHTML(message: string): boolean {
    const doc = new DOMParser().parseFromString(message, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); // Check for HTML tags
 }
  public viewmore(content: any) {
    content.isMore = !content.isMore
  }
  public showLimitedContent(content: string, isMore: boolean = false) {
    let text = content;
    if(content && content.length > 80 && !isMore) {
      text = content.substring(0, 80) + '....';
    }
    return text;
  }
 public getCombinedCodes(): string[] {
    return [...this.newFileUpload,...this.myScans,...this.userManagement,...this.roleManagement,...this.regionManagementCodes,...this.deviceManagementCodes,...this.securityInformation,...this.acceptedRiskCodes];
  }

 public handleButtonClick(isAll: boolean): void {
 if(this.alertShow){
  this.alertKeyStatus(isAll, 'resetPage');
  }
  else{
    this.notificationKeyStatus(isAll, 'resetPage');
  }
  }
  
  backToList() {
    this.showDetails = false;
    this.selectedNotification = null;
  }

}
