import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { CloudSecurityService } from '../../../Services/cloud-security.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-edit-cloud-scan',
  templateUrl: './edit-cloud-scan.component.html',
  styleUrls: ['./edit-cloud-scan.component.scss']
})
export class EditCloudScanComponent {
  agentEditForm: FormGroup;
  public editSubscription!: Subscription;
  public agent_id = ''

  constructor(
    public filterService: FilterService,
    public spinnerService: SpinnerService,
    public router: Router,
    public cloudSecurityService: CloudSecurityService) {
      this.agentEditForm = new FormGroup({
        cloudname: new FormControl('', [
          Validators.required,
          Validators.maxLength(20)
        ]),
        description: new FormControl('', [
          Validators.required,
          Validators.maxLength(50)
        ])
      });
    this.editSubscription = this.cloudSecurityService.editDataState.subscribe(
      (data: any) => {
        this.agentEditForm.controls['cloudname'].setValue(data.name);
        this.agentEditForm.controls['description'].setValue(data.description);
       this.agent_id = data.id
      }
    )
  }
  closeNotification() {
    this.filterService.closeSidenav();
  }
  onSubmit() {
    if (this.agentEditForm.valid) {
      let edit_value = this.agentEditForm.value
      let payload = { 
        name: edit_value.cloudname,
        description: edit_value.description
       };
      console.log(this.agentEditForm.value);
      this.cloudSecurityService.agentEdit(this.editAgentCallBack,this.agent_id, payload)
    }
  }
  editAgentCallBack = (response: any) => {
    if (response.status == 200 && response.data) {
      this.filterService.closeSidenav();
      this.cloudSecurityService.createData.next({
        data: response,
        action: 'edit'
      });
      //this.router.navigate(['dashboard/cyber-security']);
    }
  }

}
