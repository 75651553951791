import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-compliant-assets',
  templateUrl: './compliant-assets.component.html',
  styleUrls: ['./compliant-assets.component.scss']
})
export class CompliantAssetsComponent {
  public params!: ICellRendererParams;
  iconMap: { [key: string]: string } = {
    'Pass': '../../../../../../assets/images/Pass.svg',
    'Fail': '../../../../../../assets/images/Fail.svg',
    'Not Applicable': '../../../../../../assets/images/NotApplicable.svg',
    'Pending Review': '../../../../../../assets/images/PendingReview.svg',
    'Manually Fail': '../../../../../../assets/images/ManuvallyFail.svg',
    'Manually Pass': '../../../../../../assets/images/ManuvallyPass.svg',
  };
  defaultIcon = 'assets/icons/default.svg';
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

}
