export class IUpdateOverLayPerameter {
    public id: string = '';
    public device_id: string = ''
    public vendor: string = '';
    public device_type: string = ''
    public device_name: string = '';
    public region_name: string = ''
    constructor(item: any, type: string) {
        this.id = "";
        this.device_id = "";
        this.vendor = "";
        this.device_type = "";
        this.device_name = "";
        this.region_name = "";
        if(type){
            if(type==='cls') {
                this.id = 'bad24677-0755-43d4-98d4-fba6c6abb0cd'; //item?.account_id
                this.device_id = item?.account_id;
                this.device_name = item?.account_id;
                this.vendor = item?.resource_name;
                this.region_name = item?.region;
                this.device_type = item?.resources_type;
            }
        }
    }
}