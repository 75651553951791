import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { GlobalSearchService } from './global-search.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';
import { END_POINTS } from '../constants/endpoints';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { time } from 'echarts';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class ScheduleScanService {

  constructor(private restService: RestService,
    public   responseService: ResponseService,
    private globalSearchService: GlobalSearchService,
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private apiService: ApiService) { }

  //  public timeOptions():any {
  //  let timeOptions:any=[];
  //   for (let hour = 0; hour < 24; hour++) {
  //     for (let minute = 0; minute < 60; minute++) {
  //       const period = hour < 12 ? 'AM' : 'PM';
  //       const formattedHour = (hour % 12 || 12).toString(); // Convert hour to 12-hour format
  //       const formattedMinute = ('0' + minute).slice(-2); // Ensure two-digit format
  //       const time = `${formattedHour}:${formattedMinute} ${period}`;
  //       timeOptions.push(time);
  //     }
  //   }
  //   return timeOptions;
  //  }
  public timeOptions(formattedTime?: string): string[] {
    let timeOptions: string[] = [];
    let startHour = 0;
    let startMinute = 0;
    let startSecond = 0;
  
    // Parse the formattedTime to determine the starting point
    if (formattedTime) {
      const timeParts = formattedTime.split(':');
      startHour = parseInt(timeParts[0], 10);
      startMinute = parseInt(timeParts[1], 10);
      if (timeParts.length > 2) {
        startSecond = parseInt(timeParts[2], 10);
      }
    }
  
    // Generate time options from the specified starting point
    for (let hour = startHour; hour < 24; hour++) {
      for (let minute = (hour === startHour ? startMinute : 0); minute < 60; minute++) {
        const period = hour < 12 ? 'AM' : 'PM';
        const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedHour = ('0' + adjustedHour).slice(-2); // Ensure two-digit format for hour
        const formattedMinute = ('0' + minute).slice(-2); // Ensure two-digit format for minute
        const time = `${formattedHour}:${formattedMinute} ${period}`;
        timeOptions.push(time);
      }
    }
  
    // Append 12:00 AM at the end of the list if not included
    if (!timeOptions.includes('12:00 AM')) {
      timeOptions.push('12:00 AM');
    }
  
    return timeOptions;
  }
  
  
  

   public generateTimeOptionsFromNow(): any {
    const timeOptions: any = [];
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 5);
  
    let startHour = currentTime.getHours();
    let startMinute = currentTime.getMinutes();
  
    for (let hour = startHour; hour < 24; hour++) {
      for (let minute = (hour === startHour ? startMinute : 0); minute < 60; minute++) {
        const period = hour < 12 ? 'AM' : 'PM';
        const formattedHour = (hour % 12 || 12).toString(); // Convert hour to 12-hour format
        const formattedMinute = ('0' + minute).slice(-2); // Ensure two-digit format
        const time = `${formattedHour}:${formattedMinute} ${period}`;
        timeOptions.push(time);
  
        // Stop at 11:59 PM
        if (hour === 23 && minute === 59) {
          return timeOptions;
        }
      }
    }
  
    return timeOptions;
  }
  

  public loadScheduleList(callback: Function,page_no: number = 1, rows_count?:any,selectedFilter?:any,order_column:string="",sort_by:string= "", search: string = '') {
    const req: any = {
      rows_count: rows_count,
      page_no: page_no,
      order_column: order_column,
      sort_by: sort_by,
      // filters: {
      //   assigned_region: selectedFilter && selectedFilter.assigned_region.length ? selectedFilter.assigned_region[0]: [],
      //   total_last_scan: selectedFilter && selectedFilter.total_last_scan.length ? selectedFilter.total_last_scan: []
      //   }
    };
    if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm && !this.globalSearchService.type) {
      req.search =this.globalSearchService.searchTerm
    }
    
    if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm && this.globalSearchService.type){
      req.search =this.globalSearchService.searchTerm,
      req.type = this.globalSearchService.type
    }
    if(search) {
      req.search = search;
    }
   const subs = this.restService.postApi(END_POINTS.SCHEDULE_LIST, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   this.apiService.apiSubscriber.push(subs); 
  }

  public loadAllList(callback: Function){
    return this.loadList(callback,null,null)
}

  public loadList(callback: Function,page_number:any=1,content_number:any=10){
    let url = END_POINTS.SCHEDULE_LIST;
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    if(page_number && content_number){
      url += `?page_number=${page_number}&content_number=${content_number}&timezone=${timezone}`;
    }
    if(time){
      url+=`?timezone=${timezone}`
    }
    // this.restService.getApi(url).subscribe({
    //   next: (response) => {
    //     callback(this.responseService.successResponse(response));
    //   },
    //   error: (err) => {
    //     callback(this.responseService.errorResponse(err));
    //   },
    // })
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.GET,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    //apiDetails.req = req;
    apiDetails.url = url
    this.apiService.callAPI(apiDetails);
    
  }

  public scheduleInfoMethod(callback: Function,scan_uuid:any){
    let url = END_POINTS.SCHEDULE_INFO;
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    if(scan_uuid){
      url += `?schedule_scan_id=${scan_uuid}&timezone=${timezone}`;
    }
    this.restService.getApi(url).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    
  }

  public validDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) return null; // if the value is empty, no error
      const isValidDate = !isNaN(Date.parse(value));
      return isValidDate ? null : { invalidDate: { value: control.value } };
    };
  }

  public loadOrgDeviceList(callback: Function,page_number:any=1,content_number:any=10,
    scheduled_flag:boolean=false,scan_id:any=null){
    let url = END_POINTS.SCHEDULE_ORG_DEVIC_LIST;
    if(page_number && content_number){
      url += `?page_number=${page_number}&content_number=${content_number}&`;
    }

    if(scan_id) {
      url +=`?scheduled_flag=${scheduled_flag}&schedule_scan_id=${scan_id}`
    } else {
      url +=`?scheduled_flag=${scheduled_flag}`
    }
   
    
   const subs = this.restService.getApi(url).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   this.apiService.apiSubscriber.push(subs); 
  }

  

  public loadScanAssets(callback: Function,asset_uuid:any,element=null){
    let url = END_POINTS.SCHEDULE_ASSET_POPUP;
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    if(asset_uuid){
      url += `?asset_uuid=${asset_uuid}&timezone=${timezone}`;
    }
    this.restService.getApi(url).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response),element);
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    
  }

  public loadScanDeviceList(callback: Function,page_number:number=1,content_number=10){
    let url = END_POINTS.SCHEDULE_LIST;
    if(page_number && content_number){
      url += `?page_number=${page_number}&content_number=${content_number}`;
    }
    const subs = this.restService.getApi(url).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }

  cancelSchedule(callback: Function,req:any) {
    this.restService.postApi(END_POINTS.CANCEL_SCHEDULE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })

  }

  saveSchduleDetails(selectedRow: any = null, callback: Function, scan_id: any = null, schedule_scan_id: any = null) {
    if (scan_id) {
      selectedRow.scan_id = scan_id;
    }
    if (schedule_scan_id) {
      selectedRow.schedule_scan_id = schedule_scan_id;
    }
    if(selectedRow.scan_name && selectedRow.scan_name.trim().length==0){
      selectedRow.scan_name=null
    }
    
  
    if (selectedRow.time) {
      let utcDate;
      if (selectedRow.date) {
        // Parse provided date and time
        const [day, month, year] = selectedRow.date.split('-').map(Number);
        const [hours, minutes] = selectedRow.time.split(':').map(Number);
        const localDate = new Date(year, month - 1, day, hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
        //utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
      } else {
        // Use current date if date is not provided
        const now = new Date();
        const [hours, minutes] = selectedRow.time.split(':').map(Number);
        const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
      }
  
      // Format date as "DD-MM-YYYY"
      const utcDay = String(utcDate.getDate()).padStart(2, '0');
      const utcMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
      const utcYear = utcDate.getFullYear();
      const formattedDate = `${utcDay}-${utcMonth}-${utcYear}`;
  
      // Format time as "HH:MM"
      const utcHours = String(utcDate.getHours()).padStart(2, '0');
      const utcMinutes = String(utcDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${utcHours}:${utcMinutes}`;
  
      selectedRow.date = formattedDate;
      selectedRow.time = formattedTime;
    }
  
    this.restService.postApi(END_POINTS.SCAN_SCHEDULE, selectedRow).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }
  
  
  

  createRequestObject(req:any): any {
    
   

    const [timePart, ampm] = req.time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (ampm === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    console.log(formattedTime);
    req.time=formattedTime;
   

    return req;
  }
}
