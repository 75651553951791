<header class="header border-bottom">
  
  <mat-toolbar class="header-tool">
    <!-- search -->
    <div class="toggle-menu">
      <button mat-raised-button (click)="openSideNav()"><mat-icon class="menu-icon">menu</mat-icon></button>
    </div>
    <div class="header-sparklogo">
      <a (click)="closeSideNav()"  [routerLink]="['/dashboard']" [routerLinkActiveOptions]="{exact: true}" 
      routerLinkActive="active">
      <img    src="../../assets/images/spark_byVerastel.svg" />
    </a>
    </div>
    <div class="page-title">
      <span class="back-arrow"> <mat-icon *ngIf="['scanDetail', 'device','cveDashDetails','schedule'].indexOf(pageType) >= 0"
        (click)="goBack()">keyboard_backspace</mat-icon></span>
      <div class="header-dropdown">
        <span class="page-name" [ngStyle]="dropDownData && dropDownData.length === 0 ? {maxWidth:'500px'}:{}"
          *ngIf="dropDownData && dropDownData.length === 0; else extendMenu">{{ heading }}</span>
        <ng-template #extendMenu>
          <div>
            <span class="page-name" [ngStyle]="dropDownData && dropDownData.length === 0 ? {maxWidth:'500px'}:{}"
            title="{{heading}}">{{ heading }}</span>
            <button class="" mat-button [matMenuTriggerFor]="clickMenu" #commonDropdownMenu="matMenuTrigger"
              (click)="openMenu()">
                <mat-icon *ngIf="dropDownData?.length">arrow_drop_down</mat-icon>
            </button>
          </div>
        
          <div class="breadcrumb" *ngIf="pageSubTitle">{{pageSubTitle}}</div>
        </ng-template>  
        <!-- <span class="page-sub-title" *ngIf="pageSubTitle">{{pageSubTitle}}</span> -->
        <mat-menu #clickMenu="matMenu" class="scan-list-menu mat-menu-scroll white-menu ">
          <div *ngIf="pageType==='schedule'">
            <button (click)="updateScans(data, pageType)" mat-menu-item
              *ngFor="let data of dropDownData"><span [tooltip]="data.schedule_name">{{data.schedule_name}}</span></button>
          </div>
          <div *ngIf="pageType==='scanDetail'">
            <button (click)="updateScans(data, pageType)" mat-menu-item
              *ngFor="let data of dropDownData"><span [tooltip]="data.scanname">{{data.scanname}}</span></button>
          </div>
          <div *ngIf="pageType==='device'">
            <button (click)="updateScans(data, pageType)" mat-menu-item
              *ngFor="let data of dropDownData"><span [tooltip]="data.device_name">{{data.device_name}}</span></button>
          </div>
          <div *ngIf="pageType==='cveDashDetails'">
            <button (click)="updateScans(data, pageType)" mat-menu-item
              *ngFor="let data of dropDownData">{{data.cveid}}</button>
          </div>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="pageType=== 'scanDetail' && scanHeaderDetails?.scan_datetime" class="scan-details-header">
    <app-scan-header [newScanDetails]="scanHeaderDetails"></app-scan-header>
    </div>
    <!-- <div class="progress-text">
      <p>40% - (40/100) files in progress..</p>
    </div> -->
    <div *ngIf="(['scanDetail', 'deviceManagement', 'userMgnt', 'roleMgnt', 'securityInformation', 'myscans', 'cveDash', 'cveDashDetails'].includes(pageType) && globalSearchService.isRedirected) && globalSearchService.searchTerm"
    class="search-term-wrapper">
    <!-- <p class="text_ellipsis" appSafariEllipsis [tooltip]="globalSearchService.headerText ? globalSearchService.headerText : globalSearchService.searchTerm"><span>Search /</span> {{ globalSearchService.headerText ? globalSearchService.headerText : globalSearchService.searchTerm }}</p> -->
    <p matTooltip="{{globalSearchService.headerText ? globalSearchService.headerText : globalSearchService.searchTerm}}" class="text_ellipsis paramer-value"><span>Search /</span> {{ globalSearchService.headerText ? globalSearchService.headerText : globalSearchService.searchTerm }}</p>

    <button (click)="clearSearchTerm()"><mat-icon>close</mat-icon></button>
  </div>
    <div class="right-section">
      <!-- <div *ngIf="pageType === 'userMgnt'">
        <div *ngIf="hasPermission(this.permissionProps.ADD_USER)">
        <button class="btn btn-outline v-top" (click)="onUserManagementHandler('Add_User')">Add User</button>
      </div>
      </div> -->
      <!-- <div class="device-icon" *ngIf="pageType === 'deviceManagement'">
        <div *ngIf="hasPermission(this.permissionProps.DELETE_DEVICE)&& pageProps?.selection?.length > 0">
          <button class="btn btn-plain red-text p0"
            (click)="onDeviceManagementHandler('delete')"><mat-icon>delete</mat-icon></button>
        </div>
        <div *ngIf="hasPermission(this.permissionProps.MODIFIY_DEVICE_LOCATION) && pageProps?.selection?.length > 0">
          <button class="map-icon btn btn-plain gray-text p0" 
            (click)="onDeviceManagementHandler('mapping')"><mat-icon>room</mat-icon></button>
        </div>  
      </div> -->
      <!-- <div class="sync-btn">
        <button class="plain-btn icon-btn" ><mat-icon>sync</mat-icon></button> 
      </div> -->
      <!-- <div class="theme-dropdown">
        <button class="icon-btn" mat-button [matMenuTriggerFor]="themeMenu">
          <span class="material-icons">grid_on</span>
        </button>
        <mat-menu #themeMenu="matMenu" class="account_dropdown">
          <ul>
            <li mat-menu-item *ngFor="let theme of themeList" (click)="selectTheme(theme)">{{theme.value}}</li>
          </ul> 
        </mat-menu>
      </div> -->
       <div class="notification icon-btn">
        <button (click)="openNotification()" mat-button class="btn icon-btn notifi-btn">
          <mat-icon >notifications</mat-icon>
          <mat-badge *ngIf="notificationService.alert_count" [matBadge]="notificationService.alert_count" matBadgePosition="above after" matBadgeSize="small" matBadgeColor="warn" class="custom-badge"></mat-badge>
            <mat-badge *ngIf="notificationService.notification_count" [matBadge]="notificationService.notification_count" matBadgePosition="below after" matBadgeSize="small" matBadgeColor="primary" class="custom-badge"></mat-badge>
        </button>
        </div>
        <!-- search button -->
      <div class="search-btn">
        <button [disabled]="disableFilter || disableSearch" class="plain-btn icon-btn" (click)="openGlobalSearch()"
          [ngClass]="{'applied': globalSearchService.isRedirected && globalSearchService.searchTerm}"><mat-icon class="Search-align">search</mat-icon></button>
      </div>
      <div *ngIf="showFilter()" class="filter-btn">
        <button [disabled]="disableFilter" [ngClass]="{'applied': userDetails?.global_filter}" (click)="openGlobalFilter()" mat-button
          class="btn icon-btn" ><mat-icon>filter_alt</mat-icon></button>
      </div>
      <div class="profile">
        <button class="account_btn plain-btn icon-btn" mat-button [matMenuTriggerFor]="profilemenu">
          <app-profile-icon [imageSrc]="avatarImage" [userData]="profileData"></app-profile-icon>
        </button>
        <mat-menu #profilemenu="matMenu" class="account_dropdown">
          <ul>
            <li mat-menu-item><P class="mail-text">{{userData?.organization_email}} </P></li>
            <li mat-menu-item *ngIf="userData?.subscription_code =='free'||userData?.is_admin"  (click)="openAccount()">
                <div class="d-flex myaccount-li">
                    <div class="myaccount-li-left">
                      <button>Account
                      <span *ngIf="profileData.expiresIn>0" >Expires in {{profileData.expiresIn}}days</span>
                      <span *ngIf="profileData.expiresIn<0" >{{profileData.expiredOn}}</span>
                    </button>
                    </div>
                    <div class="myaccount-li-right">
                      <label class="plan-label" [ngClass]="getLabelClass(profileData.subscriptionCode)">{{profileData.subscription}}</label>
                  </div>
                  
                </div>
            </li>
           <li mat-menu-item *ngIf="userData?.subscription_code !='free'&& !userData?.is_admin" (click)="openAccount()"><button>Profile</button></li>
            <!-- <li mat-menu-item><button (click)="route.navigate(['admin/organisation']);">My Organization</button></li>
            <li mat-menu-item><button (click)="onChangePassWord()" *ngIf="!userData?.sso_enabled">Change Password</button></li> -->
            <li mat-menu-item  (click)="logout()"><button class="acnt-signout-btn">Sign Out</button></li>
          </ul> 
        </mat-menu>
      </div>
      <div *ngIf="showNewScanBtn()" class="new-scan ">
        <button mat-button class="btn btn-primary  new-scan-button" (click)="newScan()">New Scan</button>
      </div>
      <!-- <div class="new-scan ">
            <button mat-button class="btn btn-primary  new-scan-button" (click)="inviteOrg()" >Invite Organization</button>
          </div>  -->

    </div>
  </mat-toolbar>
</header>