<div class="out-layout">
    <div class="myscan_page page_content">
        <mat-form-field appearance="fill">
            <mat-label>Search Resource</mat-label>
            <input matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off"
                type="text" #searchField>
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
        </mat-form-field>
    <app-ag-grid
    #myGrid
    [agDeviceColumnDefs]="colDefs"
    [data]="dataSource?.data"
    [loader]="isApiCallDone"
    [gridClass]="'cloud-resource-table ag-grid-tabel'"
    [rowHeight]="rowHeight"
    [restrictCheckBoxSelection]="false"
    (onRowSelectedChange)="onRowSelected($event)"
    [tableKey]="'resourceTable'">
    </app-ag-grid>
</div>
</div>
