import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.scss']
})
export class BenchmarkComponent {
  public params!: ICellRendererParams;
  formattedStandards: { type: 'image' | 'text'; value: string; imageUrl?: string }[] = [];
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.dataMassage(this.params?.data?.benchmark);
  }
  public dataMassage(benchmarkArray:any){
    const keywordToImageMap: { [key: string]: string } = {
      'cis': '../../../../../../assets/images/CIS.svg',
      'nist': '../../../../../../assets/images/Nist.svg',
      'spark': '../../../../../../assets/images/Spark-logo.svg'
    };
    benchmarkArray.forEach((standard: any) => {
      const keyword = Object.keys(keywordToImageMap).find((key) => standard.toLowerCase().includes(key));
      if (keyword) {
        this.formattedStandards.push({ type: 'image', value: standard, imageUrl: keywordToImageMap[keyword] });
      } else {
        this.formattedStandards.push({ type: 'text', value: standard });
      }
    });
  }
}
