import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-dashboard-parameters',
  templateUrl: './dashboard-parameters.component.html',
  styleUrls: ['./dashboard-parameters.component.scss']
})
export class DashboardParametersComponent {
  public params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
