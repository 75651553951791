import { Component, Input, SimpleChanges } from '@angular/core';
import { ISelectedListDetailsResponse, ISelectedListInfoDetails } from '../list/list.model';
import { END_POINTS } from '../../constants/endpoints';
import { CveAssetsService } from '../../services/cve-assets.service';
import { SpinnerService } from '../../services/spinner.service';
import { FilterService } from '../../services/filter.service';
import { SecurityInformationService } from '../../services/security-information.service';
import * as _ from 'lodash';
import { ToolTipConfig } from '../../constants/constant-data';
import { CveService } from '../../services/cve.service';
import { DynamicChartData } from '../selected-list-details/selected-list-details.model';
import { Subscription } from 'rxjs';
import { WhitelistService } from '../../services/whitelist.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CyberReilienceComponent } from '../cyber-reilience/cyber-reilience.component';

@Component({
    selector: 'app-tabs-assets',
    templateUrl: './tabs-assets.component.html',
    styleUrls: ['./tabs-assets.component.scss']
  })

  export class TabsAssetsComponent{
    @Input() assetsData:any;
    public dynamicChartData: DynamicChartData[] | any= [];
    public detailsSubscribe!: Subscription;
    public hideTab: boolean = false;
    public isCveSelected: boolean = false;
    public isCveName:any;
    public cveDetails: any;
    public securityGraphTitle = 'Security Score';
    public securityApiName: any;
    public vulnerabilityGraphTitle = 'Vulnerabilities';
    public vulnerabilityApiName: any;
    public vulnerabilityCveApiName:any
    public cyberResilienceGraphTitle = 'Cyber Resilience Trend';
    public cyberResilienceGraphApiName: any;
    public cyberResilienceFilter = true; 
    public apiPayload: any;
    public deviceID:any
    public commonVulnerabilityTitle = 'Vulnerabilities List';
    public commonVulnerabilityApiName = END_POINTS.DASHBOARD_COMMONVUL;
    public commonVulnerabilityFilter = false;
    public newVulnerabilityTitle = 'Vulnerabilities List';
    public newVulnerabilityApiName = END_POINTS.NEW_GRAPH;
    public newVulnerabilityFilter = false;
    public vulRowData: boolean = true
    public vulHeading:any;
    public toolTipOption = ToolTipConfig;
    public selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
    public selectedRowApiCall = false;
    public vulRowCount:number = 0;
    public cveRowCount:number = 0;
    private isApiCallInProgress: boolean = false;
    public vulCountShow:boolean = false;
    public cveCountShow:boolean = false;
    public isDisabled:boolean = true;
  showDescription: boolean = false;
  showRectification: boolean = false;
  showRationale: boolean = false;
  showIssues: boolean = false;
  isCveTab: boolean = true;  
  @Input() selectedIndex = 0; 
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['assetsData'] && changes['assetsData'].currentValue !== changes['assetsData'].previousValue) {
            if (this.assetsData?.selectedRow?.id) {
                this.securityApiName = END_POINTS.SECURITY_SCORE + '?device_uuid=' + this.assetsData.selectedRow.id;
                this.vulnerabilityApiName = END_POINTS.DASHBOARD_VULNARABILITY + '?device_uuid=' + this.assetsData.selectedRow.id;
                this.vulnerabilityCveApiName = END_POINTS.NEW_CVE + '?device_uuid=' + this.assetsData.selectedRow.id;
                this.cyberResilienceGraphApiName = END_POINTS.CYBER_RESILIENCE_DASHBOARD;
                this.apiPayload = { device_uuid: this.assetsData.selectedRow.id };
                this.deviceID = this.assetsData.selectedRow.id;
                this.isCveTab = !this.assetsData?.isCveHide;
                if(this.assetsData?.moduleType === 'cloudResource') { // update end point details

                }
              }
        }
      }
    constructor( private cveAssetsService: CveAssetsService,
        private spinnerService: SpinnerService,
        public filterService: FilterService,
        private cveService: CveService,
        private securityInformationService: SecurityInformationService,
        private whitelistService: WhitelistService){
        this.filterService.sidenavCloseEvent.subscribe(()=>{
          if(this.detailsSubscribe) {
            this.detailsSubscribe.unsubscribe();
          }
        })
        this.detailsSubscribe = this.cveAssetsService.vulnerabilityRow.subscribe((data)=>{
          this.vulHeading = data
          console.log(data,'vulHeading');
            if(data && !this.isApiCallInProgress){ 
              this.selectedRowApiCall = false;
              this.isApiCallInProgress = true;
              this.vulRowData = false;
              this.hideTab = true;
              this.showRectification = false;
              this.showDescription = false;
              this.showIssues = false;
              this.showRationale = false;
              this.securityInformationService.fetchSelectedSecurityDetail(data.id, this.fetchSelectedSecurityDetailCallback); 
            }
            });
        // this.whitelistService.updateGraphDataObserver.subscribe((res: any)=>{
        //   if(res){
        //     this.isDisabled = false;
        //   }
        // })
    } 

    onTabChange(event: MatTabChangeEvent) {
      if (event.index === 0) { 
        this.whitelistService.updateCyberGraph.next(true);
      }
    }

    public fetchSelectedSecurityDetailCallback = (res: ISelectedListDetailsResponse) => {
        this.spinnerService.hide();
        this.selectedRowApiCall = true;
        this.isApiCallInProgress = false;
        if (res.status === 200) {
          this.selectedRowInfoDetails = _.cloneDeep(res.data?.details);
          this.getSelectedGraphDetails();
        }
      }

      goBack(selectedTab: number = 1){
        this.vulRowData = true;
        this.hideTab = false;
        this.isCveSelected = false;
      }
      selectedCveRow(event: any) {
        this.vulRowData = true;
        this.hideTab = true;
        this.isCveSelected = true;
        this.isCveName = event[0].cve_id
        this.loadCVEDetails(event[0])
      }
      public loadCVEDetails(selectedRow: any) {
        this.cveDetails = null;
        this.cveService.fetchCVEDetails(selectedRow?.cve_id, this.loadCVEDetailsCallback)
      }
      public loadCVEDetailsCallback = (response: any) => {
        if (response.status === 200 && response.data && response.data.cve_summary_details) {
          this.cveDetails = response.data.cve_summary_details;
        }
      }
      public  getSelectedGraphDetails() {
       this.dynamicChartData = [
         {value:this.selectedRowInfoDetails?.device_count?.failed_device_count, name:"Assets Failed", itemStyle:{ color: '#FB4B50'}},
         {value:this.selectedRowInfoDetails?.device_count?.passed_device_count, name:"Assets Pass",itemStyle:{ color: '#19A71F'}},
         {value:this.selectedRowInfoDetails?.device_count?.whitelisted_device_count, name:"Accepted Risk",itemStyle:{ color: '#B71E31'}}
       ]
     }
     handleCveListLength(length: number) {
      this.cveCountShow = true
      this.cveRowCount = length;
    }
    handleVulListLength(length: number) {
      this.vulCountShow = true
      this.vulRowCount = length;
    }
    toggleDescription() {
      this.showDescription = !this.showDescription;
    }
    toggleRectification() {
      this.showRectification = !this.showRectification;
    }
    toggleRationale() {
      this.showRationale = !this.showRationale;
    }
    toggleIssues() {
      this.showIssues = !this.showIssues;
    }
  }