import { Component, OnInit } from '@angular/core';
import { REGION_ROUTE, ROLES_ROUTE } from '../../constants/constant-data';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { GlobalFilterService } from '../../services/global-filter.service';
import { Subscription } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { environment } from 'src/environments/environment';
import { isPromise } from 'util/types';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  showFiller : boolean = true;
  userProfile:any;
  vpn = environment?.onPremRestriction?.vpn;
  public sideNavSubscription!: Subscription;
  subMenuToggle: any = {
    isPromise: false,
    isCloud: false,
    isOrg: false
  }
  constructor(private authService: AuthService,private profileService: ProfileService,
    private globalFilterService: GlobalFilterService,
    private filterService: FilterService) {
    
  } 
  ngOnInit(): void {
    const expandedMenu = JSON.parse(this.authService.getCookie('expandedMenu') || '{}');
    if(expandedMenu && Object.keys(expandedMenu).length) {
      for(let key in this.subMenuToggle){
        this.subMenuToggle[key] = expandedMenu[key] || false;
      }
    }
    this.sideNavSubscription = this.globalFilterService.sideNavFilterState.subscribe(
      (flag: boolean) => {
        // this.getuserProfile()
        this.openSideNav(flag);
      }
    )
   
  }
  openSideNav(flag: boolean) {
    this.filterService.toggleSidenav('sideNavFilter');
  }
  closesideNav() {
    this.showFiller = true
    this.filterService.closeSidenav();
  }
  get permissionProps () {
    return ROLES_ROUTE;
  }
  get regionRoutes(){
    return REGION_ROUTE
  }
  public hasPermission(roleName: string): boolean {
    const isRoleHasAccess = !!this.authService.isRoleAccess(roleName);
    return isRoleHasAccess;
  }
  public addClassOnActive(path:string) {
    return window.location.pathname.indexOf(path) >= 0 ? 'active' : '';
  }

  public getuserProfile() {
    this.profileService.fetchUserDetails((userDetails: any) => {
     this.userProfile = userDetails;
    });
  }
  public toggleMenu(type: string, event: any){
    event.stopPropagation();
    this.subMenuToggle[type] = !this.subMenuToggle[type]; 
    this.authService.setCookie('expandedMenu', JSON.stringify(this.subMenuToggle));
  }
}
