<div class="chart-section categorywise-section">
    
    <!-- <div *ngIf="isSpinner" class="loading-text">{{constant.LOADING}}</div> -->
       <div class="control-panel-col-header">
        <p *ngIf="title" class="box-label">{{title}}</p>   
        <div class="region-sort" *ngIf="showFilter && valueFilterList.length">
            <button mat-button
                [matMenuTriggerFor]="catFilter">{{selectedCatValue}}<mat-icon>keyboard_arrow_down</mat-icon></button>
            <mat-menu #catFilter="matMenu">
                <button (click)="valueFilter(val)" *ngFor="let val of valueFilterList"
                    mat-menu-item>{{val.value}}</button>
            </mat-menu>
        </div>
    </div>
    <div *ngIf="!isSpinner && categoryPassedData.length === 0 && categoryFailedData.length === 0" class="no-data">{{noDataMsg}}</div>
    <div class="category-loader" *ngIf="isSpinner">
        <ngx-skeleton-loader class="category-bar" count="2"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="category-bar" count="2"></ngx-skeleton-loader>
         <ngx-skeleton-loader class="category-bar" count="2"></ngx-skeleton-loader>
      </div>
    <div class="category-wise" [hidden]="isSpinner">
        <div class="cat-graph">
            <div class="bar-row" *ngFor="let item of categoryWisePercVal; let i = index;">
                <div>
                    {{item.categoryLabel}}
                </div>
                <!-- <div class="bar-section" appSafariEllipsis [tooltipClass]="'bar-tooltip'"  [options]="toolTipOption"   [tooltip]="categoryWisePassAndFailParams[item.categoryLabel]">
                    <div [ngStyle]="{width: item.value + '%', backgroundColor:  item.itemStyle.color}" class="passed-bar">{{item.actualValue}}</div>
                    <div [ngStyle]="{width: item.value1 + '%'}" class="failed-bar">{{item.value1}}</div>
                </div> -->
                <div class="bar-section" >
                    <p class="profiletab-itemvalue" matTooltip="{{'categoryWisePassAndFailParams[item.categoryLabel]'}}" class="text_ellipsis paramer-value">{{"categoryWisePassAndFailParams[item.categoryLabel]"}}</p>
                    <div [ngStyle]="{width: item.value + '%', backgroundColor:  item.itemStyle.color}" class="passed-bar">{{item.actualValue}}</div>
                    <div [ngStyle]="{width: item.value1 + '%'}" class="failed-bar">{{item.value1}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
