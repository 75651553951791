import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from 'src/app/modules/shared/utils';

@Component({
  selector: 'app-severity',
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss']
})
export class SeverityComponent {
  public params!: ICellRendererParams;
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  severityStatus(type:string){
    return Utils.addBadgeClass(type)
  }
}
