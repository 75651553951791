import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { SaveChangeDialogComponent } from 'src/app/modules/new-scan/save-change-dialog/save-change-dialog.component';
import { CONSTANT, ROLES_CONTENT_PROPS, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { ApiService } from 'src/app/modules/shared/services/api.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { CredentionalService } from 'src/app/modules/shared/services/credentional.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { EncryptService } from 'src/app/modules/shared/services/encrypt.service';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { CanComponentDeactivate, UnsaveChangeGuard } from 'src/app/modules/shared/unsave-change.guard';



@Component({
  selector: 'app-credentionals-management',
  templateUrl: './credentionals-management.component.html',
  styleUrls: ['./credentionals-management.component.scss']
})
export class CredentionalsManagementComponent implements OnInit, CanComponentDeactivate {
  tabApiLoaded: boolean = true;
  tabLoaded: boolean = true;
  autoSave: boolean = false;
  execPassword: boolean = false;
  searchRole: any;
  isEdit: boolean = false;
  credentials_List: any = [];
  credentialForm: FormGroup;
  Credential: any = {};
  credentialTypes: any = [];
  credentional_types: any = [];
  credentionslTypeIds: any = [];
  showPasswordInput = false;
  showPrivateKeyInput = false;
  showApiTokenInput = false;
  showPasswordTypeOptions = true;
  dialogRef!: MatDialogRef<SaveChangeDialogComponent> | null;
  isGlobalCredential: boolean = false;
  isLocalCredential: boolean = false;
  Roles = new FormControl();
  Users = new FormControl();
  passwordTypes: any[] = [];
  credential_type_id: any;
  credential_type_password_id: any;
  public toolTipOption = ToolTipConfig;
  public users_list: any = [];
  public org_roles: any = [];
  selectedRoles: string[] = [];
  selectedRoleIds: string[] = [];
  slectedUsers: string[] = [];
  selectedUserIds: string[] = [];
  selectedPasswordTypeVal: string = "";
  userLoggedOutSubscription!: Subscription;
  initialFormValue !: FormGroup;
  hide = true;
  exec_hide = true;
  isNewUser: boolean = false;
  // modify: boolean = true
  isCancel: boolean = true
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  // isAllUserCredentials:boolean = false;
  apiRadioButtonDisabled: boolean = false;
  sshRadioButtonDisabled: boolean = false;
  edit_global_cred: boolean = false;
  constructor(private fBuilder: FormBuilder, public credentionalService: CredentionalService, public headerService: HeaderService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private messageService: MessageServiceService,
    private encryptService: EncryptService,
    private canDeactivateGuard: UnsaveChangeGuard,
    public dialogService: DialogService,
    private dialog: MatDialog,
    private apiService: ApiService) {
    this.credentialTypes = CONSTANT.CONNECTION_TYPE;
    this.passwordTypes = CONSTANT.PASSWORD_TYPE;
    this.credentionalList();
    this.credentialForm = this.fBuilder.group({
      credential_name: [{ value: '', disabled: true }, Validators.required],
      user: [''],
      description: [{ value: '', disabled: true }, Validators.required],
      password: [''],
      exec_toogle: [''],
      auto_assign: [''],
      enable_password: [''],
      apitoken: [''],
      privateFile: [''],
      connectionType: [{ value: 'ssh', disabled: true }],
      passwordType: [{ value: 'password', disabled: true }]
    });
    this.Roles.valueChanges.subscribe(selectedValues => {
      this.selectedRoles = selectedValues;
      this.updateSelectedRoleIds();
    });
    // this.initialFormValue =_.cloneDeep(this.credentialForm);
  }

  ngOnInit() {
    this.credentionalTypes();
    this.credentialForm?.get('connectionType')?.valueChanges.subscribe(value => {
      if (value === 'ssh') {
        this.credentialForm.get('exec_toogle')?.reset();
        this.credentialForm.get('auto_assign')?.reset();
        this.hide = true
        this.showApiTokenInput = false;
        this.credentialForm.get('apitoken')?.setValidators(null);
        this.credentialForm?.get('apitoken')?.setValue("");
        this.credentialForm.get('user')?.setValidators([Validators.required]);
        this.credentialForm.get('passwordType')?.setValue("password");
        this.credentialForm.get('passwordType')?.updateValueAndValidity();
        this.credentialForm.get('apitoken')?.updateValueAndValidity();
        this.credentialForm.get('user')?.updateValueAndValidity();
      } else {
        this.credentialForm.get('exec_toogle')?.reset();
        this.credentialForm.get('auto_assign')?.reset();
        this.showApiTokenInput = true
        this.credentialForm.get('apitoken')?.setValidators([Validators.required]);
        this.credentialForm.get('user')?.setValidators(null);
        this.credentialForm.get('passwordType')?.setValue("none");
        this.credentialForm.get('apitoken')?.updateValueAndValidity();
        this.credentialForm.get('user')?.updateValueAndValidity();
      }
    })
    this.credentialForm.get('exec_toogle')?.valueChanges.subscribe(value => {
      if(this.isLocalCredential || this.isGlobalCredential || this.isEdit){
      if (value) {
        this.execPassword = true
        this.credentialForm.get('auto_assign')?.disable();
        this.credentialForm.get('enable_password')?.setValidators(Validators.required)
        this.credentialForm.get('enable_password')?.enable()
        this.credentialForm.get('enable_password')?.setValue("");
        this.credentialForm.get('enable_password')?.updateValueAndValidity();
        this.autoSave = false;
      } else {
        this.execPassword = false;
        this.credentialForm.get('auto_assign')?.enable();
        this.credentialForm.get('enable_password')?.setValidators(null)
        this.credentialForm.get('enable_password')?.disable();
        this.credentialForm.get('enable_password')?.setValue("");
        this.credentialForm.get('enable_password')?.updateValueAndValidity();
      }
    }
    });
    this.credentialForm.get('auto_assign')?.valueChanges.subscribe(value => {
      if(this.isGlobalCredential || this.isLocalCredential || this.isEdit ){
      if (value) {
        this.credentialForm.get('exec_toogle')?.disable();
        this.credentialForm.get('enable_password')?.disable();
        this.credentialForm.get('enable_password')?.setValue("");
        this.credentialForm.get('enable_password')?.updateValueAndValidity();
        this.autoSave = true;
      } else {
        this.credentialForm.get('exec_toogle')?.enable();
        this.autoSave = false;
      }
    }
    });
    this.credentialForm?.get('passwordType')?.valueChanges
      .subscribe(value => {
        this.selectedPasswordTypeVal = value;
        if (value === 'password') {
          this.showPasswordInput = true;
          this.showPrivateKeyInput = false;
          this.credentialForm?.get('password')?.setValidators(Validators.required);
          this.credentialForm?.get('privateFile')?.setValidators(null);
          this.credentialForm?.get('password')?.setValue("");
          this.credentialForm.get('password')?.setErrors(null);
        } else if (value === 'privatekey') {
          this.showPasswordInput = false;
          this.showPrivateKeyInput = true;
          this.credentialForm?.get('privateFile')?.setValidators(Validators.required);
          this.credentialForm?.get('password')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValue("");
        } else {
          this.showPasswordInput = false;
          this.showPrivateKeyInput = false;
          this.credentialForm.get('password')?.setErrors(null);
          this.credentialForm?.get('password')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValue("");
          this.credentialForm?.get('password')?.setValue("");
          this.credentialForm?.get('password')?.markAsUntouched()
        }
        this.credentialForm?.get('exec_toogle')?.updateValueAndValidity();
        this.credentialForm?.get('password')?.updateValueAndValidity();
        this.credentialForm?.get('privateFile')?.updateValueAndValidity();
      });
    this.headerService.setHeading('Credential Management', { type: 'crdMgnt' });
    this.credentionalService.loadUserList(this.loadUserListCallback, { order_column: "firstname", sort_by: "ASC", role_filter: [] })
    this.credentionalService.getRoles(this.getTabsCallback);
  }

  ngAfterViewInit(): void {
    this.userLoggedOutSubscription = this.authService.userLoggedOut.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userLoggedOutSubscription) {
      this.userLoggedOutSubscription.unsubscribe();
    }
    this.apiService.unsubscribeApiCall();
  }

  getTabsCallback = (response: any) => {
    if (response.status === 200) {
      this.org_roles = response.data
    }
  }

  // validation_messages = {
  //   'user': [
  //     { type: 'required', message: CONSTANT.CREDENTIAL.CRED_USER_NAME_MSG },
  //     { type: 'minlength', min: 1, message: CONSTANT.CREDENTIAL.CRED_USER_NAME_MIN_LENGTH },
  //     { type: 'maxlength', max: 32, message: CONSTANT.CREDENTIAL.CRED_USER_NAME_MAX_LENGTH },
  //     // { type: 'pattern', pattern:/^[a-zA-Z]+( [a-zA-Z]+)*$/, message: CONSTANT.CREDENTIAL.CRED_USER_NAME_PATTERN }
  //   ],
  //   'credential_name': [
  //     { type: 'required', message: CONSTANT.CREDENTIAL.CRED_CREDENTIAL_NAME_MSG },
  //     { type: 'minlength', min: 1, message: CONSTANT.CREDENTIAL.CRED_CREDENTIAL_NAME_MIN_LENGTH },
  //     { type: 'maxlength', max: 32, message: CONSTANT.CREDENTIAL.CRED_CREDENTIAL_NAME_MAX_LENGTH },
  //     // { type: 'pattern', pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/, message: CONSTANT.CREDENTIAL.CRED_CREDENTIAL_NAME_PATTERN }
  //   ],
  //   'description': [
  //     { type: 'required', message: CONSTANT.CREDENTIAL.CRED_DESCRIPTION_NAME_MSG },
  //     { type: 'minlength', min: 6, message: CONSTANT.CREDENTIAL.CRED_DESCRIPTION_NAME_MIN_LENGTH },
  //     { type: 'maxlength', max: 64, message: CONSTANT.CREDENTIAL.CRED_DESCRIPTION_NAME_MAX_LENGTH},
  //     // { type: 'pattern', pattern:/^[a-zA-Z]+( [a-zA-Z]+)*$/,
  //     //   message: CONSTANT.CREDENTIAL.CRED_DESCRIPTION_NAME_PATTERN
  //     // }
  //   ],
  //   'password': [
  //     { type: 'required', message: CONSTANT.AUTHENTICATION.SIGN_IN_PASSWORD_REQUIRED},
  //     // { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MAX_LENGTH },
  //     // { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MIN_LENGTH },
  //     // { type: 'pattern', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_FORMAT },
  //     // { type: 'noSpaceAllowed', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_NO_SPACE }
  //   ],
  //   'apitoken': [
  //     { type: 'required', message: CONSTANT.CREDENTIAL.CRED_API_MSG},
  //   ]
  // };
  // public onChangeEvent(e: any, length: any) {
  //   const validationRules = {
  //     user: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
  //     description: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
  //   };
  //   type ValidationField = keyof typeof validationRules;
  //   const name: ValidationField = e.target.getAttribute('formcontrolname')
  //   const maxSize = length;

  //   if (e.target.value.length > maxSize) {
  //     this.credentialForm.controls[name].setValue(e.target.value.substring(0, maxSize))
  //     this.credentialForm.controls[name].setErrors({ maxlength: true });
  //   }
  //   else if (Object.keys(validationRules).includes(name)) {
  //     const regex = validationRules[name];
  //     if (regex && regex.test(e.target.value.trim())) {
  //       this.credentialForm.controls[name].setValue(e.target.value)
  //       this.credentialForm.controls[name].setErrors(null);
  //     } else {
  //       this.credentialForm.controls[name].setValue(e.target.value.substring(0, e.target.value.length - 1));
  //       this.credentialForm.controls[name].setErrors({ pattern: true });
  //     }
  //   } else {
  //     this.credentialForm.controls[name].setValue(e.target.value);
  //   }
  //   this.credentialForm.get(name)?.markAsTouched();
  // }

  // public onBlur(e: any) {
  //   const name = e.target.getAttribute('formcontrolname')
  //   this.credentialForm.controls[name].setErrors(null);
  //   this.credentialForm.controls[name].markAsTouched();
  //   this.credentialForm.controls[name].updateValueAndValidity();
  // }

  loadUserListCallback = (response: any) => {
    if (response.status === 200) {
      this.users_list = response.data.users_list
    }
  }

  remove(Role: string): void {
    if (Role === 'Admin') {
      return;
    }
    const Roles = this.Roles.value as string[];
    const index = Roles.indexOf(Role);
    if (index >= 0) {
      Roles.splice(index, 1);
      this.Roles.setValue(Roles);
    }
  }

  removeUser(user: string): void {
    const Users = this.Users.value as string[];
    const index = Users.indexOf(user);
    if (index >= 0) {
      Users.splice(index, 1);
      this.Roles.setValue(Users);
    }
  }

  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }

  updateSelectedRoleIds(): void {
    this.selectedRoleIds = this.selectedRoles.map(roleName => {
      const role = this.org_roles.find((r: { name: string; }) => r.name === roleName);
      return role ? role.id : null;
    }).filter(id => id !== null);
  }

  public validatePrivateKeyFile(event: any) {
    if (event.target?.files.length > 0) {
      this.credentialForm.get('privateFile')?.setValidators(null);
    }
  }
  // private validateFileUpload(files: Array<File>): Boolean {
  //   const result = this.uploadService.validateFileUpload(files, this.files, true);
  //   return result.isValid;
  // }  

  editCredentional(cred_id: any) {
    this.isEdit = true;
    const passwordValue = this.credentialForm.get('password')?.value;
    const enable_password = this.credentialForm.get('enable_password')?.value;
    this.credentialForm.enable();
    if (cred_id === this.credential_type_password_id) {
      this.credentialForm.get('connectionType')?.setValue("ssh");
      this.credentialForm.get('passwordType')?.setValue("password");
      this.credentialForm.get('password')?.setValue(passwordValue);
      this.credentialForm.get('enable_password')?.setValue(enable_password);
    }
    this.initialFormValue = _.cloneDeep(this.credentialForm);
  }

  deleteCredentional(cred: any) {
    const title = 'Are you sure you want to delete the credential ' + cred.name + '?';
    this.dialogService.openDialog(
      {
        width: '367px',
        title: title,
        content: 'Please note that, you cannot undo this action.',
        class: 'free-delete-popup',
        buttons: [
          {
            label: 'Cancel',
            class: 'btn-lightblack-outline',
            callBack: () => {
            }
          },
          {
            label: 'Delete',
            class: 'btn-tartorange',
            callBack: () => {
              this.credentionalService.deleteCredential(this.deleteCredentionalListResponse, cred.id)
            }
          }
        ],
        disableClose: true
      }
    );
  }




  deleteCredentionalListResponse = (response: any) => {
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.credentionalService.credentialList((response: any) => {
        if (response.status === 200) {
          if (response.data.cred_list.length > 0) {
            this.credentials_List = response.data.cred_list
            this.isNewUser = false;
            // this.isAllUserCredentials = this.credentials_List.every((cred: { modify: boolean; }) => cred.modify === false);
            // this.credentials_List.sort((a: { modify: any; }, b: { modify: any; }) => {
            //   if (a.modify && !b.modify) return -1;
            //   if (!a.modify && b.modify) return 1;
            //   return 0;
            // });
            this.checkTabChange(this.credentials_List[0].id)
          } else {
            this.credentials_List = []
            this.isNewUser = true
            this.credentialForm.disable();
            this.isEdit = false;
            this.Credential = {}
            this.credentialForm.reset();
            this.credentialForm.get('connectionType')?.setValue("ssh");
            this.credentialForm.get('passwordType')?.setValue("none");
            this.selectedRoleIds = [];
            this.Roles.setValue([]);
            this.selectedRoles = [];
            this.selectedUserIds = [];
            this.Users.setValue([]);
            this.slectedUsers = [];
            this.isLocalCredential = false;
            this.isGlobalCredential = false;
            this.initialFormValue = _.cloneDeep(this.credentialForm)
          }
        }
      });
    }
  }

  cancelCredentional(cred_id: any) {
    this.isEdit = false;
    this.credentialForm.disable();
    this.checkTabChange(cred_id);
  }

  saveCredentional(cred_id: any) {
    this.hide = true;
    const { user, credential_name, password, privateFile, apitoken, description, passwordType, connectionType, enable_password, ...rest } = this.credentialForm.value
    let data = { user, credential_name, password, privateFile, apitoken, description, connectionType, passwordType, enable_password }
    let payload: any = {
      "cred_id": cred_id,
      "credential_type_id": this.credential_type_id,
      "cred_name": data.credential_name,
      "global_cred": this.edit_global_cred,
      "username": data.user,
      "role_id": this.selectedRoleIds,
      "user_id": this.selectedUserIds,
      "description": data.description,
      "enable_password": data.enable_password
    }
    if (this.autoSave) {
      payload.auto_assign = true;
      delete payload.enable_password;
    }
    else {
      payload.auto_assign = false;
    }
    if (this.execPassword) {
      payload = Object.assign(payload, {
        enable_password: this.encryptService.doEncryptLogin(payload.enable_password)
      })
      payload.exec_password = true
    }
    else {
      payload.exec_password = false
    }
    if (data.passwordType === "password") {
      payload.password = data.password;
      payload = Object.assign(payload, {
        password: this.encryptService.doEncryptLogin(payload.password)
      })
      const passwordType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'password');
      if (passwordType) {
        payload.credential_type_id = passwordType.id;
      }
    }
    if (data.passwordType === "privateFile") {
      payload.private_key = data.privateFile;
      const privateKeyType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'private_key');
      if (privateKeyType) {
        payload.credential_type_id = privateKeyType.id;
      }
    }
    if (data.connectionType === "api") {
      payload.encrypt=true
      payload.api_token = this.encryptService.doEncryptLogin(data.apitoken);
      const APIType = this.credentional_types[1].password_type.find((type: { code: string; }) => type.code === 'api_token');
      if (APIType) {
        payload.credential_type_id = APIType.id;
      }
    }
    this.spinnerService.show()
    payload = Object.assign(payload, {
      username: this.encryptService.doEncryptLogin(payload.username),
    })
    payload.encrypt = true;
    this.credentionalService.editCredential(this.editCredentionalCallback, payload)
  }

  editCredentionalCallback = (response: any) => {
    this.spinnerService.hide()
    if (response.status === 200) {
      this.isEdit = false;
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.credentialForm.disable();
      this.credentionalService.credentialList(this.credentionalListOfResponse);
      this.initialFormValue = _.cloneDeep(this.credentialForm);
      this.checkTabChange(response.data.id);
    }
  }

  saveAddCredentional() {
    const { user, credential_name, password, privateFile, apitoken, description, passwordType, connectionType, enable_password, ...rest } = this.credentialForm.value
    let data = { user, credential_name, password, privateFile, apitoken, description, connectionType, passwordType, enable_password }
    let payload: any = {
      "cred_name": data.credential_name,
      "global_cred": this.isGlobalCredential,
      "username": data.user,
      "role_id": this.selectedRoleIds,
      "user_id": this.selectedUserIds,
      "description": data.description,
      "enable_password": data.enable_password
    }
    if (this.autoSave) {
      payload.auto_assign = true;
      delete payload.enable_password;
    }
    else {
      payload.auto_assign = false;
    }
    if (this.execPassword) {
      payload = Object.assign(payload, {
        enable_password: this.encryptService.doEncryptLogin(payload.enable_password)
      })
      payload.exec_password = true
    }
    else {
      payload.exec_password = false
    }
    if (data.passwordType === "password") {
      payload.password = data.password;
      payload = Object.assign(payload, {
        password: this.encryptService.doEncryptLogin(payload.password)
      })
      const passwordType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'password');
      if (passwordType) {
        payload.credential_type_id = passwordType.id;
      }
    }
    if (data.passwordType === "privateFile") {
      payload.private_key = data.privateFile;
      const privateKeyType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'private_key');
      if (privateKeyType) {
        payload.credential_type_id = privateKeyType.id;
      }
    }
    if (data.connectionType === "api") {
       payload.encrypt=true
       payload.api_token = this.encryptService.doEncryptLogin(data.apitoken);
      payload.credential_type_id = this.credential_type_id
      const APIType = this.credentional_types[1].password_type.find((type: { code: string; }) => type.code === 'api_token');
      if (APIType) {
        payload.credential_type_id = APIType.id;
      }
    }
    this.spinnerService.show()
    if(data.user) {
      payload = Object.assign(payload, {
        username: this.encryptService.doEncryptLogin(payload.username),
      })
    }
    
    payload.encrypt = true;
    this.credentionalService.addCredential(this.addCredentionalCallback, payload)
  }

  addCredentionalCallback = (response: any) => {
    this.spinnerService.hide()
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.credentionalService.credentialList(this.credentionalListOfResponse);
      this.initialFormValue = _.cloneDeep(this.credentialForm);
      this.isGlobalCredential = false;
      this.isLocalCredential = false;
      this.checkTabChange(response.data.id);
    }
  }

  credentionalListOfResponse = (response: any) => {
    if (response.status === 200) {
      this.credentials_List = response.data.cred_list;
      if (this.credentials_List.length > 0) {
        this.isNewUser = false;
      }
      // this.isAllUserCredentials = this.credentials_List.every((cred: { modify: boolean; }) => cred.modify === false);
      // this.credentials_List.sort((a: { modify: any; }, b: { modify: any; }) => {
      //   if (a.modify && !b.modify) return -1;
      //   if (!a.modify && b.modify) return 1;
      //   return 0;
      // });
    }
  }

  cancelADDCredentional() {
    if (this.credentials_List.length > 0) {
      // this.isAllUserCredentials = this.credentials_List.every((cred: { modify: boolean; }) => cred.modify === false);
      // this.credentials_List.sort((a: { modify: any; }, b: { modify: any; }) => {
      //   if (a.modify && !b.modify) return -1;
      //   if (!a.modify && b.modify) return 1;
      //   return 0;
      // });
      this.isLocalCredential = false;
      this.isGlobalCredential = false;
      this.checkTabChange(this.credentials_List[0].id, false)
    }
    else {
      this.credentialForm.disable();
      this.isEdit = false;
      this.Credential = {}
      this.credentialForm.reset();
      this.credentialForm.get('connectionType')?.setValue("ssh");
      this.credentialForm.get('passwordType')?.setValue("password");
      this.selectedRoleIds = [];
      this.Roles.setValue([]);
      this.selectedRoles = [];
      this.selectedUserIds = [];
      this.Users.setValue([]);
      this.slectedUsers = [];
      this.isLocalCredential = false;
      this.isGlobalCredential = false;
      this.initialFormValue = _.cloneDeep(this.credentialForm);
    }
  }

  public checkTabChange(cred_details: any, isCancel?: any) {
    this.isCancel = (isCancel !== undefined) ? isCancel : true;
    if (this.isGlobalCredential && this.selectedRoleIds.length > 1) {
      return this.saveChangeDialog();
    }
    if ((this.isEdit || this.isLocalCredential || this.isGlobalCredential) && this.isFormModified() && this.isCancel) {
      return this.saveChangeDialog();
    }
    // if (modify !== undefined) {
    //   this.modify = modify;
    // }
    this.isEdit = false;
    this.execPassword = false;
    this.autoSave = false;
    this.credentialForm.disable();
    this.tabApiLoaded = false
    this.Credential = {}
    this.credentialForm.reset();
    this.selectedRoleIds = [];
    this.Roles.setValue([]);
    this.selectedRoles = [];
    this.selectedUserIds = [];
    this.Users.setValue([]);
    this.slectedUsers = [];
    this.isLocalCredential = false;
    this.isGlobalCredential = false;
    this.initialFormValue = _.cloneDeep(this.credentialForm);
    this.credentionalService.viewCredential(this.viewCredentionalListResponse, cred_details)
  }

  viewCredentionalListResponse = (response: any) => {
    this.tabApiLoaded = true;
    if (response.status === 200) {
      this.Credential = response.data;
      this.edit_global_cred = response.data.global_cred
      if (response.data.credential_type_id === this.credential_type_password_id) {
        this.credentialForm.get('connectionType')?.setValue("ssh");
        this.credentialForm.get('passwordType')?.setValue("password");
        this.apiRadioButtonDisabled = true;
        this.sshRadioButtonDisabled = false;
      } else {
        this.credentialForm.get('connectionType')?.setValue("api");
        this.apiRadioButtonDisabled = false;
        this.sshRadioButtonDisabled = true;
      }
      this.credentialForm.get('credential_name')?.setValue(response.data.name);
      if (response.data.username) {
        this.credentialForm.get('user')?.setValue(response.data.username);
      } else {
        this.credentialForm.get('user')?.setValue("");
      }
      if (response.data.api_token) {
        this.credentialForm.get('apitoken')?.setValue(response.data.api_token);
      } else {
        this.credentialForm.get('apitoken')?.setValue("");
      }
      if (response.data.description) {
        this.credentialForm.get('description')?.setValue(response.data.description);
      } else {
        this.credentialForm.get('description')?.setValue("");
      }
      if(response.data.exec_password){
        this.credentialForm.get('exec_toogle')?.setValue(true);
        this.execPassword = true
        this.autoSave = false;
        this.credentialForm.get('enable_password')?.setValidators(Validators.required)
        this.credentialForm.get('enable_password')?.updateValueAndValidity();
      } else {
        this.credentialForm.get('exec_toogle')?.reset();
      }
      if(response.data.auto_assign){
        this.credentialForm.get('auto_assign')?.setValue(true);
        this.execPassword = false;
        this.autoSave = true;
        this.credentialForm.get('enable_password')?.setValidators(null)
        this.credentialForm.get('enable_password')?.updateValueAndValidity();
      } else {
        this.credentialForm.get('auto_assign')?.reset();
      }
      if (response.data.role_id && response.data.global_cred) {
        const roleIdsToFind = response.data.role_id
        const matchingRoles = this.org_roles.filter((role: any) => roleIdsToFind.includes(role.id));
        matchingRoles.forEach((role: any) => {
          this.selectedRoles.push(role.name);
          this.selectedRoleIds.push(role.id);
        });
        
        if(!this.selectedRoles && !this.selectedRoleIds && response.data.global_cred){
          this.org_roles.forEach((role: { name: string; id: any; }) => {
            if (role.name === "Admin") {
              this.selectedRoles.push(role.name);
              this.selectedRoleIds.push(role.id);
            }
          });
          
        }

        this.Roles.setValue(this.selectedRoles);
      }
      this.credentialForm.disable();
      if (response.data.password) {
        this.credentialForm.get('password')?.setValue(response.data.password);
      } else {
        this.credentialForm.get('password')?.setValue("");
      }
      if(response.data.enable_password){
        this.credentialForm.get('enable_password')?.setValue(response.data.enable_password);
      } else {
        this.credentialForm.get('enable_password')?.setValue("");
      }
      this.initialFormValue = _.cloneDeep(this.credentialForm);
    }
  }

  public credentionalTypes() {
    this.credentionalService.credentialTypes(this.credentialTypeResponse);
  }

  credentialTypeResponse = (response: any) => {
    if (response.status === 200) {
      this.credentional_types = response.data;
      const passwordType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'password');
      if (passwordType) {
        this.credential_type_password_id = passwordType.id;
      }
    }
  }

  public credentionalList() {
    this.tabLoaded = false
    this.tabApiLoaded = false
    this.credentionalService.credentialList(this.credentialListResponse);
  }
  credentialListResponse = (response: any) => {
    this.tabLoaded = true;
    this.tabApiLoaded = true;
    if (response.status === 200) {
      this.credentials_List = response.data.cred_list;
      if (this.credentials_List.length > 0) {
        this.isNewUser = false;
      }
      //   this.isAllUserCredentials = this.credentials_List.every((cred: { modify: boolean; }) => cred.modify === false);
      //   this.credentials_List.sort((a: { modify: any; }, b: { modify: any; }) => {
      //     if (a.modify && !b.modify) return -1;
      //     if (!a.modify && b.modify) return 1;
      //     return 0;
      // });

      if (this.credentials_List.length > 0) {
        this.checkTabChange(this.credentials_List[0].id)
      }
      else {
        this.isNewUser = true
      }
    }
  }

  createGlobalCredential() {
    if (this.isFormModified()) {
      return this.saveChangeDialog();
    }
    if (this.isGlobalCredential && this.selectedRoleIds.length > 1) {
      return this.saveChangeDialog();
    }
    this.isEdit = true;
    this.isGlobalCredential = true;
    this.isLocalCredential = false;
    this.apiRadioButtonDisabled = false;
    this.sshRadioButtonDisabled = false;
    this.Credential = {}
    this.credentialForm.enable();
    this.credentialForm.reset();
    this.credentialForm.get('connectionType')?.setValue('ssh');
    this.credentialForm.get('passwordType')?.setValue("password");
    this.selectedRoleIds = [];
    this.Roles.setValue([]);
    this.selectedRoles = [];
    this.selectedUserIds = [];
    this.Users.setValue([]);
    this.slectedUsers = [];
    const adminRole = this.org_roles.find((role: { name: string; }) => role.name === 'Admin');
    if (adminRole) {
      this.selectedRoles.push(adminRole.name);
      this.selectedRoleIds.push(adminRole.id);
    }
    this.Roles.setValue(this.selectedRoles);
    this.isEdit = true;
    this.initialFormValue = _.cloneDeep(this.credentialForm);
  }
  createLocalCredential() {
    if (this.isFormModified()) {
      return this.saveChangeDialog();
    }
    if (this.isGlobalCredential && this.selectedRoleIds.length > 1) {
      return this.saveChangeDialog();
    }
    this.edit_global_cred = false;
    this.isLocalCredential = true;
    this.isGlobalCredential = false;
    this.apiRadioButtonDisabled = false;
    this.sshRadioButtonDisabled = false;
    this.Credential = {}
    this.credentialForm.enable();
    this.credentialForm.reset();
    this.credentialForm.get('connectionType')?.setValue('ssh');
    this.credentialForm.get('passwordType')?.setValue("password");
    //  this.selectedRoleIds = [];
    //  this.Roles.setValue([]);
    //  this.selectedRoles = [];
    //  this.selectedUserIds = [];
    //  this.Users.setValue([]);
    //  this.slectedUsers = [];
    this.isEdit = true;
    this.initialFormValue = _.cloneDeep(this.credentialForm);
  }
  saveChangeDialog(): Observable<boolean> {
    this.dialogRef = this.dialog.open(SaveChangeDialogComponent, {
      width: '360px',
      disableClose: true,
      data: {
        title: 'Cancel Form',
        content: 'This action will cancel the form changes',
        buttons: [{
          label: 'Cancel Form',
          class: 'btn btn-primary',
          callBack: () => {
            this.isEdit = false;
            this.isLocalCredential = false;
            this.isGlobalCredential = false;
            this.cancelADDCredentional();
          }

        }, {
          label: 'Go Back',
          class: 'btn btn-outline red-outline',
          callBack: () => {
          }
        }

        ],
        disableClose: true
      },
    });
    return this.dialogRef.afterClosed();
  }

  isFormModified(): boolean {
    if (this.credentialForm && this.credentialForm.value && this.initialFormValue && this.initialFormValue.value) {
      const credForm = JSON.stringify(this.credentialForm.value);
      const initialForm = JSON.stringify(this.initialFormValue.value);
      return credForm !== initialForm;
    } else {
      return false;
    }
  }


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    const hasChange = this.isFormModified();
    return hasChange ? this.saveChangeDialog() : false;
  }
}
