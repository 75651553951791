<div class="cloud-scan-container">
    <div>
        <div class="cloud-scan-header">
            <div class="header-left">
                <h3>Cloud Scan</h3>
                <p>Select the cloud service provider</p>
                <!-- <div class="header-logo">
                <img src="../../../../../../assets/images/aws_large.svg">
            </div> -->

            </div>

            <button class="close-btn" (click)="closeSideNav()" mat-button [mat-dialog-close]="true">
                <mat-icon>cancel</mat-icon></button>
        </div>
        <div class="cloud-scan-content">
            <div *ngIf="!isNext">
                <div class="btns-section">
                    <div class="white-box" *ngFor="let account of device_list" (click)="selectedCloud(account)"
                        [ngClass]="{'active': account.id === selectedtypeCloud.id}">
                        <img [src]="'../../../../../../assets/images/' + account.name.toLowerCase() + '.svg'"
                            class="normal-state">
                        <img [src]="'../../../../../../assets/images/' + account.name.toLowerCase() + '_hover.svg'"
                            class="hover-state">
                    </div>
                </div>

                <div *ngIf="selectedProvider">
                    <p class="sub-text">Select between single account or organization.</p>
                    <div class="radio-btn-secction">
                        <mat-radio-group class="report-label" [(ngModel)]="selectedAccount">
                            <mat-radio-button *ngFor="let category of selectedtypeCloud.categories"
                                [value]="category.name">
                                <p class="radio-title"> {{ category.name === 'single' ? 
                                'Single Account':'Organisation Account' }}</p>
                                <p class="radio-des">{{ category.name === 'single'
                                    ? 'Single AWS subscription'
                                    : 'Onboarding an AWS Organization (tenant root group)' }}</p>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <!-- <div class="info-fields">
            <form>
                <div class="input-box-container">
                    <mat-form-field class="full-width border-field no-bg" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input formControlName="cloudname" matInput>
                    </mat-form-field>
                </div>
                <div class="input-box-container">
                    <mat-form-field class="full-width border-field no-bg" appearance="outline">
                      <mat-label>Description</mat-label>
                      <input formControlName="description" matInput>
                    </mat-form-field>
                  </div>
                  <div class="setup-info">
                    <p class="steup-header">Setup Access (Cloud Formation)</p>
                    <div class="setu-list">
                        <ol>
                           <li>
                            Ensure "New hosts" is selected below.
                           </li>
                           <li>
                            Log in as an admin in your organization's AWS management account.
                           </li>
                           <li>
                            Click the Save and continue button on the bottom right of this page.
                           </li>
                           <li>
                            On the subsequent pop-up modal, click the Launch CloudFormation button.
                           </li>
                        </ol>
                    </div>

                  </div>
            </form>
        </div>  -->
            <div class="info-fields" *ngIf="isNext">
                <form [formGroup]="cloudForm" class="cloud-form">
                    <div class="input-box-container">
                        <mat-form-field class="full-width border-field no-bg" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input formControlName="cloudname" matInput maxlength="21"> 
                            <mat-error *ngIf="cloudForm.get('cloudname')?.hasError('maxlength')">
                                Maximum 20 characters allowed
                            </mat-error>
                            <mat-error *ngIf="cloudForm.get('cloudname')?.hasError('required')">
                                Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="input-box-container">
                        <mat-form-field class="full-width border-field no-bg" appearance="outline">
                            <mat-label>Description</mat-label>
                            <input formControlName="description" matInput maxlength="51">
                            <mat-error *ngIf="cloudForm.get('description')?.hasError('maxlength')">
                                Maximum 50 characters allowed
                            </mat-error>
                            <mat-error *ngIf="cloudForm.get('description')?.hasError('required')">
                                Description is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="setup-info">
                        <p class="setup-header">Setup Access (Cloud Formation)</p>
                        <div class="setup-list">
                            <ol>
                                <li>Ensure "New hosts" is selected below.</li>
                                <li>Log in as an admin in your organization's AWS management account.</li>
                                <li>Click the Save and continue button on the bottom right of this page.</li>
                                <li>On the subsequent pop-up modal, click the Launch CloudFormation button.</li>
                            </ol>
                        </div>
                    </div>

                    <!-- Submit button, disabled if form is invalid -->

                </form>
            </div>





            <!-- controls cerren -->

            <!-- <div class="controls-section">
            <div class="controls-top">
                <p class="">Controls</p>
                <button class="btn btn-link"> <img src="../../../../../../assets/images/dowmlod_template.svg"
                        alt="">Download template</button>
            </div>
            <div class="controls-list">
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                <div class="list-items">
                    <p class="control-name">
                        Agent Version
                    </p>
                    <p class="description">
                        Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
            </div>
            <div class="controls-conection">
                <h3>Connecting to Agent</h3>
                <div class="connect-img">
                    <img src="../../../../../../assets/images/connect_cloud.svg">
                    <img src="../../../../../../assets/images/connect-line.svg">
                    <img src="../../../../../../assets/images/connect_agent.svg">

                </div>
                <p class="bottom-text">
                    Login to your AWS account in a separate tab and click on Go To AWS below.
                </p>
            </div>
        </div> -->
        </div>
        <div class="bottom-btn" *ngIf="isNext">
            <button class="btn btn-primary full-width" mat-raised-button type="submit" (click)="onSubmit()"
                [disabled]="!cloudForm.valid">Submit</button>


        </div>
        <div class="bottom-btn" *ngIf="!isNext">
            <button [disabled]="!selectedProvider || !selectedAccount" (click)="onSlectCloud()"
                class="btn btn-primary full-width">Next</button>
            <!-- <button class="btn btn-primary full-width">Submit</button> -->
            <!-- <button class="btn btn-primary full-width"(click)="goToAws()">Go To AWS</button> -->

        </div>

    </div>