import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';
import { ResponseService } from './response.service';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SortingFilterService {
  public filter: IMainFilter | any = {};
  constructor(
    private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private apiService: ApiService) {

  }

  public resetFilter(type:string, loadCache:boolean = false):void{
    if(Object.keys(this.filter).length === 0 || !this.filter[type]){
      return;
    }
    if(this.filter && this.filter[type as keyof IMainFilter]?.filters) {
      Object.keys(this.filter[type as keyof IMainFilter]?.filters).forEach((item: any) => {
        this.filter[type as keyof IMainFilter]?.filters[item as keyof ISortingFilterData].forEach((key: ISortingFilterObj) => key.activated = false)
      })
    }
    
    Object.keys(this.filter[type as keyof IMainFilter])?.forEach((item: any) => {
      this.filter[type as keyof IMainFilter]?.[item as keyof ISortingFilterData]?.sort?.forEach((key: ISortingFilterObj) => key.activated = false)
    })
    Object.keys(this.filter[type as keyof IMainFilter])?.forEach((item: any) => {
      this.filter[type as keyof IMainFilter]?.filter?.filter?.forEach((key: ISortingFilterObj) => key.activated = false)
    })
    console.log(this.filter)
    if(loadCache){
      this.setFilter(type, this.filter[type]);
    }
  }

  public setFilter(type: string, data: any) {
    this.filter[type] = data;
    this.authService.setCookie(type, JSON.stringify(data));
  }
  public getFilter(type: string, data: any) {
    return type ? this.filter[type as keyof IMainFilter] : this.filter
  }
  public securityInfoFilter(callback: Function, pageType: string = 'securityInfo') {
    const filterDetails = JSON.parse(this.authService.getCookie(pageType) || '{}');
    this.filter[pageType] = filterDetails;
    if (this.filter && this.filter[pageType] && Object.keys(this.filter[pageType]).length) {
      // this.resetFilter('securityInfo');
      callback(this.filter[pageType]);
    } else {
      const subs = this.restService.getApi(END_POINTS.SECURITY_INFO_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data.status === 200) {
            Object.keys(data.data?.filters)?.forEach((item: string) => {
              data.data?.filters[item].map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            this.setFilter(pageType, data.data);
            callback(data.data);
          } else {
            this.setFilter(pageType, []);
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          this.setFilter(pageType, []);
          callback([]);
        }
      })
      this.apiService.apiSubscriber.push(subs);
    }

  }

  public whiteListFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('whiteList') || '{}');
    this.filter['whiteList'] = filterDetails;
    if (this.filter && this.filter?.whiteList && Object.keys(this.filter?.whiteList).length) {
      // this.resetFilter('whiteList');
      callback(this.filter?.whiteList);
    } else {
      this.restService.getApi(END_POINTS.WHITELIST_MAIN_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data.status === 200) {
            Object.keys(data.data?.filters)?.forEach((item: string) => {
              data.data?.filters[item].map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            this.setFilter('whiteList', data.data);
            callback(data.data);
          } else {
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callback([]);
        }
      })
    }
  }

  public subWhiteListFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('whiteListSub') || '{}');
    this.filter['whiteListSub'] = filterDetails;
    if (this.filter && this.filter?.whiteListSub && 
      Object.keys(this.filter?.whiteListSub).length) {
      this.resetFilter('whiteListSub', true);
      callback(this.filter?.whiteListSub);
    } else {
      this.restService.getApi(END_POINTS.WHITELIST_SUB_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data?.status === 200) {
            Object.keys(data.data?.filters)?.forEach((item: string) => {
              data.data?.filters[item].map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            this.setFilter('whiteListSub', data.data);
            callback(data.data);
          } else {
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callback([]);
        }
      })
    }
  }

  public deviceManagementFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('deviceManagement') || '{}');
    this.filter['deviceManagement'] = filterDetails;
    if (this.filter && this.filter?.deviceManagement &&  
      Object.keys(this.filter?.deviceManagement).length) {
      callback(this.filter?.deviceManagement);
    } else {
      this.restService.getApi(END_POINTS.DEVICE_MANAGEMENT_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data?.status === 200) {
            Object.keys(data.data?.filters)?.forEach((item: string) => {
              data.data?.filters[item].map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            data.data.filters.total_last_scan = data.data.filters.total_last_scan.filter((item:ISortingFilterObj) => item.id !== 'date_range')
            data.data.filters.assigned_region = data.data.filters.assigned_region.filter((item:ISortingFilterObj) => item.id !== 'all')
            this.setFilter('deviceManagement', data.data);
            callback(data.data);
          } else {
            this.setFilter('deviceManagement', []);
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err);
          this.setFilter('deviceManagement', []);
          callback([]);
        }
      })
    }
  }

  public cveFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('cveFilter') || '{}');
    this.filter['cveFilter'] = filterDetails;
    if (this.filter && this.filter?.cveFilter && 
      Object.keys(this.filter?.cveFilter).length) {
     // this.resetFilter('cveFilter', true);
      callback(this.filter?.cveFilter);
    } else {
      this.restService.getApi(END_POINTS.CVE_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data?.status === 200) {
            Object.keys(data.data)?.forEach((item: string) => {
              data.data?.[item]?.sort.map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
              data.data?.[item]?.filters?.map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            this.setFilter('cveFilter', data.data);
            callback(data.data);
          } else {
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callback([]);
        }
      })
    }
  }
  public assetsCveFilter(callback: Function, cveName: string = "") {
    const filterDetails = JSON.parse(this.authService.getCookie('assetsCveFilter') || '{}');
    this.filter['assetsCveFilter'] = filterDetails;
    if (this.filter && this.filter?.assetsCveFilter && 
      Object.keys(this.filter?.assetsCveFilter).length) {
      callback(this.filter?.assetsCveFilter);
    } else {
      this.restService.postApi(END_POINTS.ASSETS_CVE_FILTER, {cve: cveName}).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if (data?.status === 200) {
            Object.keys(data.data)?.forEach((item: string) => {
              data.data?.[item]?.map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
              data.data?.[item]?.map((subItem: ISortingFilterObj) => {
                subItem.selected = false;
                subItem.activated = false;
              })
            })
            this.setFilter('assetsCveFilter', data.data);
            callback(data.data);
          } else {
            callback([]);
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callback([]);
        }
      })
    }
  }
  showFilterDropDown(data: any) {
    let showFilter = false;
    if(data && data.length > 1) {
      showFilter = true;
    }
    return showFilter;
  }
}


export interface IMainFilter {

  scanlist?:ISortingFilter;
  securityInfo: ISortingFilter;
  whiteList: ISortingFilter;
  whiteListSub: ISortingFilter;
  deviceManagement: ISortingFilter;
  accepted_risk_filter: ISortingFilter;
  scanDetail: ISortingFilter;
  cveFilter:  ISortingFilter;
  assetsCveFilter:  ISortingFilter;

}
export interface ISortingFilterObj {
  id?: string;
  name?: string;
  value?: string;
  activated?: boolean;
  selected?: boolean;
}
export interface ISortingFilterData {
  accepted_risk_sort: ISortingFilterObj[];
  benchmark: ISortingFilterObj[];
  device_failed_sort: ISortingFilterObj[];
  parameters: ISortingFilterObj[];
  severity: ISortingFilterObj[];
  vendor: ISortingFilterObj[];
  device_type: ISortingFilterObj[];
  device_failed_filter: ISortingFilterObj[];
  accepted_risk_filter: ISortingFilterObj[];
  assigned_region: ISortingFilterObj[];
  total_last_scan: ISortingFilterObj[];
  affected_devices: ISortingFilterObj[];
  cve: ISortingFilterObj[];
}
export interface ISortingFilter {
  filters: ISortingFilterData
}
export interface ISortingFilterResponse {
  status?: number;
  message?: string;
  data?: ISortingFilter;
}