import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { CONFIG } from "../constants/config";
import * as moment from 'moment';
import { MyScansService } from "./my-scans.service";
import { IUserDetail } from "../model/profile/profile";
import { ProfileService } from "./profile.service";
import { ImyScan } from "../model/scan/scan";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() public updateDataOnSocketNotify = new EventEmitter<any>();
  @Output() public closeAgSidebar = new EventEmitter<any>();
  @Output() public isThemeUpdate = new EventEmitter<any>();
  public theme: any = {
    type: 'dark-theme',
    isUpdate: false
  };
  private _data: any =  {};
  public apiResponse: any = {};
  private isDashboardLoaderAction = new Subject<boolean | string>();
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    ) { }

  onNavigateTopage(type: string, data: any){
    const obj: any = {
      'device': 'on-premises/asset-management',
      'whitelist': 'on-premises/acceptedrisk',
      'security': '/on-premises/security-information',
      'vendor': '/on-premises/security-information',
      'scan_summary': 'on-premises/scan-details'
    }
    this._data = data;
    this.router.navigate([obj[type]]);
  }

  setData(dt:any){
    this._data = dt;
  }

  getData(){
    return this._data;
  }

  clearData(){
    this._data = {};
  }

  toggleBodyClass(flag: boolean):void{
    if(flag){
      this.document.body.classList.add('header-stick');
    } else{
      this.document.body.classList.remove('header-stick');
    }
  }
  dispatchDashBoardLoaderActions(flag: boolean | string){
    this.isDashboardLoaderAction.next(flag);
  }

  getDashBoardLoaderActions(){
    return this.isDashboardLoaderAction.asObservable();
  }
  public clearAPIData(type: string){
    this.apiResponse[type] = null;
  }

  public setAPIData(type: string, response: any, isDataAvailable: boolean = false, isSearchData:boolean = false) {
    if(type!=CONFIG.PAGE_API.MY_SCANS) {
      this.apiResponse[type]={};

    } else {
      this.apiResponse[type] = {
      response: response,
      isDataAvailable: isDataAvailable,
      isSearchData: isSearchData
    };

    }
    
    
  }
  public getAPIData(type: string): any {
     return this.apiResponse[type];
  }
  public setAPIEmptyData(){
    this.apiResponse={};

  }
  public updateApiData(socketData: any) {
    if (socketData && socketData?.data?.length) {
        socketData.data.forEach((moduleObj: any) => {
            if (moduleObj && moduleObj.module && this.apiResponse && 
                this.apiResponse[moduleObj.module]) {
                
                let currentData = [];
                const response = this.apiResponse[moduleObj.module].response;

                if (response && response.data) {
                    if (Object.keys(response.data).length > 0) {
                        if (moduleObj.module === CONFIG.PAGE_API.SCAN_STATUS) {
                            currentData = response.data.scan_details || [];
                            response.data.scan_details = this.currentModuleUpdatedData(
                                CONFIG.UNIQUE_API_PROPS[moduleObj.module], 
                                moduleObj.module, 
                                currentData, 
                                socketData
                            );
                        } else if (moduleObj.module === CONFIG.PAGE_API.ASSETS_DETAILS) {
                            currentData = response.data.device_list || [];
                            response.data.device_list = this.currentModuleUpdatedData(
                                CONFIG.UNIQUE_API_PROPS[moduleObj.module], 
                                moduleObj.module, 
                                currentData, 
                                socketData
                            );
                        } else if (moduleObj.module === CONFIG.PAGE_API.MY_SCANS) {
                            currentData = response.data || [];
                            response.data = this.currentModuleUpdatedData(
                                CONFIG.UNIQUE_API_PROPS[moduleObj.module], 
                                moduleObj.module, 
                                currentData, 
                                socketData
                            );
                        }
                    }
                }
            }
        });
    }
}
public filterCompletedScan(data: any,loggedUser:any={}) {
  this.loggedUser = loggedUser;
  const inProgress = data?.filter((item: any) => item.status === 3 || item.status === 4 || item.status === 5 || item.status==6);
  inProgress.forEach(((item: any) => {
    const menu = [];
    
    if(item.hasOwnProperty('user')) {
      if((item.user === this.loggedUser.organization_email || this.loggedUser.is_admin) && item.rescan && item.status!==4){
        menu.push({label: 'Re-Scan', data: 'rescan'});
        
      }
      if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
        menu.push({label: 'Rename', data: 'rename'});
        if(item.status==3 ||item.status == 6||item.status === 4 ){
          menu.push({label: 'Scan Information', data: 'status'});
        }
        if(item.schedule_status === "scheduled" ) {
          menu.push({label: 'Change Schedule', data: 'changeSchedule'})
          menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
          
        }
        if(item.schedule_status === "schedulable"){
          menu.push({label: 'Schedule Scan', data: 'createSchedule'})
          
        }
      }
      
      item.menu = menu;
    } else {

      if((this.loggedUser.is_admin) && item.rescan){
        menu.push({label: 'Re-Scan', data: 'rescan'});
        menu.push({label: 'Change Schedule', data: 'changeSchedule'})
        menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
      }
      if( this.loggedUser.is_admin){
        menu.push({label: 'Rename', data: 'rename'});
        if(item.status) {
          menu.push({label: 'Scan Information', data: 'status'});
          menu.push({label: 'Change Schedule', data: 'changeSchedule'})
          menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
        }
      }
      item.menu = menu;
    }
  }))
  return inProgress;
}
loggedUser: IUserDetail = {};
public filterInProgressScan(data: any,loggedUser:any={}) {
  this.loggedUser = loggedUser;
  const scanCompleted = data?.filter((item: any) => item.status === 1 || item.status === 2);
  scanCompleted?.forEach(((item: any) => {
    const menu = [];
    if(item.hasOwnProperty('user')) {
      if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
        menu.push({label: 'Rename', data: 'rename'});
      }
      if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
        menu.push({label: 'Cancel', data: 'cancel'});
      }
      item.menu = menu;
    } else {
      if(this.loggedUser.is_admin){
        menu.push({label: 'Rename', data: 'rename'});
      }
      if( this.loggedUser.is_admin){
        menu.push({label: 'Cancel', data: 'cancel'});
      }
      item.menu = menu;
    }
    
  }))
  return scanCompleted;
}
getSecurityClass(rating:any){
  if(rating<50){
    return "Failed"
  }
  else if (rating >= 50 && rating <80){
    return "Cancelled"
  }
  else if (rating >= 80){
    return "Success"
  }
}
getScanStatusClass(value: number): string {
  const arr = ['', 'in-queue','in-pgr', 'low', 'critical', 'cancle', 'partial', 'in-pgr','in-pgr', 'critical', 'cancle','in-queue'];
  return arr[value];
}
  public currentMyscanwrapper(uniqueProps: string, moduleName: string, currentData: any, socketData: any,loggedUser:any={}) {
    let tempData:any=[]
    if(socketData && socketData?.data?.length) {
      socketData?.data?.forEach((moduleObj: any)=>{
        if(moduleObj && moduleObj.module === moduleName) {
           if(moduleObj.data && moduleObj.data.length) {
            
              //console.log("control reaching shared service current module myscans");
              //console.log(moduleObj.data.length +"before processing")
              const scanProgress = this.filterInProgressScan(moduleObj.data,loggedUser);
              const scanCompleted = this.filterCompletedScan(moduleObj.data,loggedUser);
              moduleObj.data = scanProgress.concat(scanCompleted);
              //console.log(moduleObj.data.length +"after processing")
              
            
            //hasNewRecord = moduleObj.data.some((item:any) => item.status === 3);
            
            moduleObj.data.forEach((socketObj: any)=>{
              const currentDataIndex = currentData?.findIndex((cDataObj: any)=>{
                return cDataObj[uniqueProps] === socketObj[uniqueProps];
              })
              if(moduleName=='myscans') {
                
                const arr = ['', 'In Queue', 'In-Progress', 'Success', 'Failed', 'Cancelled', 'Partially Success', 'Fetching Configuration', 'In-Progress', 'Failed', 'Cancelled', 'Renaming...'];
                socketObj.statusName = arr[socketObj.status];

                socketObj.securityrating_rounded = socketObj.securityrating ? Math.floor(socketObj.securityrating) : socketObj.securityrating;

                const formattedDate = socketObj.date_time ? moment(socketObj.date_time).format('D MMM, y HH:mm:ss') : '';
                socketObj.date_time = formattedDate;
                socketObj.securityRatingClass = this.getSecurityClass(socketObj.securityrating_rounded);
                socketObj.statusClass = this.getScanStatusClass(socketObj.status);
                if(currentDataIndex > -1) {

                  //currentData[currentDataIndex] = socketObj;

                  
                  
                } else {
                  socketObj.position=1;
                  
                  //currentData.unshift(socketObj);
                  //hasNewRecord=true;
                  //console.log()
                  //console.log("record not  found scan uuid"+socketObj.scan_uuid)
                  
                }
              }
              
              
            })
            
            tempData=moduleObj.data;

            
            
           }
        }
      })
    }
    //this.currentModuleUpdatedData(uniqueProps,moduleName,currentData,socketData,loggedUser)
    return tempData;

  }
  public currentModuleUpdatedData(uniqueProps: string, moduleName: string, currentData: any, socketData: any,loggedUser:any={}) {
    let hasNewRecord:boolean=false;
    let tempData:any=[];
    //console.log(currentData.length +"current data lenght at the start")
    if(socketData && socketData?.data?.length) {
      socketData?.data?.forEach((moduleObj: any)=>{
        if(moduleObj && moduleObj.module === moduleName) {
           if(moduleObj.data && moduleObj.data.length) {
            //hasNewRecord = moduleObj.data.some((item:any) => item.status === 3);
            
            moduleObj.data.forEach((socketObj: any)=>{
              const currentDataIndex = currentData?.findIndex((cDataObj: any)=>{
                return cDataObj[uniqueProps] === socketObj[uniqueProps];
              })
              if(moduleName=='scan_status') {
                if(currentDataIndex > -1 &&currentData[currentDataIndex].status  && socketObj.status) {
                  currentData[currentDataIndex].status = socketObj.status;

                }
                if(currentDataIndex > -1 && currentData[currentDataIndex].reason && socketObj.reason) {
                  currentData[currentDataIndex].reason=socketObj.reason;

                }
                if(currentDataIndex > -1 && currentData[currentDataIndex].file_name && socketObj.file_name) {
                  currentData[currentDataIndex].file_name=socketObj.file_name
                 }
                 if( currentDataIndex > -1 && currentData[currentDataIndex].asset_name && socketObj.asset_name) {
                  currentData[currentDataIndex].asset_name=socketObj.asset_name
                 }

              }
              
            })
            tempData=moduleObj.data;
            
           }
        }
      })
    }
    return tempData;
  }
  public noNewRowAdd() {
    return [CONFIG.PAGE_API.SCAN_STATUS]
  }
  public closeSideBar() {
    this.closeAgSidebar.emit(false);
  }
}
