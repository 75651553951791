<div class="ag-center">
    <div *ngFor="let item of formattedStandards.slice(0, 3)">
      <ng-container *ngIf="item.type === 'image'; else showText">
        <div class="benchmark-logos">
        <img [src]="item.imageUrl"/>
      </div>
      </ng-container>
      <ng-template #showText>
        <span class="benchmark-badge">{{ item.value }}</span>
      </ng-template>
    </div>
    <div *ngIf="formattedStandards.length > 3">
       <span class="circle">+{{formattedStandards.length-3}}</span>
    </div>
</div>
