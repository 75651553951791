import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-acceptedrisk',
  templateUrl: './acceptedrisk.component.html',
  styleUrls: ['./acceptedrisk.component.scss']
})
export class AcceptedriskComponent {
  public params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

}
