import { Injectable } from '@angular/core';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';
import { Observable, catchError, throwError } from 'rxjs';
import { Utils } from '../utils';
import { SortingFilterService } from './sorting-filter.service';
import { CONFIG } from '../constants/config';
import { ApiService, IAPIDetails } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class CveService {
  public selectedRow: any;
  public bgColor: any = {
    critical: '#A50F15',
    medium: '#FF6600',
    high: '#D91C20',
    low: '#FFC900'
  }
  public bgColorSeverity: any = {

    1: '#FFE455',
    2: '#FFC900',
    // Add more mappings if needed

    3: '#F2BF00',
    4: '#FF9642',
    5: '#FF6600',
    6: '#E85800',
    // Add more mappings if needed


    7: '#C60005',
    8: '#A7000F',
    // Add more mappings if needed


    9: '#960006',
    10: '#670004',
    // Add more mappings if needed

  }
  constructor(private restService: RestService,
    private responseService: ResponseService,
    private sortingFilterService: SortingFilterService,
    private apiService: ApiService) {

  }
  showFilterDropDown(data: any) {
    let showFilter = false;
    if (data && data.length > 0) {
      showFilter = true;
    }
    return showFilter;
  }

  public vendorSpecificVulnerability(callback: Function) {
   const subs = this.restService.getApi(END_POINTS.VENDOR_SPECIFIC_VULNERABILITY).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  public fetchAffectedDevices(callback: Function) {
   const subs = this.restService.getApi(END_POINTS.AFFECTED_DEVICE).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   this.apiService.apiSubscriber.push(subs); 
  }
  public fetchVulnerability(callback: Function) {
   const subs = this.restService.getApi(END_POINTS.CVE_VULNERABILITY).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);  
  }

  public fetchCveData(callback: any){
    const req = {
      type: 1
    };
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }

    apiDetails.req = req;
    apiDetails.url = END_POINTS.CVE_DATA;
    this.apiService.callAPI(apiDetails);
  }
  public fetchCVEDetails(cveName: string = '', callback: Function) {
    const req = {
      cve: cveName
    }
    const subs = this.restService.postApi(END_POINTS.CVE_DETAILS, req).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  public fetchAffectedAssetsList(cve: any, callback: Function) {
    const req = {
      cve: cve,
      type: 1
    }
    const subs = this.restService.postApi(END_POINTS.CVE_ASSETS_LIST, req).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  public fetchCveCommonData(callback: Function,payLoad?: any) {
    let req: any = {
      type: 1
    };

    if (payLoad) {
      req.asset_list = payLoad;
    }

    const subs = this.restService.postApi(END_POINTS.CVE_DATA, req).subscribe({
      next: (res) => {
        callback(null, this.responseService.successResponse(res));  // Pass the data
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err), null);  // Pass the error
      }
    });
    this.apiService.apiSubscriber.push(subs);
  }


}
