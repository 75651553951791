<div class="custom-filter">
    <!-- <label>
      <input
        type="checkbox"
        [checked]="filterValues.includes('Manual')"
        (change)="onCheckboxChange('Manual', $event)"
      />
      Manual
    </label>
    <label>
      <input
        type="checkbox"
        [checked]="filterValues.includes('Automated')"
        (change)="onCheckboxChange('Automated', $event)"
      />
      Automated
    </label> -->
    <mat-checkbox class="checkbox-class"
    [checked]="filterValues.includes('Manual')"
    (change)="onCheckboxChange('Manual', $event)"
  >
    Manual
  </mat-checkbox>
  <mat-checkbox
    [checked]="filterValues.includes('Automated')"
    (change)="onCheckboxChange('Automated', $event)"
  >
    Automated
  </mat-checkbox>
    <button class="btn_class" (click)="applyFilter()">Apply</button>
  </div>