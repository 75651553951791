import { Component } from '@angular/core';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { CloudSecurityService } from '../../../Services/cloud-security.service';
import { IcloudSecurity } from '../../../cloud-security-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/modules/shared/services/api.service';

@Component({
  selector: 'app-cloud-new-scan',
  templateUrl: './cloud-new-scan.component.html',
  styleUrls: ['./cloud-new-scan.component.scss']
})
export class CloudNewScanComponent {
  selectedAccount: string = "";
  selectedProvider: string = "";
  selectedAws: boolean = false;
  selectedGoogleCloud: boolean = false;
  selectedAzure: boolean = false;
  public device_list:any = [];
  cloudForm: FormGroup;
  isNext: boolean = false;
  public selectedtypeCloud!: any;
  constructor(private filterService: FilterService, public cloudSecurityService: CloudSecurityService,
    private apiService: ApiService
  ) {
    this.cloudForm = new FormGroup({
      cloudname: new FormControl('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ])
    });
  }

  ngOnInit(): void {
    this.cloudSecurityService.resetDataState.subscribe((res:any)=>{      
        if(res && this.device_list.length > 0){
          this.isNext = false;
          this.resetForm();
          this.selectedCloud(this.device_list[0]);
        }
    })
   
    this.resetForm();
    this.isNext = false;
    this.CloudList();
  }
  ngOnDestroy(): void {
    this.apiService.unsubscribeApiCall();
  }
  public resetForm(): void {
    this.cloudForm.reset({
      cloudname: '',
      description: ''
    },
      { emitEvent: false }
    );    
  }

  onSubmit() {
    if (this.cloudForm.valid) {
      const cloud_values = this.cloudForm.value
      let slectedAccountId = ''
      this.selectedtypeCloud.categories.map((item:any)=>{
        if(item.name ===  this.selectedAccount){
          slectedAccountId = item.id
        }
      })
      let payload = { 
        name: cloud_values.cloudname,
        description: cloud_values.description,
        cloud_account_type: slectedAccountId
       }
      // payload.id = this.selectedProvider;
      // payload.cloudType = this.selectedtypeCloud;
      // payload.cloud_account_type = this.selectedAccount;
      this.cloudSecurityService.createAgent(this.createAgentCallback,payload)
    }
  }

  public createAgentCallback = (res:any)=>{
  this.isNext = false;
  this.filterService.closeSidenav();
  this.resetForm();
  this.cloudSecurityService.createData.next({
    data: res,
    action: 'create'
  });
  }

  public CloudList() {
    this.cloudSecurityService.accountsList(this.cloudListCallback)
  }

  cloudListCallback = (response: any) => {
    // this.device_list = response.data.accounts || [];
    this.device_list = response.data?.accounts || [];
    this.selectedCloud(this.device_list[0]);
    // this.selectedAccount = this.device_list[0].categories[0].name;
  }

  public selectedCloud(account_type: any) {
    this.selectedProvider = account_type.id
    this.selectedtypeCloud = account_type;
    this.selectedAccount = account_type.categories[0].name;

  }
  public onSlectCloud() {
    this.isNext = true;
  }

  public selectedtypeAws() {
    this.selectedAws = !this.selectedAws

  }
  public closeSideNav() {
    this.isNext = false;
    this.filterService.closeSidenav();
  }
  public selectedtypeGoogleCloud() {
    this.selectedGoogleCloud = !this.selectedGoogleCloud
  }
  public selectedtypeAzure() {
    this.selectedAzure = !this.selectedAzure
  }
  public goToAws() {
    const url = 'https://console.aws.amazon.com/cloudformation/home?#/stacks/new?stackName=Spark-Agent&templateURL=https://spark-inventory.s3.us-east-1.amazonaws.com/cft/spark-agent.yaml';
    window.open(url, '_blank');
  }
}
