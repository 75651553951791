<div class="admin_section out-layout vpn-management-page" *ngIf="noData">
  <div class="vpn-main-section">
    <div class="vpn-list white-box p0">
      <div class="role-data-section cred-data white-box p0" *ngIf="!isVpnLoader">
        <ngx-skeleton-loader count="18">
        </ngx-skeleton-loader>
      </div>
      <div class="vpn-search-header" *ngIf="isVpnLoader">
        <button class="btn add-vpn" (click)="addVpn()"><mat-icon>add</mat-icon></button>
        <div class="search-bar">
          <mat-form-field appearance="fill">
            <mat-label>Search VPN</mat-label>
            <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text">
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      
      <div class="vpn-search-list scroll" *ngIf="isVpnLoader && vpnList?.length">
        <div class="vpn-list-item" *ngFor="let vpn of vpnList" (click)="checkTabChange(vpn)" [ngClass]="{'active': vpn.tunnel_name === tunnelName }">
          <div class="vpn-tunnelname"><span>{{ vpn.tunnel_name }}</span> <span class="vulnerab_label" [ngClass]="getScanStatusClass(vpn.status)">{{vpn.tunnel_status}}</span></div>
          <!-- <p class="vpn-description text_ellipsis" appSafariEllipsis matTooltip="{{vpn.tunnel_description}}">{{ vpn.tunnel_description }}</p> -->
          <p class="vpn-description">
            <label>
              <p matTooltip="{{vpn.tunnel_description}}" class="text_ellipsis paramer-value">{{vpn.tunnel_description}}</p>
          </label>
          </p>
        </div>
      </div>
    </div>
    <div class="vpn-data-section white-box p0" *ngIf="noData">
      <div class="role-data-section cred-data white-box p0" *ngIf="!isdetails ">
        <ngx-skeleton-loader count="18">
        </ngx-skeleton-loader>
      </div>
      <div class="vpn-data" *ngIf="isdetails && vpnList?.length">
        <div class="vpn-data-header">
          <h3>VPN Details</h3>
          <div class="vpn-btns">
            <button class="btn btn-outline" (click)="testVpn()">Test</button>
            <div class="actions-btn">
              <button [matMenuTriggerFor]="menu" class="dropdown-button">Actions <mat-icon
                  class="sort-icon">keyboard_arrow_down</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editVpn(vpndetails)">Edit</button>
                <button mat-menu-item (click)="pauseVpn(vpndetails,true)" *ngIf="!vpndetails.paused">Pause</button>
                <button mat-menu-item (click)="pauseVpn(vpndetails,false)" *ngIf="vpndetails.paused">Resume</button>
                <button mat-menu-item (click)="deleteVpn(vpndetails)">Delete</button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="vpn-data-inner" *ngIf="vpndetails">
          <div class="right-inner scroll">
            <div class="item">
              <p class="text">Tunnel Name</p>
              <p class="sub-text">{{vpndetails.tunnel_name}}</p>
            </div>
            <div class="item">
              <p class="text">Tunnel Description</p>
              <p class="sub-text">{{vpndetails.tunnel_description}}</p>
            </div>
            <div class="item">
              <p class="text">Client Gateway IP</p>
              <p class="sub-text">{{vpndetails.client_gateway_ip}}</p>
            </div>
            <div class="item">
              <p class="text">CIDR Block</p>
              <p class="sub-text">{{vpndetails.client_cidr_block}}</p>
            </div>
            <div class="item">
              <p class="text">Created By</p>
              <p class="sub-text">{{vpndetails.firstname}} {{vpndetails.lastname}}</p>
            </div>

            <div class="item">
              <p class="text">Created Date & time</p>
              <p class="sub-text">{{vpndetails.created_on | date: 'dd MMM, yyyy - HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Last Successfully Connected</p>
              <p class="sub-text">{{vpndetails.last_success_connection | date: 'dd MMM, yyyy - HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Last Failed connection</p>
              <p class="sub-text">{{vpndetails.last_failed_connection | date: 'dd MMM, yyyy - HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">ESP</p>
              <p class="sub-text">{{vpndetails.details.esp}}</p>
            </div>
            <div class="item">
              <p class="text">IKE</p>
              <p class="sub-text">{{vpndetails.details.ike}}</p>
            </div>
            <div class="item">
              <p class="text">Dpddelay</p>
              <p class="sub-text">{{vpndetails.details.dpddelay}}</p>
            </div>
            <div class="item">
              <p class="text">Lifetime</p>
              <p class="sub-text">{{vpndetails.details.lifetime}}</p>
            </div>
            <div class="item">
              <p class="text">Left Auth</p>
              <p class="sub-text">{{vpndetails.details.leftauth}}</p>
            </div>
            <div class="item">
              <p class="text">Right Auth</p>
              <p class="sub-text">{{vpndetails.details.rightauth}}</p>
            </div>
            <div class="item">
              <p class="text">DPDT Timeout</p>
              <p class="sub-text">{{vpndetails.details.dpdtimeout}}</p>
            </div>
            <div class="item">
              <p class="text">Left Subnet</p>
              <p class="sub-text">{{vpndetails.details.leftsubnet}}</p>
            </div>
            <div class="item">
              <p class="text">Right Subnet</p>
              <p class="sub-text">{{vpndetails.details.rightsubnet}}</p>
            </div>
            <div class="item">
              <p class="text">IKE Lifetime</p>
              <p class="sub-text">{{vpndetails.details.ikelifetime}}</p>
            </div>
            <div class="item">
              <p class="text">Key Exchange</p>
              <p class="sub-text">{{vpndetails.details.keyexchange}}</p>
            </div>
          </div>
          <div class="left-inner">
            <div class="header">
              <h4>Tunnel Details</h4>
              <span class="vulnerab_label" [ngClass]="getScanStatusClass(vpndetails.status)">{{vpndetails.tunnel_status}}</span>
            </div>
            <div class="VPN-item">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>SPARK VPN Public IP</mat-label>
                <input matInput value="{{vpndetails.spark_vpn_public_id}}" readonly>
                <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails?.spark_vpn_public_id)">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="VPN-item">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>SPARK Subnet</mat-label>
                <input matInput [value]="vpndetails?.spark_subnet" readonly>
                <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails?.spark_subnet)">
                  <mat-icon [ngClass]="{ 'copied': isCopied }">content_copy</mat-icon>
                </button>
              </mat-form-field>
              <!-- <span *ngIf="isCopied" class="copy-feedback">Copied!</span> -->
              
            </div>
            <div class="VPN-item">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>IKE Key</mat-label>
                <input matInput *ngIf="hide" value="{{vpndetails.ike_key}}" type="password" readonly>
                <input matInput *ngIf="!hide" value="{{vpndetails.ike_key}}" type="text" readonly>
                <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails.ike_key)">
                  <mat-icon>content_copy</mat-icon>
                </button>
                <mat-icon matSuffix (click)="hide = !hide" class="hide-icon">{{hide ? 'visibility_off' :
                    'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
            <p class="note-text"><span></span> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--No data screen-->
<div class="no-data-section d-flex" *ngIf="isVpnLoader && !vpnList?.length">
    <div class="no-data-info text_center">
      <img src="../../../../assets/images/Group.svg" />
      <h3>There is no VPN available,</h3>
      <p>Add a VPN to connect SPARK to your private network.</p>
      <button class="btn btn-primary" (click)="addVpn()">Add VPN</button>
    </div>

  </div>