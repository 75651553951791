import { Injectable } from '@angular/core';
import { IAciveDeactiveReq, IDeleteUser, InviteUserReq, IResedPasswordReq, IUpdatedUserReq, IUpdateUserRoleReq, IUserListReq, User } from '../../admin/components/users/model/user.model';
import { END_POINTS } from '../constants/endpoints';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { GlobalSearchService } from './global-search.service';
import { catchError, Observable, throwError } from 'rxjs';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private restService: RestService,
    public responseService: ResponseService,
    private globalSearchService: GlobalSearchService,
    private apiService: ApiService) {

  }
  loadUserList(callback: Function, req: IUserListReq) {
    if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm) {
      req.search = this.globalSearchService.searchTerm;
      if (this.globalSearchService.type === 1){
        req.type = 1;
      }
    }
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    req.type = 1;
    apiDetails.req = req;
    apiDetails.url = END_POINTS.USER_MANAGEMENT_LIST;
    this.apiService.callAPI(apiDetails);
  }
  loadRoleList(callback: Function) {
    const roleReq: any = {};
    if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm) {
      roleReq.search = this.globalSearchService.searchTerm;
    }
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    roleReq.type = 1;
    apiDetails.req = roleReq;
    apiDetails.url = END_POINTS.ORG_ROLES;
    this.apiService.callAPI(apiDetails);
  }
  updateRoleForSelectedUser(callback: Function, req: IUpdateUserRoleReq) {
    this.restService.postApi(END_POINTS.UPDATE_USER_ROLE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  inviteUser(callback: Function, readOnlyRole: string | undefined, emailList: string[]) {
    let req: InviteUserReq[] = [];
    emailList?.forEach((email: string) => {
      const reqObj: InviteUserReq = {};
      reqObj.role_id = readOnlyRole;
      reqObj.email = email;
      req.push(reqObj);
    })
    const subs = this.restService.postApi(END_POINTS.INVITE_USER, req)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  activateDeactivateUser(callback: Function, currentUser: User, isActiveDeactive: boolean) {
    const req: IAciveDeactiveReq = {};
    req.user_id = currentUser.id;
    req.is_active = isActiveDeactive;
    this.restService.postApi(END_POINTS.ACTIVATE_DEACTIVATE_USER, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  deleteUser(callback: Function, user: User) {
    const deleteUsers = {user_id: [user.id]}
    this.restService.postApi(END_POINTS.DELETE_USER, deleteUsers)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  multipleDeleteUser(callback: Function, users: any) {
    const deleteUsers = {user_id: users.map((item: any) => item.id)}
    this.restService.postApi(END_POINTS.DELETE_USER, deleteUsers)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  resedPasswordLink(callback: Function, user: User) {
    const req: IResedPasswordReq = {};
    req.email = user?.organization_email;
    this.restService.postApi(END_POINTS.RESET, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  updateUser(callback: Function, updatedUser: User){
    const req: IUpdatedUserReq = {};
    req.user_id = updatedUser.id;
    req.firstname = updatedUser.firstname || null;
    req.lastname = updatedUser.lastname || null;
    req.phone_number = updatedUser.phone_number || null;
    req.country_code = updatedUser.country_code || null;
    req.organization_email = updatedUser.organization_email || null;
    this.restService.postApi(END_POINTS.UPDATED_USER, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
}
