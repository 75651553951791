import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent {
  public params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

}
