import { Component } from '@angular/core';
import { IFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-filter-manual',
  templateUrl: './filter-manual.component.html',
  styleUrls: ['./filter-manual.component.scss']
})
export class FilterManualComponent {
  params!: IFilterParams;
  filterValues: string[] = [];

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  onCheckboxChange(filter: string, event: any): void {
    const checkbox = event;
    if (checkbox.checked) {
      this.filterValues.push(filter);
    } else {
      this.filterValues = this.filterValues.filter((value) => value !== filter);
    }
  }

  applyFilter(): void {
    this.params.filterChangedCallback(); 
  }

  isFilterActive(): boolean {
    return this.filterValues.length > 0;
  }

  doesFilterPass(params: any): boolean {
    const cellValue = params.data.controls_type
    return this.filterValues.includes(cellValue);
  }

  getModel() {
    return { values: this.filterValues };
  }

  setModel(model: any): void {
    this.filterValues = model?.values || [];
  }
}
