<mat-toolbar>
  <div class="scan-header">
    <div class="left-header-scan">
      <div class="scan-section" *ngIf="title !== 'New Scan'">        
        <div *ngIf="newScanDetails?.scan_datetime" class="time rating">
          <p class="time-label">Time </p>
          <p>{{newScanDetails?.scan_datetime |  date: 'dd-MMM-yyyy HH:mm:ss'}} </p>
        </div>
        <div *ngIf="newScanDetails?.rating  && newScanDetails?.rating >0" class="time rating">
          <p class="time-label">Security Score</p>
          <!-- <P>
            
            <span appSafariEllipsis [ngClass]="newScanDetails?.ratingcls" class="bg-critical br-small white-text sec-value text_ellipsis" appSafariEllipsis [options]="toolTipOption" [tooltip]="newScanDetails?.rating">{{newScanDetails?.rating_rounded}}</span>
          </P>  -->
          <p [ngClass]="newScanDetails?.ratingcls" class="bg-critical br-small white-text sec-value text_ellipsis"   matTooltip="{{newScanDetails?.rating}}" class="text_ellipsis paramer-value">{{newScanDetails?.rating}}</p>
        </div>
        <div class="time rating" *ngIf="newScanDetails?.rating  && newScanDetails?.rating >0">
          <p class="time-label">Risks Accepted</p>
            <p class="whitelisted-label bg-tertiary">
              <span class="whitelisted-value text-tertiary">{{acceptedRisk}}</span>
              <span class="whitelisted-text">Parameter(s)</span>                             
            </p>
        </div>
      </div>
      <span class="example-spacer"></span>
    </div>
  </div>
</mat-toolbar>