<div class="out-layout">
  <div class="cve-overlay_page page_content">
    <div class="cve-overlay-header" *ngIf="showSearch">
      <div class="left-mgt-head">
        <div class="search-bar">
          <mat-form-field appearance="fill">
            <mat-label>Search CVE</mat-label>
            <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text" (input)="filterAssetsField()"
              #searchField>
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
    </div>
    </div>
    <app-ag-grid #myGrid [agDeviceColumnDefs]="agDeviceColumnDefs" [data]="commonVulnarabilites"
    [gridClass]="'new_vul_tabel ag-grid-loader-bg'" [loader]="isApiCalled" [customSidebar]="isDashboard ? false : true"
    [restrictCheckBoxSelection]="false" 
    (onRowSelectedChange)="onRowSelected($event)">
</app-ag-grid>
  </div>
</div>