import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VpnGuardGuard implements CanActivate {
    public vpn = environment?.onPremRestriction?.vpn;
    constructor(private route: Router) { }
    canActivate(): boolean {
        if (this.vpn) {
            return true;
        } else {
            this.route.navigate(["login"]);
            return false;
        }
    }
  
}
