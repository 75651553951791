import { Component } from '@angular/core';
import { CloudSecurityService } from '../Services/cloud-security.service';
import { CONFIG } from '../../shared/constants/config';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { IUpdateOverLayPerameter } from './cloud-resource.model';
import { CveAssetsService } from '../../shared/services/cve-assets.service';
import { FilterService } from '../../shared/services/filter.service';

@Component({
  selector: 'app-cloud-resource',
  templateUrl: './cloud-resource.component.html',
  styleUrls: ['./cloud-resource.component.scss']
})
export class CloudResourceComponent {
  public dataSource = new MatTableDataSource<any>([]);
  //dataSource:any;
  public rowHeight = 48;
  public selectedRow: any[] = [];
  isApiCallDone = false;
  devicePopup: any;
   public tableHeader = CONFIG.TABLE_INFO.CLOUD_RESOURCE_LIST.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CLOUD_RESOURCE_LIST.PROPS;
  colDefs: ColDef[] = [
      {
        headerName: this.tableHeader.SERIAL_NO,
        field: this.tableProps.SERIAL_NO,
        sortable: false,
        filter: false,
        minWidth: 60,
        //cellRenderer: CommonTooltipComponent,
        maxWidth:70,
        lockPosition: true,
        pinned: 'left'
      },
      {
        headerName: this.tableHeader.RESOURCE_NAME,
        field: this.tableProps.RESOURCE_NAME,
        sortable: true,
        filter: true,
        minWidth: 250,
         maxWidth: 300,
        lockPosition: true,
        pinned: 'left',
      },
      {
        headerName: this.tableHeader.RESOURCE_TYPE,
        field: this.tableProps.RESOURCE_TYPE,
        headerTooltip: this.tableHeader.RESOURCE_TYPE,
        sortable: true,
        filter: true,
        lockPosition: true,
        pinned: 'left',
        minWidth: 150,
        maxWidth: 300
      },
      {
        headerName: this.tableHeader.ACCOUNT_ID,
        headerTooltip: this.tableHeader.ACCOUNT_ID,
        field: this.tableProps.ACCOUNT_ID,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.REGION,
        headerTooltip: this.tableHeader.REGION,
        field: this.tableProps.REGION,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.LAST_SCANNED,
        headerTooltip: this.tableHeader.LAST_SCANNED,
        field: this.tableProps.LAST_SCANNED,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.SCORE,
        headerTooltip: this.tableHeader.SCORE,
        field: this.tableProps.SCORE,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.VULNERABILITIES,
        headerTooltip: this.tableHeader.VULNERABILITIES,
        field: this.tableProps.VULNERABILITIES,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
    ];
  constructor(
     private cloudService:CloudSecurityService,
     private cveAssetsService: CveAssetsService,
     private filterService: FilterService) {
    this.cloudService.resourcesList(this.loadDeviceListCallback,{"type":1});
    //console.log(cloudService.cloudDevice)

    
  }
  loadDeviceListCallback = (response: any) => {
    this.isApiCallDone=true;
    console.log(response);
    this.dataSource = new MatTableDataSource<any>(response.data.cloud_resource);
  }
  public openAssetsDetails() {
    this.filterService.toggleSidenav('cveAssetsDetails');
  }
  public onRowSelected(selectedRow: any) {
    this.selectedRow = selectedRow;
    const updateRowPerameter = new IUpdateOverLayPerameter(_.cloneDeep(selectedRow[0]), 'cls')
    this.openAssetsDetails();
    this.cveAssetsService.dataForAssetsDetails.emit({isAssetDetails: true, selectedRow: updateRowPerameter, selectedIndex: 0, isDeviceManagementNavigation:false, isCveHide: true, moduleType: 'cloudResource'});
  }
}
