import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { CloudSecurityComponent } from './cyber-security/cloud-security/cloud-security.component';
import { CloudResourceComponent } from './cloud-resource/cloud-resource.component';

const routes: Routes = [
  { path: '', component: CyberSecurityComponent},
  {
    path: 'cloud-controls', component: CloudSecurityComponent
  },
  {
    path: 'cloud-resource', component: CloudResourceComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CyberSecurityRoutingModule { 
  
}
