import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ICloudBenchmark } from '../../cloud-security';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { OverlayResourceTypeComponent } from './overlay-resource-type/overlay-resource-type.component';
import { OverlayComplainceComponent } from './overlay-complaince/overlay-complaince.component';
import { OverlayAccptedRiskComponent } from './overlay-accpted-risk/overlay-accpted-risk.component';
import { Subscription } from 'rxjs';
import { CloudTabsService } from '../../Services/cloud-tabs.service';

@Component({
  selector: 'app-cloud-security-overlay',
  templateUrl: './cloud-security-overlay.component.html',
  styleUrls: ['./cloud-security-overlay.component.scss']
})
export class CloudSecurityOverlayComponent {
//cis json 
isApiCallDone:boolean=false;
rowHeight:number=40;
public resourceSubscribe!: Subscription;
public resourceData:boolean = false;
public hideTab: boolean = false;
public cisJson = {
  "cisVersions": [
    {
      "cisVersion": "CIS Version 8",
      "cisHeader": "17.2 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    },
    {
      "cisVersion": "CIS Version 7",
      "cisHeader": "17.3 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    },
    {
      "cisVersion": "CIS Version 6",
      "cisHeader": "17.4 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    }
  ]
}


  showBenchamrks = [
    {
      type:'cis',
      image_src:'../../../../../assets/images/cis_overlay.svg'
    },
    {
      type:'nist',
      image_src:'../../../../../assets/images/nist_overlay.svg'
    },
    {
     type:'hippa',
     image_src:'../../../../../assets/images/hippa_overlay.svg'
    },
    {
      type:'pci',
      image_src:'../../../../../assets/images/pci_overlay.svg'
    },
    {
      type:'mitre',
      image_src:'../../../../../assets/images/mitre_overlay.svg'
    }
  ]
  showBenchmarkContainer = ['cis','nist','hippa','pci','mitre'];
  selectedBenchmark:string= this.showBenchamrks[0].type;
  constructor(private filterService: FilterService,public cloudTabsService:CloudTabsService)
  {
    console.log('loaded');
    this.filterService.sidenavCloseEvent.subscribe(()=>{
      if(this.resourceSubscribe) {
        this.resourceSubscribe.unsubscribe();
      }
    })
    this.resourceSubscribe = this.cloudTabsService.resourceRow.subscribe((data:any)=>{
      console.log(data,'hi I am data');
        if(data){ 
        this.resourceData = true;
        this.hideTab = true;
        }
        });
  }

  public goBack(){
    this.resourceData = false;
    this.hideTab = false; 
  }

  public closeSidenav() {
    this.filterService.closeSidenav();
  }
  onTabChange(event: MatTabChangeEvent) {

    }
    logoclick(event:ICloudBenchmark){
     console.log('clicked', event); 
     this.selectedBenchmark = event.type || '';     
    }
  
  }

