import { Type } from '@angular/core';
import { VulnerabilityComponent } from '../components/vulnerability/vulnerability.component';
import { Position } from 'monaco-editor';
import { CommonTooltipComponent } from '../components/common-tooltip/common-tooltip.component';


export const CONFIG: any = {
  UNIQUE_API_PROPS: {
    myscans: 'scan_uuid',
    asset_management: 'id',
    scan_status: 'device_id'
  },
  PAGE_API: {
    ASSETS_DETAILS: 'asset_management',
    SCAN_STATUS: 'scan_status',
    DECOMMISION_ASSETS: 'decommission',
    CVE_DASHBOARD: 'cve-list',
    MY_SCANS:'myscans',
    SCHEDULE_LIST:'schedule_list',
    SCAN_STATUS_LIST:'security_status_list',
    SECURITY_INFO_LIST:'security_info_list',
    SCAN_DETAILS:'scan_details_list',
    USER_MANAGEMENT: 'user-management',
    USER_ROLE: 'user-roles',
    COMMON_VULNERABILITY: 'common-vulnerability',
    NEW_VULNERABILITY: 'new-vulnerability',
    WHITELIST_DETAILS: 'whitelist'
  },
  METHOD: {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    DELETE: 'delete'
  },
  RESPONSE_TYPE: {
    SINGLE: 'single',
    ASYNC: 'async',
    NONE: 'none',
  },
  API_CONFIG: {
    ASSETS: {
      NUMBER_OF_PAGES: 5,
    },
  },
  AG_GRID_CONFIG: {
    RESTRICT_CHECKBOX_SELECTION_ON_ROW_CLICK: true,
    SCROLL_WIDTH: 5,
    HIDE_CONTEXT_MENU: true,
    enableOnChange: true,
    pagination: {
      SHOW: true,
      DROP_DOWN: [10, 50, 100],
      PAGE_SIZE: 50,
    },
    ROW_SELETION: {
      SINGLE: 'single',
      MULTIPLE: 'multiple',
    },
    DEFAULT_COLUMN: {
      // minWidth: 150,
      // maxWidth: 500,
      editable: false,
      suppressMenu: true,
      cellRenderer: CommonTooltipComponent,
      filter: 'agTextColumnFilter',
    },
    // SIDEBAR: {
    //   toolPanels: [
    //     {
    //       id: 'columns',
    //       labelDefault: 'Columns',
    //       labelKey: 'columns',
    //       iconKey: 'columns',
    //       toolPanel: 'agColumnsToolPanel',
    //       toolPanelParams: {
    //         suppressRowGroups: true, // Hide row groups
    //         suppressValues: true,
    //         suppressPivots: true,
    //         suppressPivotMode: true,
    //         suppressSideButtons: true,
    //         suppressColumnFilter: false,
    //         suppressColumnSelectAll: false,
    //         suppressColumnExpandAll: false,
    //         isToolPanelShowing: true,
    //       },
    //     },
    //   ],
    //   defaultToolPanel: 'filters',
    //   position:'left'
    // },
  },
  TABLE_INFO: {
    WHITE_LIST_PARAM: {
      HEADER_LABEL: {
        SERIAL_NO: '#',
        PERAMETER: 'Parameters',
        BENCH_MARK: 'Benchmark',
        RISK: 'Accepted Risk',
        VULNERABILITY: 'Severity'
      },
      PROPS: {
        SERIAL_NO: 'position',
        PERAMETER: 'paramter',
        BENCH_MARK: 'benchmark',
        RISK: 'accepted_risk',
        VULNERABILITY: 'vulnerability'
      }
    },
    ASSETS: {
      HEADER_LABEL: {
        BLANK: '',
        POSITION: '#',
        ASSETS_NAME: 'Asset Name',
        ASSETS_TYPE: 'Asset Type',
        OEM: 'OEM',
        REGION: 'Region',
        TOTAL_SCAN: 'Total & Last Scan',
        SECURITY_SCORE: 'Security Score',
        VULNERABILITY: 'Vulnerabilities',
        TYPE: 'Type',
        VENDOR: 'Vendor',
        CVES: 'CVE’s',
        OPTIONS:'Options',
        LAST_UPDATED: 'Last Updated'
      },
      PROPS: {
        CHECKBOX: 'selection',
        ASSETS_NAME: 'device_name',
        ASSETS_TYPE: 'device_type',
        OEM: 'vendor',
        REGION: 'region_name',
        TOTAL_SCAN: 'last_scan_date',
        SECURITY_SCORE: 'security_score',
        VULNERABILITY: 'vulnerability',
        OPTIONS: 'options',
        POSITION: 'position',
        CVES: 'cve',
        LAST_UPDATED: 'cve_last_modified'
      }
    },
    MY_SCANS: {
      HEADER_LABEL: {
        BLANK:'Options',
        SERIAL_NO: '#',
        SCAN_NAME: 'Scan Name',
        SCAN_TIME: 'Scan Time',
        STATUS: 'Status',
        ASSETS: 'Assets',
        SECURITY_SCORE: 'Security Score',
        VULNERABILITIES: 'Vulnerabilities',
        USER: 'User'

      },
      PROPS: {
        SERIAL_NO: 'position',
        SCAN_NAME: 'scanname',
        SCAN_TIME: 'date_time',
        STATUS: 'status',
        ASSETS: 'total_count',
        SECURITY_SCORE: 'securityrating',
        VULNERABILITIES: 'vulnerabilities',
        USER: 'user',
        OPTIONS: 'options',
      },
    },
    SCAN_STATUS: {
      HEADER_LABEL:{
        SERIAL_NO: '#',
        FILE_NAME:'File Name',
        ASSET_NAME:'Asset Name',
        STATUS:'Status',
        REGION:'Region',
        TYPE:'Type',
        VENDOR:'Vendor',
        COMMENT:'Comments'


      },
      PROPS: {
        SERIAL_NO: 'position',
        FILE_NAME:'file_name',
        ASSET_NAME:'asset_name',
        STATUS:'Status',
        REGION:'region',
        TYPE:'category',
        VENDOR:'vendor',
        COMMENT:'reason'

      }
    },
    SCHEDULE_LIST: {
      HEADER_LABEL:{
        POSITION: '#',
        SERIAL_NO: 'Scan ID',
        SCAN_NAME:'Scan Name',
        FREQUENCY:'Frequency',
        CREATED_BY:'Created By',
        SCHEDULED_TIME:'Scheduled Time',
        STATUS: 'Schedule Status',
        NUBMER_OF_ASSETS:'Number of Assets',
        DATE:'Date'
      },
      PROPS: {
        POSITION: 'position',
        SERIAL_NO: 'schedule_scan_id',
        SCAN_NAME:'schedule_name',
        FREQUENCY:'frequecy_name',
        CREATED_BY:'user_name',
        SCHEDULED_TIME:'time',
        STATUS: 'updated_schedule_status',
        NUBMER_OF_ASSETS:'num_of_assets',
        DATE:'date',

      }
    },
    SECURE_INFO: {
      HEADER_LABEL: {
        POSITION: '#',
        PARAMETERS: 'Parameters',
        BENCHMARK: 'Benchmark',
        ACCEPTED_RISK: 'Accepted Risk',
        WHITELIST: 'Whitelist',
        COMPLAINT_ASSETS: 'Compliant Assets',
        SEVERITY: 'Severity',
        
      },
      PROPS: {
        POSITION: 'position',
        PARAMETERS: 'paramter',
        DESCRIPTION:'desctiption',
        BENCHMARK: 'bechmark',
        ACCEPTED_RISK: 'accepted_risk',
        WHITELIST: 'Whitelist',
        COMPLAINT_ASSETS: 'passd_percentage',
        SEVERITY: 'vulnerability',
      },
    },
    CVE_DASHBOARD: {
      HEADER_LABEL: {
        POSITION: '#',
        CVE_ID: 'CVE',
        CVSS: 'CVSS',
        SEVERITY: 'Severity',
        CVSS_DATE: 'Issued Date',
        CVSS_UPDATE_DATE: 'Last updated',
        CISA_KEY: "CISA's KEV",
        EPSS: 'EPSS',
        VECTOR: 'Vector',
        AFFECTED_ASSETS: 'Affected Assets',
      },
      PROPS: {
        POSITION: 'position',
        CVE_ID: 'cve_id',
        CVSS: 'score',
        SEVERITY: 'severity',
        CVSS_DATE: 'cve_issued_date',
        CVSS_UPDATE_DATE: 'cve_last_updated',
        CISA_KEY: 'cisas_key',
        EPSS: 'epss',
        VECTOR: 'vector_string',
        AFFECTED_ASSETS: 'asset_count',
      },
      CVE_DETAILS_ASSETS_PROPS: {
        ASSETS_NAME: 'asset_name',
        ASSETS_TYPE: 'asset_type',
        VENDOR: 'vendor',
        REGION: 'region_name'
      }
    },
    REGION_MANAGEMENT: {
      HEADER_LABEL: {
          CHECKBOX: '#',
          POSITION: '#',
          FIRST_NAME: 'First Name',
          LAST_NAME: 'Last Name',
          EMAIL: 'Email',
          PHONE: 'Phone',
          MFA: 'MFA',
          STATUS: 'Status',
          ROLE: 'Role'
      },
      PROPS:{
        CHECKBOX: 'selection',
        POSITION: 'position',
        FIRST_NAME: 'firstname',
        LAST_NAME: 'lastname',
        EMAIL: 'organization_email',
        PHONE:'phone_number',
        COUNTRY: 'country_code',
        MFA: 'mfa',
        STATUS: 'status',
        ROLE: 'role',
        OPTIONS: 'option',
      }
    },
    COMMON_VULNERABILITY: {
      HEADER_LABEL: {
        Vulnerability_Type: 'Vulnerability',
        TYPE: 'Severity',
        ASSETS: 'Asset',
    },
    PROPS:{
      COUNT: 'count',
      PARAMETER: 'paramter',
      FAILED_COUNT: 'failed_count',
      VULNERABILITY: 'vulnerability',
      DEVICE:'device',
    }
    },
    NEW_VULNERABILITY: {
      HEADER_LABEL: {
        POSITION: '#',
        PARAMETER: 'Parameter',
        SEVERITY: 'Severity',
        ACCEPTED_RISK: 'Accepted Risk',
        COMPLIANCE:'Compliance'
    },
    PROPS:{
      POSITION: 'position',
      PARAMETER: 'paramter',
      SEVERITY: 'vulnerability',
      ACCEPTED_RISK: 'accepted_risk',
      COMPLIANCE: 'compliance',
      COUNT: 'count',
      ID: 'id'
    }
    },
    ASSET_WITH:{
      HEADER_LABEL: {
        NAME: 'Asset',
        TYPE: 'Type',
        REGION: 'Region',
        SCORE: 'Score',
        CVE: 'CVE'
      },
      PROPS: {
        NAME: 'hostname',
        TYPE: 'vendor_category',
        REGION: 'region',
        SCORE: 'score',
        CVE: 'cve'
      },
    },
    CLOUD_SECURE_INFO: {
      HEADER_LABEL: {
        POSITION: '#',
        PARAMETERS: 'Controls',
        BENCHMARK: 'Benchmark',
        SEVERITY: 'Severity',
        ACCEPTED_RISK: 'Risk Accepted',
        COMPLAINT_ASSETS: 'Compliance',
      },
      PROPS: {
        POSITION: 'position',
        PARAMETERS: 'controls',
        BENCHMARK: 'bechmark',
        SEVERITY: 'vulnerability',
        ACCEPTED_RISK: 'accepted_risk',
        COMPLAINT_ASSETS: 'passd_percentage',
      },
    },
    CLOUD_SECURE_RESOURCE: {
      HEADER_LABEL: {
        RESOURCE: 'Resource Type',
        RESOURCE_NAME: 'Resource Name',
        ACCOUNT: 'Account',
        ACCEPTED_RISK: 'Accepted Risk',
        COMPLAINCE: 'Complaince',
      },
      PROPS: {
        RESOURCE: 'resource_type',
        CONTROL_TYPE:'control_type',
        RESOURCE_NAME: 'resource_name',
        ACCOUNT: 'account',
        ACCEPTED_RISK: 'accepted_risk',
        COMPLAINCE: 'complaince',
      },
    },
    CLOUD_RESOURCE_LIST: {
      HEADER_LABEL: {
        SERIAL_NO: '#',
        RESOURCE_NAME: 'Resource Name',
        RESOURCE_TYPE: 'Resource Type',
        ACCOUNT_ID: 'Account ID',
        REGION: 'Region',
        LAST_SCANNED: 'Last Scanned ',
        SCORE:'Score',
        VULNERABILITIES:'Vulnerabilities',

      },
      PROPS: {
        SERIAL_NO: 'position',
        RESOURCE_NAME: 'resource_name',
        RESOURCE_TYPE: 'resources_type',
        ACCOUNT_ID: 'account_id',
        REGION: 'region',
        LAST_SCANNED: 'last_scanned',
        SCORE:'score',
        VULNERABILITIES:'vulnerability',
      },
    },
  },
};
