<div class="asset-tabcontent">
    <div class="search-bar horizontal-border-bottom">
        <mat-form-field appearance="fill">
            <mat-label>Search Resource</mat-label>
            <input matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off"
                type="text" #searchField>
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <mat-card>
        <app-ag-grid
        #myGrid
        [agDeviceColumnDefs]="agDeviceColumnDefs"
        [data]="resourceJson"
        [gridClass]="'resource_page_content ag-grid-loader-bg'"
        [loader]="true"
        [rowHeight]="rowHeight"
        [customSidebar]="resourceJson.length > 0"
        (onRowSelectedChange)="onRowSelected($event)"
        [tableKey]="'resource-table'">
       </app-ag-grid>                    
    </mat-card>
</div>