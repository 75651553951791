import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from '../../../utils';

@Component({
  selector: 'app-severity',
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss']
})
export class SeverityComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  filterMenuOpen = false;
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.params.data.severityClass = this.severityStatus(this.params?.data?.vulnerability);
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  severityStatus(type:string){
    return Utils.addBadgeClass(type)
  }

}
