<div class="cve-assets-details-section">
  <div class="cve-assets-details-header">
    <div class="cve-header cve-header-details" *ngIf="isCVEdetails">
      <app-cve-overlay-header [cveDetails]="cveDetails" [selectedCVE]="dataForCveDetails"></app-cve-overlay-header>
    </div>
    <div class="cve-header assets-header-details" *ngIf="isAssetDetails">
      <div class="asset-header-left">
        <p>{{ deviceId }}&nbsp;({{deviceVendor}}-{{deviceType}})</p>
        <!-- <button mat-icon-button aria-label="Customize" class="btn asset-btns kebab-btn">
          <mat-icon>more_vert</mat-icon>
      </button>     -->
      <div *ngIf="isDeviceManagementNavigation">
        <button mat-icon-button class="mat-kebab-btn  show" [matMenuTriggerFor]="menu"
            (menuOpened)="onMenuOpen(assetDataDetails.id)" (menuClosed)="onMenuClose()"
            aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <mat-menu #menu="matMenu" class="white-menu">
        <ng-container *ngFor="let item of menuListItems">
            <button [ngClass]="{'red-text':item==CANCESCHEDULE}" mat-menu-item *ngIf="kebabMenuController(item,assetDataDetails)"
                (click)="menuClick(item, assetDataDetails)">
                {{ item }}
            </button>
        </ng-container>
    </mat-menu>
          <button class="btn asset-btns" (click)="downloadAssetsSummary()">
            <img src="../../assets/images/download-icon-blue.svg" />
          </button>
          <!-- <button  class="btn asset-btns">
            <img src="../../assets/images/setting-icon.svg" alt="">
        </button>  -->
      </div>
      <!-- <p class="text_ellipsis region_name" appSafariEllipsis matTooltip="{{regionName}}">{{regionName}}</p> -->
      <label class="region_name">
        <p matTooltip="{{regionName}}" class="text_ellipsis paramer-value">{{regionName}}</p>
    </label>
    </div>
    <div class="close-btn">
      <button (click)="closeSidenav()" mat-button class="btn close-sidebar">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
</div>
<div class="assets-details"  *ngIf="!isCVEdetails && isAssetDetails">
  <app-tabs-assets [assetsData]="assetsData" [selectedIndex]="selectedIndex"></app-tabs-assets>
</div>
<div class="cve-details" *ngIf="isCVEdetails">
  <app-cve-details [assetsData]="affectedDeviceResponse" [assetLoader]="assetsDataLoader" [cveDetails]="cveDetails"></app-cve-details>
</div>
</div>