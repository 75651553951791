<mat-sidenav-container class="main-content">
    <mat-sidenav #deviceSidenav mode="over" position="end" class="filter-sidenav change-location">
        <app-filter type="radio" *ngIf="filterColumnData.get('Region')?.length" [filterColumnData]="filterColumnData"
            (applyBasedOnSelectedData)="selectedFilters($event)" [resetButtonRequired]="true"
            [saveButtonRequired]="true" [saveBtnLabel]="'Save'" [title]="'Change Location'">
        </app-filter>
        <div class="item" *ngIf="!isFirstLevelFetchBtn">
            <ngx-skeleton-loader class="rhs-content-loader head" count="4"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="rhs-content-loader global-search-loader" count="1"></ngx-skeleton-loader>
            <div class="global-loader-section">
                <div>
                    <ngx-skeleton-loader class="rhs-content-loader checkbox-box-loader"
                        *ngFor="let row of [].constructor(12)" count="2"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="out-layout">
            <div class="device_page page_content" [ngClass]="{'is-dommission-enable': isDecommissioned}">
                <div class="device-mgnt-header">
                    <div class="left-mgt-head">
                        <div class="search-bar">
                            <mat-form-field appearance="fill">
                                <mat-label>Search Assets</mat-label>
                                <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text"
                                [ngModel]="searchText" (input)="filterAssetsField()" #searchField>
                                <mat-icon class='search-icon' matPrefix>search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="right-mgt-head">
                        <div class="double-btn-section">
                            <!-- <label for="pageNumber">Page:</label>
                            <input id="pageNumber" type="number" [(ngModel)]="pageNumber">
                            <button (click)="goToPage()">Go</button> -->
                            <app-excel-export [rowData]="dataSource?.data" [isExportEnabled]="isExportEnabled"></app-excel-export>
                            <!-- <button class="btn btn-primary" (click)="exportToExcel()">Export to Excel</button> -->
                            <button class="btn download-btn" *ngIf="!isDecommissioned"
                                [disabled]="selectedRow.length==0" (click)="downloadAssetsSummary()">
                                <img src="../../assets/images/sim_card_download.svg" />
                            </button>
                            <div class="dropdown">
                                <button [matMenuTriggerFor]="menu" class="dropdown-button"
                                    [disabled]="selectedRow.length==0"
                                    [ngClass]="{'disabled-button': selectedRow.length==0}"><b>Actions<mat-icon
                                            class="sort-icon">keyboard_arrow_down</mat-icon></b></button>
                                <mat-menu #menu="matMenu" class="mat-menu-scroll white-menu">
                                    <div *ngIf="!isDecommissioned">
                                        <div *ngIf="!isNotSchedulable()">
                                            <button mat-menu-item (click)="onScheduleAsset()">Schedule Scan</button>
                                            <button mat-menu-item [disabled]="hasSchedulableOrNotSchedulable()"
                                                class="red-text" *ngIf="!hasSchedulableOrNotSchedulable()"
                                                class="red-text" (click)="onCancelSchedule()">Cancel
                                                Schedule</button>
                                        </div>
                                        <button mat-menu-item
                                            *ngIf="selectedRow.length && hasPermission(permissionProps.DECOMMISSION_DEVICE)"
                                            (click)="checkMultipleAuthorization(selectedRow)">Decommission</button>
                                        <button mat-menu-item
                                            *ngIf="hasPermission(permissionProps.MODIFIY_DEVICE_LOCATION)&&selectedRow.length&&hasPermission(permissionProps.MODIFY_ROLE_REGION_DEVICE)&&hasPermission(permissionProps.MODIFY_REGION_LEVEL)"
                                            (click)="openRegionSidebar()">Change Location</button>
                                        <button mat-menu-item
                                            *ngIf="hasPermission(permissionProps.DELETE_DEVICE)&&selectedRow.length&&!unique_devices"
                                            (click)="deleteDeviceRow(selectedRow)" class="red-text">Delete</button>
                                        <button mat-menu-item
                                            *ngIf="hasPermission(permissionProps.DELETE_DEVICE)&&selectedRow.length&&unique_devices"
                                            (click)="deleteDevices(selectedRow)" class="red-text">Delete</button>
                                        <button mat-menu-item
                                            *ngIf="hasPermission(permissionProps.CREDENTIAL_MANAGER)&&selectedRow.length && anySelectedDeviceRequiresRescan()"
                                            (click)="rescanWithMultiCredentional(selectedRow)">Re-scan with different
                                            credential</button>
                                        <button mat-menu-item
                                            *ngIf="hasPermission(permissionProps.CREDENTIAL_MANAGER)&&selectedRow.length && anySelectedDeviceRequiresRescan()"
                                            (click)="MapMultiCredentional(selectedRow)">Map credential</button>

                                    </div>
                                    <div *ngIf="isDecommissioned" class="recomission-btn">
                                        <button mat-menu-item *ngIf="selectedRow.length"
                                            (click)="checkMultipleAuthorization(selectedRow)">Commission</button>
                                    </div>
                                </mat-menu>
                            </div>

                        </div>
                        <p>
                            <strong>Show only Decommissioned </strong>
                            <mat-checkbox [(ngModel)]="isDecommissioned" [ngModelOptions]="{standalone: true}"
                                (change)="showDecommissionedDevices(isDecommissioned)">
                            </mat-checkbox>
                        </p>

                    </div>
                </div>

                <app-ag-grid
                 #myGrid
                 [agDeviceColumnDefs]="agDeviceColumnDefs"
                 [data]="dataSource?.data"
                 [gridClass]="'device_page_content ag-grid-loader-bg'"
                 [loader]="isApiCallDone"
                 [rowHeight]="rowHeight"
                 [customSidebar]="dataSource && dataSource.data.length > 0"
                 (onRowSelectedChange)="onRowSelected($event)"
                 [tableKey]="'assets-table'">
                </app-ag-grid>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
