<mat-tab-group #tabgroup  (selectedTabChange)="onTabChange($event)"  class="asset-tabgroup"  [class.hidden]="hideTab" [selectedIndex]="selectedIndex">
    <mat-tab label="Summary">
      <div class="asset-tabcontent">
        <mat-card>
            <app-summary-assets [apiPayload]="apiPayload" [securityApiName]="securityApiName" [vulnerabilityApiName]="vulnerabilityApiName" [vulnerabilityCveApiName]="vulnerabilityCveApiName" [cyberResilienceGraphApiName]="cyberResilienceGraphApiName" [deviceId]="deviceID"></app-summary-assets>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab class=" vulnerabilities-tab"> 
      <ng-template mat-tab-label>
        Controls ({{vulCountShow? vulRowCount || 0 : '-'}})
      </ng-template>
      <div class="asset-tabcontent">
        <mat-card>
          <div class="vul_list">
          <!-- <app-common-vulnerability [filterPage]="'commonVulDevice'" [title]="commonVulnerabilityTitle"
          [apiName]="commonVulnerabilityApiName" [showFilter]="commonVulnerabilityFilter" [payLoad]="apiPayload">
      </app-common-vulnerability> -->
      <app-new-vulnerability  [filterPage]="'commonVulDevice'" [title]="newVulnerabilityTitle"
      [apiName]="newVulnerabilityApiName" [showFilter]="newVulnerabilityFilter" (vulListLength)="handleVulListLength($event)" [payLoad]="apiPayload"></app-new-vulnerability>
    </div>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab *ngIf="isCveTab" class=" cve-tab">
       <ng-template mat-tab-label>
        CVE’s ({{cveCountShow ? cveRowCount || 0 : '-'}})
      </ng-template>

      <div class="asset-tabcontent">
        <mat-card>
          <!-- <div class="vul_list"> -->
            <app-cve-assets (openCveSummery)="selectedCveRow($event)" (cveListLength)="handleCveListLength($event)" [payLoad]="apiPayload"></app-cve-assets>
          <!-- </div> -->

        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div  class="assets-details parameter-details"  *ngIf="!vulRowData">
    <div  *ngIf="vulHeading && vulHeading.paramter?.length > 0 && vulHeading.vulnerability?.length > 0 && vulHeading.accepted_risk !== null" class="parameter-detail-header">
      <p class="parameter-name">
        <!-- <span class="text_ellipsis name" matTooltip="{{vulHeading.paramter}}" appSafariEllipsis><mat-icon class="back-arrow" (click)="goBack()">keyboard_backspace</mat-icon>{{vulHeading.paramter}}</span> -->
         <span> 
          <p class="name" matTooltip="{{vulHeading.paramter}}" class="text_ellipsis paramer-value "><mat-icon class="back-arrow" (click)="goBack()">keyboard_backspace</mat-icon>{{vulHeading.paramter}}</p></span>
        <span class="severity-name">
          <div class="severity-label" *ngIf="vulHeading.vulnerability === 'Critical'">
            <div *ngFor="let value of [].constructor(4)" class="color-indicator critical"></div>
        </div>

        <div class="severity-label" *ngIf="vulHeading.vulnerability === 'High'">
            <div *ngFor="let value of [].constructor(3)" class="color-indicator high"></div>
            <div class="color-indicator plain"></div>
        </div>

        <div class="severity-label" *ngIf="vulHeading.vulnerability === 'Medium'">
            <div *ngFor="let value of [].constructor(2)" class="color-indicator medium"></div>
            <div *ngFor="let value of [].constructor(2)" class="color-indicator plain"></div>
        </div>

        <div class="severity-label" *ngIf="vulHeading.vulnerability === 'Low'">
            <div class="color-indicator low"></div>
            <div *ngFor="let value of [].constructor(3)" class="color-indicator plain"></div>
        </div>
         {{vulHeading.vulnerability}}
       </span>
      </p>
      <div class="risk-accepted">Accepted risk: <p class="whitelisted-label bg-tertiary no-count" *ngIf="vulHeading.accepted_risk > 0"><span
            class="whitelisted-text">Accepted</span> </p>
      </div>
      <div class="parameter-status">

        <div class="compliance-item">Status:
          <div [ngClass]="{'pass-item': vulHeading.compliance === 'true','fail-item': vulHeading.compliance === 'false' }" class="compliance-status">
              <mat-icon *ngIf="vulHeading.compliance === 'false'">close</mat-icon>
              <mat-icon *ngIf="vulHeading.compliance === 'true'">check</mat-icon>
          </div>
          <span class="status-text" *ngIf="vulHeading.compliance === 'false'">Fail</span>
          <span class="status-text" *ngIf="vulHeading.compliance === 'true'">Pass</span>

      </div>

      </div>
    </div>
    <div class="item" *ngIf="!selectedRowApiCall">
      <div class="c-sec-loader">
        <ngx-skeleton-loader count="2" class="rhs-content-loader beach-mark-loader "> </ngx-skeleton-loader>
        <ngx-skeleton-loader count="2" class="rhs-content-loader graph-section-loader"> </ngx-skeleton-loader>
     </div>
      <ngx-skeleton-loader count="4" class="rhs-content-loader content-loader"> </ngx-skeleton-loader>
    </div>
    <div class="benchmark-mapping" *ngIf="selectedRowApiCall && selectedRowInfoDetails?.benchmark_names">
      <div class="bn-section">
        <div class="bn-left">
          <p class="item ">Benchmark: <strong>{{selectedRowInfoDetails?.benchmark_names}}</strong></p>
          <p class="benchmark-text item">NIST Mapping 
            <span class="infoicon" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon button with a menu icon" #clickHoverMenuTrigger="matMenuTrigger">
                        <mat-icon>info</mat-icon></span>
                    <mat-menu id="info-menu" #menu="matMenu" class="benchmark-info mat-menu-scroll" [xPosition]="'before'">
                        <div *ngFor="let publication of selectedRowInfoDetails?.NIST | keyvalue">
                            <span class="list-item">{{ publication.key }}</span>
                            <div class="list-order">
                                <ol>
                                    <li *ngFor="let control of publication.value | keyvalue" class="list-item-value">
                                        <span class="list-item">{{ control.key }}:</span> {{ control.value }}
                                    </li>
                                </ol>
                            </div>

                        </div>
                    </mat-menu>
                  </p>
          
        </div>
    
        <div class="bn-right" *ngIf="selectedRowInfoDetails?.device_count">
          <div class="d-crt">
            <!-- <img src="../../assets/images/Outlne Stroked.png" /> -->
            <div class="details-info-values-graf">
              <app-semi-donut-chart [dynamicChartData]="dynamicChartData"></app-semi-donut-chart>
              <p class="graf-value">{{selectedRowInfoDetails?.device_count?.total_device_count}}</p>
              <p class="graf-title">Total Assets</p>
            </div>
            <div class="details-info-values-legends">
              <ul class="legends">
                <li><label>Assets Failed:</label> <span
                    class="bg-critical">{{selectedRowInfoDetails?.device_count?.failed_device_count}}</span>
                </li>
                <li><label>Assets Pass:</label> <span
                    class="bg-low">{{selectedRowInfoDetails?.device_count?.passed_device_count}}</span>
                </li>
                <li><label>Accepted Risk:</label> <span
                    class="accepted">{{selectedRowInfoDetails?.device_count?.whitelisted_device_count}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <p>Page has no data</p> -->
    <div class="parameter-info-list scroll" *ngIf="selectedRowApiCall">
      <div *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]">
        <ul class="details-info-list-item">
          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.description" class="benchmark-list">
            <div class="list-header-section">
              <p class="list-item">Description</p>
              <button class="expand-btn" mat-button (click)="toggleDescription()"><mat-icon>keyboard_arrow_down</mat-icon></button>
            </div>
            <p class="list-item-discription"  *ngIf="showDescription">{{selectedRowInfoDetails?.benchmark_list?.[0]?.description}}</p>
          </li>

          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.remediation" class="benchmark-list">
            <div class="list-header-section">
              <p class="list-item">Remediation</p>
              <button class="expand-btn"mat-button (click)="toggleRectification()"><mat-icon>keyboard_arrow_down</mat-icon></button>
            </div>
            <p class="list-item-discription" *ngIf="showRectification">{{selectedRowInfoDetails?.benchmark_list?.[0]?.remediation}}</p>
          </li>

          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.rationale" class="benchmark-list">
            <div class="list-header-section">
              <p class="list-item">Rationale</p>
              <button class="expand-btn" mat-button (click)="toggleRationale()"><mat-icon>keyboard_arrow_down</mat-icon></button>
            </div>
            <p class="list-item-discription" *ngIf="showRationale">{{selectedRowInfoDetails?.benchmark_list?.[0]?.rationale}}</p>
          </li>

          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.issues" class="benchmark-list">
            <div class="list-header-section">
              <p class="list-item">Issues</p>
              <button class="expand-btn" mat-button (click)="toggleIssues()"><mat-icon>keyboard_arrow_down</mat-icon></button>
            </div>
            <p class="list-item-discription" *ngIf="showIssues">{{selectedRowInfoDetails?.benchmark_list?.[0]?.issues}}</p>
          </li>
        </ul>
      </div>

    </div>
  </div>
<div *ngIf="isCveSelected" class="">
  <div class="c-details">
  <p class="parameter-name">
    <span><mat-icon class="back-arrow" (click)="goBack(2)">keyboard_backspace</mat-icon>
    </span>
  </p>
  <h3 class="spark-sec-tittle"> {{isCveName}}<label mat-button *ngIf="cveDetails?.cve_summary_desc"
      [matMenuTriggerFor]="cisa">{{cveDetails?.cve_summary_desc}}</label>

    <mat-menu #cisa="matMenu" class="vector-list cve-summarypopup scroll">
      <div class="vector-list-section" *ngIf="cveDetails">
        <div class="vector-list-header vector-drop-header">
          <p>{{cveDetails?.cve_summary_desc}}</p>
        </div>
        <div class="vector-list-info">
          <div *ngIf="cveDetails.cve_details.vulnerability_name" class="vector-list-item full-width">
            <p class="vector-list-label">Vulnerability Name</p>
            <p class="vector-list-value">{{cveDetails.cve_details.vulnerability_name}}</p>
          </div>
          <div *ngIf="cveDetails.cve_details.date_added" class="vector-list-item">
            <p class="vector-list-label">Date Added</p>
            <p class="vector-list-value">{{cveDetails.cve_details.date_added}}</p>
          </div>
          <div *ngIf="cveDetails.cve_details.due_date" class="vector-list-item">
            <p class="vector-list-label">Due Date</p>
            <p class="vector-list-value">{{cveDetails.cve_details.due_date}}</p>
          </div>
          <div *ngIf="cveDetails.cve_details.required_action?.length" class="vector-list-item full-width">
            <p class="vector-list-label">Required Action</p>
            <p class="vector-list-value">
              {{cveDetails.cve_details.required_action}}
            </p>
          </div>
        </div>
      </div>
    </mat-menu>
  </h3>
</div>
  <app-cve-enumeration [cveDetails]="cveDetails"></app-cve-enumeration>
</div>