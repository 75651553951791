import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-overlay-accpted-risk',
  templateUrl: './overlay-accpted-risk.component.html',
  styleUrls: ['./overlay-accpted-risk.component.scss']
})
export class OverlayAccptedRiskComponent {
  public params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
