import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IInputRenameRole, IInputUpdateRole, Role, RoleResponse } from '../../admin/components/tool-management/model/tool-management.model';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { END_POINTS } from '../constants/endpoints';
import { values } from 'lodash';
import { GlobalSearchService } from './global-search.service';
import * as _ from 'lodash';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class ToolManagementService {
  constructor(private restService: RestService,
    private responseService: ResponseService,
    private globalSearchService: GlobalSearchService,
    private apiService: ApiService) { }


  getRoles(callback: Function) {
    const roleReq: any = {
      "order_column": "name",
      "sort_by": "ASC"
    };
    // if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm) {
    //   roleReq.search = this.globalSearchService.searchTerm;
    // }
    const subs = this.restService.postApi(END_POINTS.ORG_ROLES, roleReq)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  getRolesData(role: Role, callback: Function) {
   const subs = this.restService.getApi(END_POINTS.ORG_ROLE_DETAILS + '/' + role?.id).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    })
  this.apiService.apiSubscriber.push(subs);  
  }
  createUpdateRole(role: Role, callback: Function, isNewRole: boolean = false) {
    const roleReq: IInputUpdateRole = {};
    roleReq.role_name = role.name;
    if (!isNewRole) {
      roleReq.role_id = role.id;
      roleReq.permissions = [];
      for (const [key, value] of Object.entries(role.permissions)) {
        const permissionObj: { code: string, value: string | any } = { code: '', value: '' };
        permissionObj.code = key;
        permissionObj.value = value;
        permissionObj.value = permissionObj.value.toString();
        roleReq.permissions.push(permissionObj);
      }
    } else {
      roleReq.clone_role_id=role.clone_role_id
    }
    this.restService.postApi(END_POINTS.ORG_CREATE_UPDATE_ROLE, roleReq).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response), isNewRole, role.name);
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    })
  }
  deleteRole(role: Role, callback: Function) {
    const roleReq: IInputUpdateRole = {};
    roleReq.role_id = role.id;
    this.restService.postApi(END_POINTS.DELETE_ROLE, roleReq).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    })
  }

  loadRegionList(role: Role, callBack: Function) {
    const payload = {
      role_id: role.id
    }
   const subs =  this.restService.postApi(END_POINTS.GLOBAL_REGION, payload).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
   this.apiService.apiSubscriber.push(subs); 
  }

  loadDeviceList(role: Role, regionsData: any, callBack: Function) {
    const payload = {
    role_id: role.id,
    data: regionsData
    }
    const subs = this.restService.postApi(END_POINTS.GLOBAL_DEVICE, payload).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
    this.apiService.apiSubscriber.push(subs)
  }

  saveRegionDevice(payload: any, callBack: Function) {
    const subs = this.restService.postApi(END_POINTS.GLOBAL_REGION_DEVICE, payload).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
    this.apiService.apiSubscriber.push(subs);
  }

  public renameRole( role: Role, roleName:string, callback: Function) {
    const scanObj: IInputRenameRole = {}
    scanObj.role_name = roleName;
    scanObj.role_id = role.id;
    return this.restService.postApi(END_POINTS.RENAME_ROLE, scanObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }
}