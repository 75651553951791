<div *ngIf="hasData; else noDataMessage">
<div class="all-selection"  *ngIf="type==='checkbox' && addFilterCheckForAll">
<mat-checkbox  [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="selectAll()" class="checklist-leaf-node"></mat-checkbox>
<label class="text_ellipsis">All</label>
</div>
<mat-tree [dataSource]="filterDataSource" [treeControl]="treeControl" class="upload-tree">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li [ngClass]="node.lavel===0 ? 'tree-node' : ''">
                  <mat-checkbox class="checklist-leaf-node"
                    *ngIf="type==='checkbox'"
                     (change)="itemToggle($event.checked, node);setChangedSelectedItemHierarch(node)"
                     [checked]="node.selected">
                  </mat-checkbox>
                  <mat-radio-button
                      *ngIf="type==='radio'"
                      class="checklist-leaf-node"
                      (change)="toggleRadio(node.selected = !node.selected, node);">
                  </mat-radio-button>
                  <!-- <label  class="text_ellipsis" appSafariEllipsis  [options]="toolTipOption" [tooltip]="node.label">{{node.label}}</label> -->
                   <label>
                    <p matTooltip="{{node.label}}" class="text_ellipsis paramer-value">{{node.label}}</p>
                   </label>
                </li>
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li [ngClass]="node.lavel===0?'nested-tree-node':''">
                  <span *ngIf="node?.lavel===0 && isGroupLevel" class="total-seleted-count">{{getSelectedLastChildren(node?.data)}}</span>
                  <div class="nested-tree-node-div">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.label">
                      <mat-icon class="mat-icon-rtl-mirror"
                        [ngClass]="treeControl.isExpanded(node) ? 'tringle' : 'invert-tringle'">
                      </mat-icon>
                    </button>
                    <mat-checkbox class="checklist-leaf-node" 
                      *ngIf="type==='checkbox'"
                      [checked]="node.selected"
                      [indeterminate]="node.indeterminate && !node.selected"
                      (change)="itemToggle($event.checked,node);setChangedSelectedItemHierarch(node)">
                    </mat-checkbox>
                    <!-- <span  class="text_ellipsis" appSafariEllipsis [options]="toolTipOption" [tooltip]="node.label"> <label>{{node.label}}</label></span> -->
                    <p matTooltip="{{node.label}}" class="text_ellipsis paramer-value"><label>{{node.label}}</label></p>
                  </div>
                  <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
          <ng-template #noDataMessage>
            <div class="no-data"> {{noDataMsg}}</div>
          </ng-template>